<template>
  <div class="details">
    <h4 class="text-center">Cloreto de Magnésio</h4>
    <h5>Mineral ajuda no metabolismo dos alimentos e é inibidor do cálcio.</h5><br/>
    <p>
      Uma xícara de acelga contém 285,7 mg de magnésio.<br/><br/>
      Cerca de 60% do magnésio encontrado no nosso corpo está localizado nos ossos. O magnésio é um inibor do cálcio e vice-versa. Ele executa diversas ações no organismo incluindo o metabolismo de carboidratos, lipídios, proteínas e outros. Quando há presença de cálcio em alguma delas o magnésio funciona como um antídoto natural, é o magnésio por exemplo que evita a calcificação das paredes dos vasos sanguíneos e dos próprios ossos quando há excesso de cálcio no corpo.<br/><br/>
      A dose recomendada de magnésio é de 400 mg por dia, sendo que o ideal é que a quantidade de cálcio seja o dobro da de magnésio. Cada 100g de castanha de caju contém 236,6 mg do mineral, cada 100g de semente de abóbora contém 541 mg de magnésio e cada 100g de chocolate amargo contém 327mg do nutriente. Outros alimentos ricos em magnésio são a acelga, abacate, nozes, semente de abóbora, aveia, espinafre e banana prata.
    </p>
    <h5>EFEITOS DA DEFICIÊNCIA E DO EXCESSO DE MAGNÉSIO</h5>
    <p>
      A carência do elemento causa problemas como irritabilidade, perda de apetite, enjoos, sonolência e espasmos musculares. Já o consumo excessivo pode ocasionar pressão baixa, males respiratórios, ritmo cardíaco acelerado e inibição da calcificação óssea. O magnésio também pode ser recomendado para evitar cãibras, pois atua na contração muscular. Este é um dos motivos que leva ao consumo de citrato de magnésio. No entanto, a suplementação de magnésio sem supervisão médica pode causar efeito laxante além de todas as consequências do consumo em excesso já citadas.
    </p>
  </div>
</template>
