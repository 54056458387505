<template>
  <div class="details">
    <h4 class="text-center">Potássio</h4>
    <h5>Mineral é essencial na transmissão de impulsos nervosos, produção de energia e ainda reduz a pressão.</h5><br/>
    <p>
      Potássio é um dos mais abundantes minerais da nossa alimentação, no entanto, sua eliminação do organismo também se dá em grandes quantidades, o que torna necessária uma reposição constante. O potássio é um íon essencial na transmissão dos impulsos nervosos. Além disso, ele é vital para o metabolismo energético ao converter glicose em glicogênio.<br/><br/>
      O potássio também auxilia no controle da hipertensão. Ao contrário do sódio que ajuda na retenção de líquidos, o potássio facilita a circulação sanguínea, pois dilata as artérias, aumentando o fluxo e aliviando a pressão.
    </p>
    <h5>QUANTIDADE RECOMENDADA, FONTE E EFEITOS DA DEFICIÊNCIA E EXCESSO DE POTÁSSIO</h5>
    <p>A quantidade diária recomendada de potássio é de 4.700 mg, sendo que 100g de abacate contém 602mg do mineral e uma banana 467mg. O excesso de potássio pode causar paralisia dos músculos, arritmia cardíaca, sensação de frio ou calor e formigamento, na ausência de estímulos. A deficiência do mineral pode causar apatia, ausência de reflexos, mal estar, náuseas e vômitos. Em casos extremos a falta de potássio no organismo contribui para uma pulsação fraca e rápida com chances de parada cardíaca.</p>
    <h5>Alimentos ricos em Potássio</h5>
    <ul>
      <li>Damasco</li>
      <li>Abacate</li>
      <li>Melão</li>
      <li>Leite desnatado</li>
      <li>Iogurte desnatado</li>
      <li>Folhas verdes</li>
      <li>Feijão</li>
      <li>Cogumelos</li>
      <li>Laranja</li>
      <li>Ervilha</li>
      <li>Batata</li>
      <li>Ameixa</li>
      <li>Espinafre</li>
      <li>Tomate</li>
      <li>Uva passa</li>
    </ul>
  </div>
</template>
