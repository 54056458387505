<template>
  <q-dialog v-model="open">
    <q-layout view="lhh LpR lff" container class="dialog">
      <div class="details">
        <div class="margin-top-15">
          <h3 class="text-center">Selo #{{seal.key}}: {{seal.name}}</h3>
          <div class="text-center margin-top-15">
            <img :src="seal.image" width="80"/>
          </div>
          <q-btn outline color="white" @click="openAminoacid(seal.aminoacid)">Aminoácido: {{ seal.aminoacid }}</q-btn>
          <br/><br/>
          <q-btn outline color="white" @click="openVitamin(seal.vitamin)">Vitamina: {{ seal.vitamin }}</q-btn>
          <br/><br/>
          <q-btn outline color="white" @click="openMineral(seal.mineral)">Mineral: {{ seal.mineral }}</q-btn>
          <br/><br/>
          <q-btn outline color="white" @click="openHebrewLetter(seal)">
            <span>Letra em Hebraico: <img :src="seal.hebrewImg" width="30"/> {{ seal.hebrew }} </span>
          </q-btn>
          <p class="margin-top-15" style="white-space: pre-line">{{seal.lifeCode}}</p>
        </div>
      </div>
    </q-layout>
  </q-dialog>

  <q-dialog v-model="vitaminDetails">
    <q-layout view="lhh LpR lff" container class="dialog">
      <div v-if="selectedVitamin === 'A'">
        <vitamin-a />
      </div>
      <div v-if="selectedVitamin === 'B1'">
        <vitamin-b1 />
      </div>
      <div v-if="selectedVitamin === 'B2'">
        <vitamin-b2 />
      </div>
      <div v-if="selectedVitamin === 'B3'">
        <vitamin-b3 />
      </div>
      <div v-if="selectedVitamin === 'B4'">
        <vitamin-b4 />
      </div>
      <div v-if="selectedVitamin === 'B5'">
        <vitamin-b5 />
      </div>
      <div v-if="selectedVitamin === 'B6'">
        <vitamin-b6 />
      </div>
      <div v-if="selectedVitamin === 'B7'">
        <vitamin-b7 />
      </div>
      <div v-if="selectedVitamin === 'B8'">
        <vitamin-b8 />
      </div>
      <div v-if="selectedVitamin === 'B9'">
        <vitamin-b9 />
      </div>
      <div v-if="selectedVitamin === 'B12'">
        <vitamin-b12 />
      </div>
      <div v-if="selectedVitamin === 'B13'">
        <vitamin-b13 />
      </div>
      <div v-if="selectedVitamin === 'B15'">
        <vitamin-b15 />
      </div>
      <div v-if="selectedVitamin === 'C'">
        <vitamin-c />
      </div>
      <div v-if="selectedVitamin === 'Colina'">
        <vitamin-colina />
      </div>
      <div v-if="selectedVitamin === 'D'">
        <vitamin-d />
      </div>
      <div v-if="selectedVitamin === 'E'">
        <vitamin-e />
      </div>
      <div v-if="selectedVitamin === 'K'">
        <vitamin-k />
      </div>
      <div v-if="selectedVitamin === 'Paba'">
        <vitamin-paba />
      </div>
    </q-layout>
  </q-dialog>

  <q-dialog v-model="mineralDetails">
    <q-layout view="lhh LpR lff" container class="dialog">
      <div v-if="selectedMineral === 'Cóbre'">
        <cobre />
      </div>
      <div v-if="selectedMineral === 'Zinco'">
        <zinco />
      </div>
      <div v-if="selectedMineral === 'Cálcio'">
        <calcio />
      </div>
      <div v-if="selectedMineral === 'Fósforo'">
        <fosforo />
      </div>
      <div v-if="selectedMineral === 'Sódio'">
        <sodio />
      </div>
      <div v-if="selectedMineral === 'Potássio'">
        <potassio />
      </div>
      <div v-if="selectedMineral === 'Iodo'">
        <iodo />
      </div>
      <div v-if="selectedMineral === 'Magnésio'">
        <magnesio />
      </div>
      <div v-if="selectedMineral === 'Manganes'">
        <manganes />
      </div>
      <div v-if="selectedMineral === 'Ferro'">
        <ferro />
      </div>
      <div v-if="selectedMineral === 'Selênio'">
        <selenio />
      </div>
      <div v-if="selectedMineral === 'Cobalto'">
        <cobalto />
      </div>
      <div v-if="selectedMineral === 'Cromo'">
        <cromo />
      </div>
      <div v-if="selectedMineral === 'Enxofre'">
        <enxofre />
      </div>
      <div v-if="selectedMineral === 'Fluór'">
        <fluor />
      </div>
      <div v-if="selectedMineral === 'Lítio'">
        <litio />
      </div>
      <div v-if="selectedMineral === 'Molibdênio'">
        <molibdenio />
      </div>
      <div v-if="selectedMineral === 'Níquel'">
        <niquel />
      </div>
      <div v-if="selectedMineral === 'Silício'">
        <silicio />
      </div>
    </q-layout>
  </q-dialog>

  <q-dialog v-model="hebrewLetter">
    <q-layout view="lhh LpR lff" container class="dialog">
      <div class="details text-center">
        <h4 class="text-center">{{selectedHebrewLetter.name}}</h4>
        <img :src="selectedHebrewLetter.img" height='300'/>
        <p>{{selectedHebrewLetter.desc}}</p>
      </div>
    </q-layout>
  </q-dialog>

  <aminoacid-dialogs-component />
</template>
<script>
import AminoacidDialogsComponent from './AminoacidDialogsComponent.vue'
import VitaminColina from '../../components/vitamins/VitaminColina.vue'
import VitaminPaba from '../../components/vitamins/VitaminPaba.vue'
import VitaminA from '../../components/vitamins/VitaminA.vue'
import VitaminB1 from '../../components/vitamins/VitaminB1.vue'
import VitaminB2 from '../../components/vitamins/VitaminB2.vue'
import VitaminB3 from '../../components/vitamins/VitaminB3.vue'
import VitaminB4 from '../../components/vitamins/VitaminB4.vue'
import VitaminB5 from '../../components/vitamins/VitaminB5.vue'
import VitaminB6 from '../../components/vitamins/VitaminB6.vue'
import VitaminB7 from '../../components/vitamins/VitaminB7.vue'
import VitaminB8 from '../../components/vitamins/VitaminB8.vue'
import VitaminB9 from '../../components/vitamins/VitaminB9.vue'
import VitaminB12 from '../../components/vitamins/VitaminB12.vue'
import VitaminB13 from '../../components/vitamins/VitaminB13.vue'
import VitaminB15 from '../../components/vitamins/VitaminB15.vue'
import VitaminC from '../../components/vitamins/VitaminC.vue'
import VitaminD from '../../components/vitamins/VitaminD.vue'
import VitaminE from '../../components/vitamins/VitaminE.vue'
import VitaminK from '../../components/vitamins/VitaminK.vue'

import Zinco from '../../components/minerals/Zinco.vue'
import Cobre from '../../components/minerals/Cobre.vue'
import Calcio from '../../components/minerals/Calcio.vue'
import Fosforo from '../../components/minerals/Fosforo.vue'
import Sodio from '../../components/minerals/Sodio.vue'
import Potassio from '../../components/minerals/Potassio.vue'
import Iodo from '../../components/minerals/Iodo.vue'
import Magnesio from '../../components/minerals/Magnesio.vue'
import Manganes from '../../components/minerals/Manganes.vue'
import Ferro from '../../components/minerals/Ferro.vue'
import Selenio from '../../components/minerals/Selenio.vue'
import Cobalto from '../../components/minerals/Cobalto.vue'
import Cromo from '../../components/minerals/Cromo.vue'
import Enxofre from '../../components/minerals/Enxofre.vue'
import Fluor from '../../components/minerals/Fluor.vue'
import Litio from '../../components/minerals/Litio.vue'
import Molibdenio from '../../components/minerals/Molibdenio.vue'
import Niquel from '../../components/minerals/Niquel.vue'
import Silicio from '../../components/minerals/Silicio.vue'

export default {
  name: 'BioCodeDialogComponent',
  data () {
    return {
      aminoacidDetails: false,
      vitaminDetails: false,
      mineralDetails: false,
      bioCodeDetails: false,
      selectedHebrewLetter: {
        name: '',
        img: ''
      },
      hebrewLetter: false
    }
  },
  computed: {
    seal () {
      return this.$store.state.dialogs.bioCode.seal
    },
    open: {
      get () {
        return this.$store.state.dialogs.bioCode.open
      },
      set () {
        return this.$store.dispatch('dialogs/setBioCode', {})
      }
    }
  },
  methods: {
    openAminoacid (aminoacid) {
      this.$store.commit('dialogs/setAminoacid', aminoacid)
    },
    openVitamin (vitamin) {
      this.selectedVitamin = vitamin
      this.vitaminDetails = true
    },
    openMineral (mineral) {
      this.selectedMineral = mineral
      this.mineralDetails = true
    },
    openHebrewLetter (seal) {
      this.selectedHebrewLetter = {
        name: seal.hebrew,
        desc: seal.hebrewDesc,
        img: seal.hebrewImg
      }
      this.hebrewLetter = true
    }
  },
  components: {
    VitaminColina,
    VitaminPaba,
    VitaminA,
    VitaminB1,
    VitaminB2,
    VitaminB3,
    VitaminB4,
    VitaminB5,
    VitaminB6,
    VitaminB7,
    VitaminB8,
    VitaminB9,
    VitaminB12,
    VitaminB13,
    VitaminB15,
    VitaminC,
    VitaminD,
    VitaminE,
    VitaminK,
    Cobalto,
    Cromo,
    Enxofre,
    Fluor,
    Litio,
    Molibdenio,
    Niquel,
    Silicio,
    Zinco,
    Cobre,
    Calcio,
    Fosforo,
    Sodio,
    Potassio,
    Iodo,
    Magnesio,
    Manganes,
    Ferro,
    Selenio,
    AminoacidDialogsComponent
  }
}
</script>

<style lang="scss" scoped>
  .details p {
    white-space: pre-line;
    text-align: left;
  }
</style>
