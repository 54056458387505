<template>
  <div class="details">
    <h4 class="text-center">Treonina</h4><br/>
    <p>
      1. Atua na firmeza da pele, melhorando a aparência (colágeno).<br/>
      2. Firmeza e elasticidade em pele<br/>
      A <span class="bold">treonina</span> é um aminoácido cujas funções são ajudar a manter a quantidade adequada de proteínas no corpo. É importante para a formação de colágeno, elastina e esmalte dos dentes.  Quando combinado com ácido aspártico e metionina, previne o acúmulo de gordura no fígado. Lembrando que o corpo não é capaz de sintetizar este aminoácido, é necessário obtê-lo através da alimentação.
    </p>
    <h5>Alimentos ricos em treonina</h5>
    <p>Spirulina, nabo, quiabo, chuchu, grão de bico, feijão lentilha, ervilha, abacate, cogumelos, chicória, aspargos, salsa, pepino, cebola roxa, espinafre, tomate, couve, beterraba, berinjela.</p>
  </div>
</template>
