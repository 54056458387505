<template>
  <div class="details">
    <h4 class="text-center">Lítio</h4>
    <p>
      O lítio é um elemento simples que se encontra na natureza.
      <br/>
      Visto existir, no seu estado normal, em pequenas quantidades na comida e na água, o lítio encontra-se no corpo humano. Estes vestígios, contudo, não têm expressão. Algumas rochas têm um alto teor de lítio e são estas as fontes de quase todo o lítio utilizado na indústria e na medicina.
      <br/>
      Para as pessoas que sofrem alterações frequentes do humor motivadas pela doença Bipolar, o lítio pode ser uma ajuda.
      <br/>
      Encontramos lítio nos grãos e vegetais.
    </p>
  </div>
</template>
