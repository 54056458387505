<template>
  <div class="details">
    <h4 class="text-center">Isoleucina</h4><br/>
    <p>
      1. Atua na construção dos tecidos musculares <br/>
      2. É mais bem absorvido com vitamina B <br/>
      3. Aumenta a formação de hemoglobina<br/>
      4. Impede o rim de perder vitamina B3<br/>
      5. Ajuda regular nível de açúcar no sangue<br/>
      6. Na sua deficiência pode sentir cansaço muscular<br/><br/>
      A <span class="bold">isoleucina</span> é necessária para a formação da hemoglobina, estabiliza e regula os níveis de açúcar no sangue e a produção de energia. Este aminoácido é valioso para atletas porque ajuda a curar e reparar os tecidos musculares, bem como a pele e os ossos. Em pesquisas foi observado que este aminoácido é insuficiente em pessoas que sofrem de certos transtornos mentais e físicos. A isoleucina é um aminoácido essencial e, por isso, são importantes as fontes alimentares deste aminoácido, pois o organismo não o consegue produzir. A dose diária recomendada de isoleucina é aproximadamente 1,3 g por dia para um indivíduo de 70 kg, por exemplo.
    </p>
    <h5>Lista de alimentos ricos em Isoleucina</h5>
    <ul>
      <li>Castanha de caju, castanha do pará, noz pecã, amêndoas, amendoim, avelã, gergelim;</li>
      <li>Abóbora, batata;</li>
      <li>Ervilha, feijão preto.</li>
    </ul>
  </div>
</template>
