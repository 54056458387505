<template>
  <div class="details">
    <h4 class="text-center">Prolina</h4><br/>
    <p>
      1. Ajuda na formação do colágeno para funcionamento adequado das articulações, das veias, dos tendões e do músculo cardíaco <br/>
      2. Elasticidade da pele (previne flacidez)<br/><br/>
      A <span class="bold">prolina</span> é um aminoácido que serve para ajudar na formação do colágeno, que é importante para o funcionamento adequado das articulações, das veias, dos tendões e do músculo cardíaco. Além disso, o colágeno também é responsável pela firmeza e elasticidade da pele, prevenindo a flacidez.
    </p>
    <h5>Alimentos ricos em Prolina</h5>
    <ul>
      <li>Gelatina;</li>
      <li>Proteína isolada de soja;</li>
      <li>Nozes;</li>
      <li>Gérmen de trigo, farinha de trigo integral e aveia;</li>
      <li>Whey protein (suplemento).</li>
    </ul>
  </div>
</template>
