<template>
  <div class="details">
    <h4 class="text-center">CONHEÇA A VITAMINA D (Calciferol)</h4>
    <h5>Funções da Vitamina D e consequências de sua falta e consumo em excesso.</h5><br/>
    <p>
      A vitamina D é, fundamentalmente, sintetizada a partir da incidência de raios ultravioletas do Sol no contato com a pele. Por conta disso, a vitamina D é considerada um hormônio capaz de ser fabricado pelo corpo Através da dieta, as fontes apresentam quantidades escassas para os seres humanos.
    </p>
    <h5>ONDE ENCONTRAR VITAMINA D</h5>
    <p>
      O Sol é responsável por cerca de 80 a 90% da vitamina D que o corpo recebe. Os raios ultravioletas do tipo B (UVB) são capazes de ativar a síntese desta substância. É recomendado que, no mínimo, tenhamos 15 minutos por dia de exposição ao Sol.
      <br/>
      Mas também pode ser encontrado em alimentos, como aveia e quinoa.<br/>
      Cogumelos sintetizam vitamina D quando expostos ao sol.
    </p>
    <h5>BENEFÍCIOS DA VITAMINA D</h5>
    <p>
      Como aumenta a absorção de cálcio e fósforo no intestino e facilita a entrada desses minerais nos ossos, a vitamina D é importante para o fortalecimento da estrutura óssea e dentária. No pâncreas, a vitamina D em quantidades adequadas auxilia na liberação regulada da insulina, assim ajudando na prevenção do diabetes.
    </p>
    <h5>DEFICIÊNCIA DE VITAMINA D</h5>
    <p>
      Indivíduos que tenham pouca exposição ao sol, que tenham problemas na absorção de lipídios ou problemas na dieta estão propensos a apresentar deficiência de vitamina D. Idosos, gestantes, pacientes com osteomalácia, raquitismos, osteoporose, hiperparatireoidismo secundário, doenças inflamatórias, doenças autoimunes e renal crônica e pré-bariátricos são considerados grupo de risco.<br/><br/>
      Em gestante, suas deficiência pode causar casos de abortos até o terceiro mês de gestação e pré-eclâmpsia, doença na qual a mulher desenvolve a hipertensão. <br/><br/>
      Em alguns casos de deficiência de vitamina D o paciente pode ser assintomático. Quando os sintomas aparecem é importante ficar atento à fadiga, fraqueza muscular e dor crônica.
    </p>
    <h5>EXCESSO DE VITAMINA D</h5>
    <p>
      Valores acima de 100 ng/mL no organismo são tóxicos. A suplementação de vitamina D deve ser feita com acompanhamento médico. Quadros como este podem desencadear sintomas de toxicidade como náuseas, falta de apetite, vômitos, fraqueza e constipação. Grandes quantidades de vitamina D aumentam a concentração de cálcio sanguíneo, levando a quadros de desorientação e problemas de ritmo cardíaco, além de ser prejudicial aos rins.
    </p>
  </div>
</template>
