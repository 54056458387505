<template>
  <div class="row">
    <div class="col-md-12">
      <div class="kin bg-transparent harmonic" v-if="harmonic">
        <h3>{{ title }}</h3>
        <div v-if="isPro">
          <div class="flex-center">
            <div class="yantras">
              <div class="yantra">
                <img :src="kin.tone.image" class="tone" width="80" />
                <br/>
                <img :src="kin.seal.image" width="80" />
              </div>
              <h4>+</h4>
              <div class="yantra">
                <img :src="userKin.tone.image" class="tone" width="80" />
                <br/>
                <img :src="userKin.seal.image" width="80" />
              </div>
              <h4>=</h4>
              <div class="yantra">
                <img :src="harmonic.kin.tone.image" class="tone" width="80" />
                <br/>
                <img :src="harmonic.kin.seal.image" width="80" />
              </div>
            </div>
          </div>
          <div class="row margin-top-15">
            <div class="col-sm-6">
              <mantra-kin-component :kin="harmonic.kin"></mantra-kin-component>
              <nutrition-component :kin="harmonic.kin"/>
            </div>
            <div class="col-sm-6">
              <wave-component :wave="harmonic.wave" :kin="harmonic.kin"></wave-component>
              <oracle-component :oracle="harmonic.oracle" :kin="harmonic.kin"></oracle-component>
              <chromatics-component :chromatics="harmonic.chromatics"></chromatics-component>
              <div class="d-none d-sm-block">
                
              </div>
            </div>
          </div>
          <div class="d-none d-sm-block col-12 col-sm-10 offset-0 offset-sm-1 offset-md-3 col-md-6">
            <harmonic-component :harmonic="harmonic.harmonic"/>
          </div>
          <div class="d-block d-sm-none">
            <harmonic-component :harmonic="harmonic.harmonic"/>
          </div>
        </div>
        <div v-if="!isPro">
          <assign-plan></assign-plan>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OracleComponent from '../../components/kin/OracleComponent'
import WaveComponent from '../../components/kin/WaveComponent'
import ChromaticsComponent from '../../components/kin/ChromaticsComponent'
import MantraKinComponent from '../../components/kin/MantraKinComponent'
import NutritionComponent from '../../components/kin/NutritionComponent'
import HarmonicComponent from '../../components/kin/HarmonicComponent'
import ArqComponent from '../../components/kin/ArqComponent'
import AssignPlan from '../AssignPlan'

export default {
  name: 'CelestialHarmonicComponent',
  props: {
    kin: Object,
    userKin: Object,
    harmonic: Object,
    title: String
  },
  computed: {
    isPro () {
      return this.$store.state.auth.userPro
    },
  },
  components: {
    OracleComponent,
    WaveComponent,
    ChromaticsComponent,
    MantraKinComponent,
    NutritionComponent,
    HarmonicComponent,
    AssignPlan
  }
}
</script>

<style lang="scss" scoped>
  .harmonic {
    padding: 20px;
    .yantras {
      display: inline-flex;
      h4 {
        align-self: center;
        margin: 10px 10px -40px 10px;
        font-size: 66px;
      }
      @media (max-width: 410px) {
        img {
            width: 50px !important;
        }
      }
    }
  }
</style>
