<template>
  <div class="details">
    <h4 class="text-center">VITAMINA B2 (RIBOFLAVINA)</h4>
    <p>
      As principais fontes de vitamina B2 são: cogumelos, soja. Esta substância é fundamental no processo metabólico de proteínas, carboidratos e gorduras. Além de ajudar a aliviar a fadiga ocular e agir na na prevenção e tratamento da catarata.<br/><br/>
      A deficiência da vitamina B2 no organismo pode causar vertigem, dermatite seborreica, inflamação nos lábios e língua, coceira nos olhos e até conjuntivite. Vegetarianos, alcoólatras, crianças, gestantes e idosos estão no grupo dos mais vulneráveis ao problema, embora a deficiência a esta vitamina seja, de fato, rara.
    </p>
    <h5>Alimentos fonte de RIBOFLAVINA</h5>
    <p>Couve, brócolis, espinafre, repolho, agrião, semente de girassol, cará, ervilhas, cogumelos.</p>
  </div>
</template>
