<template>
  <div class="details">
    <h4 class="text-center">Vitamina K</h4>
    <h5>Funções da Vitamina K e consequências de sua falta e consumo em excesso.</h5><br/>
    <p>
      Fazendo parte do grupo de vitaminas lipossolúveis, que precisam de gordura para serem absorvidas, a Vitamina K se divide em k1, k2 e k3. Também conhecida como vitamina anti-hemorrágica, ela é muito importante para a coagulação do sangue evitando hemorragias, facilitando a cicatrização e a reposição de nutrientes dos ossos.<br/><br/>
      A vitamina K1 é encontrada em alimentos de origem vegetal e é a responsável por ativar a coagulação, a k2 é produzida pela flora bacteriana intestinal e auxilia na formação dos ossos e na saúde dos vasos sanguíneos, enquanto a k3 é produzida em laboratório e utilizada para fazer suplementação.
    </p>
    <h5>ONDE ENCONTRAR VITAMINA K</h5>
    <p>
      Em adultos, homens e mulheres, considerados saudáveis, uma dieta equilibrada deve conter uma média de 90 a 120 mcg de vitamina K por dia. Sua incidência é grande em vegetais de folhas verde-escuras, como brócolis, couve manteiga, alface escura, espinafre e em outros alimentos, como figo, amora e nozes.
    </p>
    <h5>BENEFÍCIOS DA VITAMINA K</h5>
    <p>
      A vitamina K ajuda as proteínas a se transformarem em substâncias que contribuem para a coagulação sanguínea correta. Assim, também contribui para uma melhor cicatrização. Já na saúde dos ossos, ela evita a desmineralização óssea no organismo e ajuda na fixação do cálcio.<br/><br/>
      Uma pesquisa realizada por cientistas da Universitat Rovira i Virgili, em 2012 na Espanha, concluiu que o consumo de alimentos ricos em vitamina K1, como brócolis, espinafre e couve-flor, pode reduzir pela metade a incidência do diabetes tipo 2.<br/><br/>
      Em bebês prematuros, a vitamina K pode ser importante para prevenir hemorragia espontânea. Além dessas funções, a vitamina k também é importante para o crescimento celular, pois está envolvida na síntese de proteínas presentes no plasma, rins e outros tecidos.
    </p>
    <h5>DEFICIÊNCIA DE VITAMINA K</h5>
    <p>
      Visto que a vitamina K está presente em diversos alimentos e também é produzida pela flora intestinal, é difícil ocorrer a deficiência desta vitamina. Contudo, desordens gastrointestinais que estão relacionadas a uma absorção de gordura deficiente e doenças no fígado podem interferir na ideal absorção de vitamina K. Pessoas que fizeram cirurgia bariátrica ou que tomam medicamentos para reduzir a absorção de gordura no intestino têm uma maior chance de ter essa deficiência.<br/><br/>
      O principal sintoma da falta da vitamina K no organismo são quadros de sangramentos na pele, nariz, por uma pequena ferida ou no estômago. O enfraquecimento dos ossos também pode ser um sintoma, assim como excesso de fluxo menstrual nas mulheres.
    </p>
    <h5>EXCESSO DE VITAMINA K</h5>
    <p>
      As grandes quantidades dessa vitamina ocorrem geralmente por meio da suplementação mal orientada. O excesso de vitamina K pode hiperestimular a coagulação sanguínea e aumentar o risco de trombose. Esse mesmo efeito coagulador em excesso pode prejudicar o uso de alguns medicamentos, especialmente aqueles para controlar diabetes ou problemas cardíacos.
    </p>
  </div>
</template>
