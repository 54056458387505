import { save, list } from '../database/moon_goal'
import KinModel from '../models/kin_model'
import moment from 'moment'

const kinM = new KinModel()

var currentDate = moment().format('YYYY-MM-DD')
var date = currentDate.split('-')
var currentSync = kinM.calcCalender(date[0], date[1], date[2])
// var currentSync = kinM.calcCalender(2222, date[1], date[2])

export default {
  namespaced: true,
  state: {
    selecteds: [],
    currentSync: currentSync
  },
  getters: {},
  actions: {
    save (context, payload) {
      const uid = context.rootState.auth.user.uid
      return save(uid, payload, currentSync.year, currentSync.moon.moon.key).then((res) => {
        context.commit('list', uid)
        return res
      })
    },
    list (context) {
      const uid = context.rootState.auth.user.uid
      return context.commit('list', uid)
    }
  },
  mutations: {
    list (state, uid) {
      return list(uid, currentSync.year, currentSync.moon.moon.key).then((res) => {
        if (res.docs.length !== 0) {
          state.selecteds = res.docs[0].data().selecteds
        }
      })
    }
  }
}
