import chronoPsiList from '../datas/chrono_psi'

export default function ChronoPsiModel () {
  // this.getByMoon = function (moonKey) {
  //   isThisCpsi = function(cpsi) {
  //     if (cpsi.moon.key == moonKey) {
  //       return true
  //     }
  //     return false
  //   }
  //   chronoPsiList.find(isThisCpsi)
  // }

  this.getByDay = function (moon, day) {
    const isThisCpsi = function (cpsi) {
      if (cpsi.moon.key === moon && cpsi.day === day) {
        return true
      }
      return false
    }
    return chronoPsiList.find(isThisCpsi).kin
  }
}
