<template>
  <div class="details">
    <h4 class="text-center">Metionina</h4><br/>
    <p>
      1. Trata patologias de fígado <br/>
      2. Desintoxicante do fígado<br/>
      3. Importante para ganho de massa muscular <br/>
      4. Previne danos das células fortalece o sistema imunológico<br/>
      5. Previne infecções urinárias recorrentes, impede que bactérias se proliferem na bexiga (cistite). <br/>
      6. Desintoxicante ajudando na eliminação de compostos tóxicos e substâncias de medicamentos<br/><br/>
      A <span class="bold">metionina</span> desempenha no organismo importantes funções, tais como estimular o ganho de massa muscular, fortalecer o sistema imunológico e auxiliar na produção de energia. A metionina é um poderoso antioxidante que previne doenças do cabelo, pele e unhas, bem como auxilia na quebra de gorduras, ajudando assim a prevenir o acúmulo de gordura no fígado e artérias. A lista de alimentos ricos em metionina é extensa, entretanto alguns quando fervidos ou colocados de molho por muito tempo podem perder a abundância do aminoácido, visto que ele é altamente solúvel.
    </p>
    <h5>Lista de alimentos ricos em Metionina</h5>
    <p>
      Castanha do pará, vagem, sementes de gergelim e de girassol e aveia.
    </p>
  </div>
</template>
