<template>
  <div class="details">
    <h4 class="text-center">Fósforo</h4>
    <h5>Além de ajudar na formação de ossos e dentes o fósforo ajuda no raciocínio e memorização.</h5><br/>
    <p>
      Fósforo é um dos materiais que participa da formação dos ossos e dentes juntamente com o cálcio. As maiores concentrações de fósforos estão no esqueleto, nos tecidos moles, músculos, no fígado e no baço. Além disso, o mineral participa das contrações musculares e é um dos principais elementos para armazenamento e transporte de energia, formando a ATP (adenosina trifosfato). Desempenha ainda importante papel na constituição da membrana celular, uma vez que participa da composição dos fosfolipídios.<br/>
      O fósforo está presente em grande quantidade em uma boa variedade de alimentos e é de fácil absorção. <br/>
    </p>
    <h5>As principais fontes de Fósforo</h5>
    <ul>
      <li>Algas marinhas, feijão, ervilha, lentilha, grão de bico, soja.</li>
      <li>Oleaginosas – amendoim, castanha, nozes e avelãs, todas com alto teor de fósforo.</li>
    </ul>
    <h5>EFEITOS DA DEFICIÊNCIA E EXCESSO DE FÓSFORO</h5>
    <p>
      Apesar de ser abundante na alimentação e de fácil absorção, problemas como alcoolismo, diarreias, vômitos, pancreatite, bem como o uso de antiácidos gástricos em tratamentos prolongados, podem causar deficiência de fósforo. Quantidades reduzidas do mineral no organismo provocam dores ósseas, osteomalácia, pseudofraturas, hipoparatiroidismo, resistência à insulina, hipocalciúria, taquicardia, dificuldades de raciocínio e memorização. <br/>
      O excesso de fósforo é identificado como hiperfosfatemia e pode causar hipertensão, confusão mental, parestesias nas extremidades do corpo (alterações do nível de sensibilidade) e formação de cristais de fosfato que podem bloquear artérias causando prejuízos à circulação sanguínea e levar à arteriosclerose, derrames e ataque cardíaco. Na dúvida, consulte seu médico. Nunca realize suplementação sem orientação de um profissional médico.
    </p>
  </div>
</template>
