import {
  save
} from '../database/whatsMatters'

export default {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    save (context, payload) {
      const uid = context.rootState.auth.user.uid
      return save(uid, payload)
    }
  },
  mutations: {

  }
}
