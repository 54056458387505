<template>
  <div class="details">
    <h4 class="text-center">Selênio</h4>
    <h5>Selênio ajuda a evitar viroses e protege o fígado.</h5>
    <p>
      O selênio é um mineral de ação antioxidante fundamental na prevenção da saúde das células por retardar o envelhecimento, proteger das doenças cardiovasculares e até do câncer. Curiosamente, as manchas brancas nas unhas são um dos indicadores da deficiência de selênio. Umas das fontes do mineral é a castanha do pará, apenas duas unidades do fruto são suficientes para suprir as necessidades diárias, para os alérgicos a alternativa é a farinha de trigo.<br/><br/>
      A quantidade recomendada de selênio varia de 55 a 77 microgramas por dia, sendo o suficiente para proteger o organismo de inflamações e evitar gripes, infecções e viroses. O fígado, o pâncreas e os músculos são alguns dos beneficiados pelo mineral, que também ajuda no bom funcionamento da tireoide.
    </p>
    <h5>EXCESSO DE SELÊNIO</h5>
    <p>
      A quantidade máxima de selênio que pode ser consumida por dia sem causar complicações a saúde é de 400 microgramas. Este é um mineral tóxico em grandes quantidades, mas essencial para o corpo. Devido ao seu benefício para preservação dos músculos, a suplementação torna-se bastante atrativa, no entanto, níveis elevados de selênio trazem cansaço, dores, maior irritabilidade, unhas e cabelos frágeis além de problemas no sistema gastrointestinal e sistema nervoso.<br/><br/>
      O acompanhamento de um profissional é sempre importante para evitar excessos e carências na hora de montar uma dieta equilibrada.
    </p>
    <h5>Aalimentos ricos em Selênio</h5>
    <ul>
      <li>Castanha-do-pará</li>
      <li>Farinha de trigo integral</li>
      <li>Arroz</li>
      <li>Feijão</li>
      <li>Sementes de girassol</li>
      <li>Cogumelo Crimini</li>
      <li>Centeio</li>
      <li>Aveia</li>
    </ul>
  </div>
</template>
