<template>
  <div class="details">
    <h4 class="text-center">VITAMINA B5 (ÁCIDO PANTOTÊNICO)</h4>
    <p>
      Esta vitamina é necessária para produzir esteroides vitais e cortisona na glândula supra-renal, atuando na produção dos hormônios supra-renais e na formação de anticorpos. A vitamina B5 também atua melhorando a saúde da pele e dos cabelos.<br/><br/>
      A deficiência da vitamina B5 no organismo pode resultar em baixa produção de anticorpos, fadiga, insônia, depressão, retardo de crescimento e queda de cabelo. Sua deficiência é rara, mas pode afetar grupos de risco, como pessoas que fazem uso excessivo de bebidas alcoólicas, idosos e pessoas com problemas intestinais.
    </p>
    <h5>Alimentos fonte de ÁCIDO PANTOTÊNICO</h5>
    <p>Semente de girassol, amendoim, abacate, cogumelos, batata doce, ervilha, lentilha, abóbora, morango, ameixa, banana, arroz, laranja, frutas cítricas e tomate.</p>
  </div>
</template>
