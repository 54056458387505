<template>
  <q-toolbar class="text-center menu glutter-x-0">
    <q-btn-dropdown stretch label="Comece por aqui">
      <q-list class="glutter-x-0">
        <q-item clickable v-close-popup to="/introducao">
          <q-item-section>
            <q-item-label>O que é o Sincronário das 13 Luas</q-item-label>
          </q-item-section>
        </q-item>

        <q-item clickable v-close-popup to="/introducao/kin">
          <q-item-section>
            <q-item-label>O que é kin</q-item-label>
          </q-item-section>
        </q-item>

        <q-item clickable v-close-popup to="/introducao/oraculo-do-destino">
          <q-item-section>
            <q-item-label>O que é Oráculo do Destino</q-item-label>
          </q-item-section>
        </q-item>

        <q-item clickable v-close-popup to="/introducao/tzolkin">
          <q-item-section>
            <q-item-label>O que é Tzolkin</q-item-label>
          </q-item-section>
        </q-item>

        <q-item clickable v-close-popup to="/introducao/matriz-tzolkin">
          <q-item-section>
            <q-item-label>Matriz Tzolkin</q-item-label>
          </q-item-section>
        </q-item>

        <q-item clickable v-close-popup to="/introducao/onda-encantada">
          <q-item-section>
            <q-item-label>O que é Onda Encantada</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>
    <q-btn-dropdown stretch label="O segredo da vida">
      <q-list class="glutter-x-0">
        <q-item clickable v-close-popup @click="goToName('/vida-bioquimico/introducao')">
          <q-item-section>
            <q-item-label>O Código da Vida Bioquímico</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable v-close-popup to="/holon">
          <q-item-section>
            <q-item-label>Holon Humano</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable v-close-popup to="/meditacao">
          <q-item-section>
            <q-item-label>Meditações</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>
    <q-btn-dropdown stretch label="Nutrição do Ser">
      <q-list class="glutter-x-0">
        <q-item clickable v-close-popup href="https://www.deborasantospossamai.com.br" target="_blank">
          <q-item-section>
            <q-item-label>Quem sou eu</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable v-close-popup @click="goToName('/nutricao/alimentos-para-cada-tom')">
          <q-item-section>
            <q-item-label>Alimentos para cada Tom</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable v-close-popup @click="goToName('/nutricao/aminoacidos')">
          <q-item-section>
            <q-item-label>Aminoácidos fonte de Vida</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable v-close-popup @click="goToName('/nutricao/vitaminas')">
          <q-item-section>
            <q-item-label>Vitaminas</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable v-close-popup @click="goToName('/nutricao/minerais')">
          <q-item-section>
            <q-item-label>Minerais</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>
    <!-- <q-btn stretch flat label="Nutrição do Ser" /> -->
  </q-toolbar>
</template>

<script>
export default {
  methods: {
    goToName(routeName) {
      console.log('goTorouter', this.$store.state.auth.freeTrial)
      var errorMessage = ''

      if (this.$store.state.auth.user) {
        if (this.$store.state.auth.userPro) {
          this.$router.push(routeName)
          return;
        }
        errorMessage = 'Assine o Plano Cósmico para obter acesso à este recurso'
      } else {
        errorMessage = 'Acesse sua conta'
      }

      this.$q.notify({
        color: 'purple',
        textColor: 'white',
        icon: 'warning',
        message: errorMessage
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .q-list {
    background-color: #402356;
    color: #fff;
    padding-top: 0;
  }
</style>
