<template>
  <div class="details">
    <h4 class="text-center">Valina</h4><br/>
    <p>
      1. Construção e tonicidade muscular<br/>
      2. Melhora a cicatrização pós cirúrgica<br/>
      3. Regenera tecidos<br/>
      A <span class="bold">valina</span> é necessária para o metabolismo e coordenação muscular, reparação de tecidos e para manter o equilíbrio adequado de nitrogênio no corpo, que é usado como fonte de energia para o tecido muscular. Este aminoácido é útil no tratamento de doenças do fígado e da vesícula biliar, pois promove o vigor mental e acalma as emoções.<br/><br/>
    </p>
    <h5>Lista de alimentos ricos em valina</h5>
    <p>Alterar a lista de alimentos de valina para: Batata inglesa, brócolis, banana, laranja, grão de bico, feijão, lentilha, amendoim, linhaça, nozes e sementes ,cogumelos, chia.</p>
  </div>
</template>
