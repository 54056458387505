<template>
  <q-header reveal :reveal-offset="20" elevated>
    <q-toolbar>
      <q-btn
        flat
        round
        @click="leftDrawerOpen = !leftDrawerOpen"
        aria-label="Menu"
        icon="menu"
      />

      <q-toolbar-title class="white-space-normal overflow-initial">
        <q-btn to="/">CosmoBiologia</q-btn>
      </q-toolbar-title>

      <q-btn v-if="user" round icon="fa fa-solid fa-bell" :class="((showNotifications) ? 'active' : '') + ((counter > 0) ? ' attention' : '')" @click="openNotifications">
        <div class="counter " v-if="counter > 0">
          {{counter}}
        </div>
      </q-btn>
      <q-btn v-if="!user" round icon="fa-solid fa-right-to-bracket" tag="a" to="/login" />
      <q-btn v-if="user" round icon="fa fa-notes-medical" @click="goToName('/addNote')"/>
      <q-btn v-if="user" round icon="fa fa-user-plus" @click="changeAddFriendDialog"/>
    </q-toolbar>
  </q-header>

  <q-drawer
    v-model="leftDrawerOpen"
    show-if-above
    bordered
    :width="270"
    :breakpoint="(user) ?1000 :2000"
  >
  
    <q-list class="glutter-x-0" :style="{'height': (!user) ? '100%' : 'auto', 'padding-top': (!user) ? '200px' : '5px'}">
      <div class="profile" v-if="userKin">
        <div class="flex-center">
          <div>
            <img :src="userKin.tone.image" width="80"/>
            <br/>
            <img :src="userKin.seal.image" width="80" />
            <br/>
          </div>
        </div>
        <div class="text-center">
          <h5>{{userKin.name}}</h5>
          <h6>{{user.name}}</h6>
        </div>
      </div>

      <div v-if="!userKin" :style="{'padding-bottom': '30px'}">
        <q-item clickable tag="a" to="/login">
          <q-item-section avatar>
            <q-icon name="fa-solid fa-right-to-bracket" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Acesso</q-item-label>
            <!-- <q-item-label caption>Acesse sua conta</q-item-label> -->
          </q-item-section>
        </q-item>
      </div>
      
      <q-item clickable tag="a">
        <q-item-section avatar>
          <q-icon name="fa fa-calendar-day" />
        </q-item-section>
        <q-item-section @click="todayKin">
          <q-item-label>Kin do dia</q-item-label>
        </q-item-section>
      </q-item>
      
      <q-item clickable tag="a">
        <q-item-section avatar>
          <q-icon name="fa fa-sun" />
        </q-item-section>
        <q-item-section @click="personalKin">
          <q-item-label>Sua Assinatura Galáctica</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable tag="a" @click="transitWave">
        <q-item-section avatar>
          <q-icon name="fa fa-sun" />
        </q-item-section>
        <q-item-section>
          <q-item-label>Onda de trânsito</q-item-label>
          <q-item-label caption>O kin que você está vivenciando este ano</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable @click="goToFriends">
        <q-item-section avatar>
          <q-icon name="fa fa-user-friends" />
        </q-item-section>
        <q-item-section>
          <q-item-label>Amig@s</q-item-label>
          <!-- <q-item-label caption>O kin de seus amig@s cadastrad@s</q-item-label> -->
        </q-item-section>
      </q-item>
      <q-item clickable @click="goToNotes">
        <q-item-section avatar>
          <q-icon name="fa fa-pencil-alt"/>
        </q-item-section>
        <q-item-section>
          <q-item-label>Anotações</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable tag="a" @click="goToName('/o-que-importa')">
        <q-item-section avatar>
          <q-icon name="fa fa-list-ol"/>
        </q-item-section>
        <q-item-section>
          <q-item-label>Perguntas Poderosas</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable tag="a" @click="goToMetas">
        <q-item-section avatar>
          <q-icon name="fa fa-clipboard-list"/>
        </q-item-section>
        <q-item-section>
          <q-item-label>Sincronizando seu intento através da lua</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable tag="a" @click="goToName('/calc')" v-if="user">
        <q-item-section avatar>
          <q-icon name="fa fa-calculator"/>
        </q-item-section>
        <q-item-section>
          <q-item-label>Calculadora</q-item-label>
          <q-item-label caption>Calcule o kin de seus amigos</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable tag="a" to="/settings" v-if="user">
        <q-item-section avatar>
          <q-icon name="fa fa-user-cog" />
        </q-item-section>
        <q-item-section>
          <q-item-label>Configurações</q-item-label>
          <q-item-label caption>Mude suas informações pessoais</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable tag="a" @click="logout" v-if="user">
        <q-item-section avatar>
          <q-icon name="fa fa-sign-out-alt" />
        </q-item-section>
        <q-item-section>
          <q-item-label>Sair</q-item-label>
          <q-item-label caption>Encerrar sessão</q-item-label>
        </q-item-section>
      </q-item>
      
    </q-list>
    <div v-if="isProTrial" class="text-center" className="isTrial">
      <p class="phrase">Seu período de teste expirará em breve</p>
      <assign-plan :simple="true"></assign-plan>
    </div>
  </q-drawer>

  <q-dialog v-model="addFriendDialog">
    <add-friend-dialog :close-dialog="changeAddFriendDialog"></add-friend-dialog>
  </q-dialog>
  <div v-if="showNotifications">
    <notifications />
  </div>
</template>

<script>
import { ref } from 'vue'
import KinModel from '../../models/kin_model'
import AddFriendDialog from '../../views/friends/AddFriendDialog'
import Notifications from '../Notifications'
import moment from 'moment'
import AssignPlan from '../../components/AssignPlan'

export default {
  components: { AddFriendDialog, Notifications, AssignPlan },
  computed: {
    user () {
      return this.$store.state.auth.user
    },
    userKin () {
      var userM = new KinModel()
      if (this.user != null) {
        return userM.list()[this.user.kin - 1]
      }
      return null;
    },
    counter () {
      return this.$store.state.notifications.howMuchToSee
    },
    isProTrial () {
      return this.$store.state.auth.freeTrial
    },
  },
  setup () {
    return {
      leftDrawerOpen: ref(false),
      addFriendDialog: ref(false),
      showNotifications: ref(false)
    }
  },
  methods: {
    async logout () {
      this.$store.dispatch('auth/logout')
    },
    personalKin () {
      if (this.user == null) {
        this.$q.notify({
          color: 'purple',
          textColor: 'white',
          icon: 'warning',
          message: 'Acesse sua conta!'
        })
        return
      }
      this.leftDrawerOpen = false
      this.$store.commit('home/changeKin', this.user.date)
      this.$router.push({ name: 'home' })
    },
    transitWave () {
      if (this.user == null) {
        this.$q.notify({
          color: 'purple',
          textColor: 'white',
          icon: 'warning',
          message: 'Acesse sua conta!'
        })
        return
      }
      this.leftDrawerOpen = false
      this.$router.push({ name: 'trafficWave' })
    },
    goToFriends () {
      if (this.user == null) {
        this.$q.notify({
          color: 'purple',
          textColor: 'white',
          icon: 'warning',
          message: 'Acesse sua conta!'
        })
        return
      }
      this.leftDrawerOpen = false
      this.$router.push({ name: 'friends' })
    },
    goToMetas () {
      if (this.user == null) {
        this.$q.notify({
          color: 'purple',
          textColor: 'white',
          icon: 'warning',
          message: 'Acesse sua conta!'
        })
        return
      }
      this.leftDrawerOpen = false
      this.$router.push({ name: 'intentos' })
    },
    goToNotes () {
      if (this.user == null) {
        this.$q.notify({
          color: 'purple',
          textColor: 'white',
          icon: 'warning',
          message: 'Acesse sua conta!'
        })
        return
      }
      this.leftDrawerOpen = false
      this.$router.push({ name: 'notes' })
    },
    todayKin () {
      this.leftDrawerOpen = false
      this.$store.commit('home/changeKin', moment().format('YYYY-MM-DD'))
      this.$router.push({ name: 'home' })
    },
    changeAddFriendDialog () {
      var errorMessage = ''

      if (this.$store.state.auth.user) {
          if (this.$store.state.auth.userPro) {
            this.addFriendDialog = !this.addFriendDialog
            return;
          }
          errorMessage = 'Assine o Plano Cósmico para obter acesso à este recurso'
      } else {
      errorMessage = 'Acesse sua conta!'
      }

      this.$q.notify({
        color: 'purple',
        textColor: 'white',
        icon: 'warning',
        message: errorMessage
      })
    },
    openNotifications () {
      this.showNotifications = !this.showNotifications
      if (this.counter > 0) {
        this.$store.dispatch('notifications/setUserView')
      }
    },
    goToName(routeName) {
      var errorMessage = ''

      if (this.$store.state.auth.user) {
        if (this.$store.state.auth.userPro) {
          this.$router.push(routeName)
          return;
        }
        errorMessage = 'Assine o Plano Cósmico para obter acesso à este recurso'
      } else {
        errorMessage = 'Acesse sua conta'
      }

      this.$q.notify({
        color: 'purple',
        textColor: 'white',
        icon: 'warning',
        message: errorMessage
      })
    }
  }
}
</script>

<style scoped lang="scss">
.profile {
  margin-bottom: 15px;
}

.active {
  & {
    color: purple !important;
    background-color: #ffffff9e !important;
  }
}

.attention {
    background: linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff);
    -webkit-background-clip: text;
    background-clip: text;
    color: white;
    animation: rainbow_animation 6s ease-in-out infinite;
    background-size: 400% 100%;
}

@keyframes rainbow_animation {
    0%,100% {
        background-position: 0 0;
    }

    50% {
        background-position: 100% 0;
    }
}

.counter {
  position: absolute;
  color: white;
  width: 16px;
  height: 16px;
  background: linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff);
  animation: rainbow_animation 6s ease-in-out infinite;
    background-size: 400% 100%;
  bottom: -5px;
  right: 0px;
  line-height: 18px;
  border-radius: 11px;
  z-index: 1000000000;
  font-weight: bold;
}

.isTrial {
  text-align: center;
  margin-top: 30px;
  p.phrase {
    padding: 2px;
    margin-bottom: 0;
    margin-top: 15px;
    font-size: 20px;
  }
}
</style>
