// import SealModel from '../models/seal_model'

export default {
  namespaced: true,
  state: {
    aminoacids: {
      open: false,
      selected: ''
    },
    bioCode: {
      open: false,
      seal: {}
    }
  },
  getters: {},
  actions: {
    setBioCode (context, seal) {
      // var sealM = new SealModel()
      // var seal = sealM.getByAminoacid(amino)
      context.commit('setBioCode', seal)
    }
  },
  mutations: {
    setAminoacid (state, _toOpen) {
      state.aminoacids = {
        open: !state.aminoacids.open,
        selected: _toOpen
      }
    },
    setBioCode (state, _seal) {
      state.bioCode = {
        open: !state.bioCode.open,
        seal: _seal
      }
    }
  }
}
