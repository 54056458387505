<template>

  <div class="kin">
    <div class="head">Kin #{{kin.key}}</div>
    <div class="body" @click="openArqDialog()">
      <h4>{{kin.name}}</h4>
      <div class="images">
        <img :src="kin.tone.image" class="tone" width="150" />
        <br/>
        <img :src="kin.seal.image" width="150" />
      </div>
      <div class="phrase">
        <p v-for="(p, k) in kin.phrase" v-bind:key="k">{{p}}</p>
      </div>
    </div>
  </div>
  <q-dialog v-model="arqDialog">
    <q-layout view="lhh LpR lff" container class="dialog">
        <div class="details text-center">
            <h4 class="text-center">{{kin.seal.archetype}}</h4>
            <img :src="kin.seal.arqImg" height='300'/>
            <p style="white-space: pre-line">{{kin.seal.archetypeDesc}}</p>
        </div>
    </q-layout>
</q-dialog>
</template>

<script>
export default {
  name: 'MantraKinComponent',
  props: {
    kin: Object
  },
  data() {
    return {
      arqDialog: false
    }
  },
  methods: {
    openArqDialog() {
      this.arqDialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .images {
    margin-bottom: 10px
  }
  .body {
    cursor: pointer;
  }
</style>
