<template>
  <div class="details">
    <h4 class="text-center">Vitamina E (Tocoferol)</h4>
    <h5>Funções da Vitamina E: consequências de sua falta e consumo em excesso.</h5><br/>
    <p>
      A vitamina E, também conhecida por tocoferol, é um nutriente essencial para o funcionamento do organismo, com potente ação antioxidante. Ela é uma vitamina lipossolúvel, ou seja, do tipo solúvel em gordura. Também possui propriedades anti-inflamatórias, além de contribuir para o controle do colesterol ruim e melhorar a fertilidade.<br/><br/>
      Como se trata de uma vitamina lipossolúvel, sua absorção ocorre na porção superior do intestino delgado e depende da ação da bile e dos sais biliares. Por se tratar do antioxidante mais importante na célula. Localizada na porção lipídica das membranas celulares, ela protege e determina a suscetibilidade das membranas sofrerem com o dano provocado pelos radicais livres.
    </p>
    <h5>ONDE ENCONTRAR VITAMINA E</h5>
    <p>
      A vitamina E é ingerida através da dieta. Pode ser encontrada em alimentos de origem vegetal, principalmente nos vegetais verde-escuros, nas sementes oleaginosas, nos óleos vegetais e no germe de trigo. Espinafre, brócolis, manga, tomate e abacate estão entre os alimentos que carregam o nutriente.<br/><br/>
      Em uma alimentação balanceada, homens e mulheres adultos e saudáveis, devem ingerir, em média, 15 mg por dia de vitamina E.
    </p>
    <h5>BENEFÍCIOS DA VITAMINA E</h5>
    <p>
      A vitamina E destaca-se, sobretudo, por seu efeito antioxidante. Além disso, ajuda a prevenir o envelhecimento celular, auxilia no crescimento e desenvolvimento, participa da formação dos glóbulos vermelhos e contribui para o controle do chamado mau colesterol, o LDL.<br/><br/>
      Em estudos mais recentes, cientistas têm correlacionado as causas de doenças como Alzheimer, esclerose lateral amiotrófica (ELA) e Parkinson a presença de radicais livres em excesso no tecido nervoso. Algumas pesquisas sugerem que o aumento da ingestão de vitamina E diminui os riscos do desenvolvimento destas enfermidades.
    </p>
    <h5>DEFICIÊNCIA DE VITAMINA E</h5>
    <p>
      Crianças recém-nascidas e prematuros têm maior risco de ter deficiência de vitamina E, mas normalmente a falta do nutriente é suprida pelo leite materno. Em jovens e adultos, distúrbios no fígado ou na vesícula biliar, pancreatite, fibrose cística e outras condições que prejudicam a absorção de gordura, ou simplesmente uma dieta desequilibrada, podem levar a uma condição preocupante de eficiência de vitamina E no organismo.<br/><br/>
      A falta desta vitamina pode causar anemia hemolítica, que é caracterizada pela falta da quantia suficiente de glóbulos vermelhos. Sua deficiência também traz complicações como dificuldades para engravidar e infertilidade.<br/><br/>
      Casos leves e moderados costumam ser assintomáticos, porém mesmo um déficit leve de vitamina E já favorece o desenvolvimento de problemas de visão, fraqueza muscular, baixa imunidade e problemas cardíacos.
    </p>
    <h5>EXCESSO DE VITAMINA E</h5>
    <p>
      A alta ingestão de vitamina E pode causar náuseas, cefaleia, fadiga, hipoglicemia, alterações na função neutrofílica e alterações nos mecanismos de coagulação. Uma recomendação publicada em 2014 pela Força-tarefa de Serviços de Prevenção dos Estados Unidos (USPSTF), aconselha que pessoas saudáveis não devem tomar suplementos de vitamina E ou de betacaroteno para prevenir doenças cardíacas ou câncer.
    </p>
  </div>
</template>
