import { auth, db, functions } from '../firebase'
import firebase from 'firebase/compat/app'
import KinModel from '../models/kin_model'
import { loadStripe } from '@stripe/stripe-js';

var kinM = new KinModel()

const register = async function (payload) {
  try {
    const authi = await auth.createUserWithEmailAndPassword(
      payload.email,
      payload.password
    )

    var date = payload.date.split('-')
    var kinKey = kinM.calc(date[0], date[1], date[2])

    return db.collection('users')
      .doc(authi.user.uid)
      .set({ name: payload.name, date: payload.date, kin: kinKey, cell: payload.cellphone })
      .then((res) => {
        authi.user.sendEmailVerification()

        auth.signOut()

        return true
      })
  } catch (error) {
    console.log(error.code)

    return error.code
  }
}

const registerUserDetails = async function (uid, payload) {
  try {
    var date = payload.date.split('-')
    var kinKey = kinM.calc(date[0], date[1], date[2])

    return db.collection('users')
      .doc(uid)
      .update({ name: payload.name, date: payload.date, kin: kinKey, cell: (payload.cellphone != null) ? payload.cellphone : null })
      .then((res) => {
        return true
      })
  } catch (error) {
    console.log(error.code)

    return error.code
  }
}

const login = async function (payload) {
  try {
    await auth.signInWithEmailAndPassword(
      payload.email,
      payload.password
    )

    // if (!user.user.emailVerified) {
    //   return user.user.sendEmailVerification().then((res) => {
    //     return 'verifyEmail'
    //   }).catch((res) => {
    //     return 'errorVerifyEmail'
    //   })
    // }

    return true
  } catch (error) {
    console.log(error)
    return error.code
  }
}
const reLogin = async function (payload) {
  let result = false
  try {
    const user = auth.currentUser
    const credential = firebase.auth.EmailAuthProvider.credential(
      payload.email,
      payload.password
    )
    await user.reauthenticateWithCredential(credential)
    result = true
    return result
  } catch (error) {
    result = false
    console.log(error)
    return result
  }
}
const updatePassword = async function (payload) {
  const user = firebase.auth().currentUser
  try {
    await user.updatePassword(payload)
  } catch (error) {
    console.log(error)
  }
}
const resetPassword = async function (payload) {
  const emailAddress = payload
  try {
    const result = await auth.sendPasswordResetEmail(emailAddress)
    return result
  } catch (error) {
    console.log(error)
    return 'error'
  }
}

const updateUser = async function (userId, payload) {
  const entries = Object.entries(payload)
  const obj = {}
  entries.forEach(([key, val]) => {
    obj[key] = val
  })

  var date = payload.date.split('-')
  var kinKey = kinM.calc(date[0], date[1], date[2])
  obj.kin = kinKey

  try {
    db.collection('users').doc(userId).update(obj)
  } catch (error) {
    console.log(error)
  }
}

const getUserById = function (userId) {
  try {
    const data = db.collection('users').doc(userId).get()
    return data
  } catch (error) {
    console.log(error)
  }
}

const userIsPro = async function(userId) {
  try {
    const customer = await db.collection('customers')
      .doc(userId);
    
      customer.onSnapshot(async (snap) => {
        
        if (!snap.exists) {
          const checkoutSession = {
            collect_shipping_address: false,
            tax_rates: [],
            allow_promotion_codes: false,
            line_items: [{"price": 'price_1PWKPMP0btCORWZseLYrUOhq', "quantity": 1}],
            success_url: window.location.origin,
            cancel_url: window.location.origin,
            metadata: {
              key: 'value',
            },
            mode: 'payment',
            locale: 'auto'
          };
        
          const docRef = await db
            .collection('customers')
            .doc(userId)
            .collection('checkout_sessions')
            .add(checkoutSession);
            return false;
        }
      });

      return customer
        .collection('subscriptions')
        .where('status', 'in', ['trialing', 'active']).get()
        .then((snapshot) => {
          if (snapshot.empty) {
            return;
          }
          var d = snapshot.docs[0].data()

          var isPro = (d.status === 'active' || d.status === 'trialing') && (d.items[0].price.product.id === 'prod_QN4Y5tEfeFSfDf');
          return isPro;
        });
      
  } catch (error) {
    console.log(error);
    return false;
  }
}

const assignPlan = async function() {
  const currentUser = auth.currentUser

  const checkoutSession = {
    collect_shipping_address: false,
    tax_rates: [],
    allow_promotion_codes: false,
    line_items: [{"price": 'price_1PWKPMP0btCORWZseLYrUOhq', "quantity": 1}],
    success_url: window.location.origin,
    cancel_url: window.location.origin,
    locale: 'auto'
  }

  const docRef = await db
    .collection('customers')
    .doc(currentUser.uid)
    .collection('checkout_sessions')
    .add(checkoutSession)

  docRef.onSnapshot(async (snap) => {
    const { error, sessionId } = snap.data()

    if (error) {
      // Show an error to your customer and 
      // inspect your Cloud Function logs in the Firebase console.
      console.log('error', error)
    }
    if (sessionId) {
      // We have a session, let's redirect to Checkout
      // Init Stripe
      const stripe = await loadStripe('pk_live_51PVCkfP0btCORWZsMJODxAJ36WwM0lobdZQ03EZRBg83LUZgMieEYjLI55Zn7nXcQCcW42hmK6BNTQIo4IjQ333k00vkqLtMXo')

      return stripe.redirectToCheckout({ sessionId })
    }
  });
}

const manageSubs = async function() {
  //https://us-central1-cosmo-biologia-nutricional
  var functionRef = functions
    .httpsCallable('ext-firestore-stripe-payments-createPortalLink');
    
  const { data } = await functionRef({ returnUrl: window.location.origin });
  window.location.assign(data.url);
}

export {
  register,
  login,
  updateUser,
  getUserById,
  reLogin,
  updatePassword,
  resetPassword,
  registerUserDetails,
  userIsPro,
  assignPlan,
  manageSubs
}
