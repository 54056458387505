<template>
    <div>
        <div class="row margin-bottom-15">
            <div class="col-12 offset-0 offset-sm-3 col-sm-6 col-md-6 col-lg-6">
                <div class="q-glutter-md kinCalc">
                    <label>KIN</label>
                    <div class="input-group">
                        <input class="form-control" type="number" v-model="kinkey" min="0" max="260"/>
                        <q-btn @click="changeKin(kinkeyValue)" size="sm" icon="fa fa-chevron-right"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KinModel from '../../models/kin_model';

export default {
    name: 'KinNavComponent',
    props: {
        kin: Object
    },
    data() {
        return {
            kinkeyValue: this.kin.key
        }
    },
    computed: {
        kinkey: {
            get() {
                return this.kin.key
            },
            set(value) {
                this.kinkeyValue = value
                return this.kin.key
            }
        }
    },
    
    methods: {
        changeKin(k) {
            var kinM = new KinModel()
            var d = this.$store.state.home.date
            var toDate = kinM.calcKin(d, this.kin.key, parseInt(k))
            this.$store.commit('home/changeKin', toDate)
        }
    }
}
</script>