import seals from '../datas/seals'
import tones from '../datas/tones'
import harmonics from '../datas/tzolkin_harmonics'
import OracleModel from './oracle_model'
import MoonModel from './moon_model'
import WaveModel from './wave_model'
import SealModel from './seal_model'
import ChronoPsiModel from './chrono_psi_model'

const oracleM = new OracleModel()
const waveM = new WaveModel()
const sealM = new SealModel()
var cpsiM = new ChronoPsiModel()

export default function KinModel () {
  this.isportal = function (i) {
    var portal = [
      1,
      20,
      22,
      39,
      43,
      50,
      51,
      58,
      64,
      69,
      72,
      77,
      85,
      88,
      93,
      96,
      106,
      107,
      108,
      109,
      110,
      111,
      112,
      113,
      114,
      115,
      146,
      147,
      148,
      149,
      150,
      151,
      152,
      153,
      154,
      155,
      165,
      168,
      173,
      176,
      184,
      189,
      192,
      197,
      203,
      210,
      211,
      218,
      222,
      239,
      241,
      260
    ]

    return (portal.includes(i))
  }

  this.list = function () {
    var kins = []

    var toneKey = 0
    var sealKey = 0
    var oracleM = new OracleModel()

    for (var i = 0; i < 260; i++) {
      var isPortal = false

      if (this.isportal(i + 1)) {
        isPortal = true
      }
      if (sealKey > 19) {
        sealKey = 0
      }
      if (toneKey > 12) {
        toneKey = 0
      }

      var tone = tones[toneKey]
      var seal = seals[sealKey]
      var oracle = oracleM.getSealsOracle(seals, seal, tone)
      var name = this.getName(seal, tone)
      var phrase = this.doPhrase(seal, tone, (i + 1), oracle.guide)

      kins.push({ key: (i + 1), seal: seal, tone: tone, name: name, phrase: phrase, isPortal: isPortal, oracle: oracle })
      sealKey++
      toneKey++
    }

    return kins
  }

  this.getName = function (seal, tone) {
    var sealName = seal.name
    var colorName = seal.color
    var toneName = tone.name

    if ([
      'Semente',
      'Noite',
      'Serpente',
      'Mão',
      'Estrela',
      'Lua',
      'Águia',
      'Terra',
      'Tormenta'
    ].includes(sealName)) {
      if (toneName.substring(toneName.length - 1) === 'o') {
        toneName = toneName.substring(0, toneName.length - 1)
        toneName += 'a'
      }
      if (colorName === 'Amarelo' || colorName === 'Vermelho') {
        colorName = colorName.substring(0, colorName.length - 1)
        colorName += 'a'
      }
    }

    return sealName + ' ' + toneName + ' ' + colorName
  }

  this.doPhrase = function (seal, tone, key, guide) {
    var sealPower = seal.power
    var guidePower = guide.power

    var portalPhrase = ''
    if (this.isportal(key)) {
      portalPhrase = 'Sou um portal de ativação galáctica, entra por mim'
    }

    var toneAction = tone.action
    var semantic = [
      'a',
      'a',
      'da'
    ]

    if (toneAction.substring(toneAction.length - 1) === 'r') {
      toneAction = toneAction.substring(0, toneAction.length - 1)
    } else if (toneAction === 'Produz') {
      toneAction = 'Produzi'
    }

    if (['Ser', 'Alento', 'Instinto', 'Fluxo']
      .includes(seal.essence)) {
      semantic[0] = 'o'
    }

    // cells conains
    if (['armazém', 'processo'].includes(seal.cell)) {
      semantic[1] = 'o'
    }

    function sealPowerSemantic (power) {
      var sem = 'da '

      if ([
        'Nascimento',
        'Espirito',
        'Florescimento',
        'Coração',
        'Livre-arbítrio',
        'Espaço',
        'Infinito',
        'Fogo Universal'
      ].includes(power)) {
        sem = 'do '
      }
      return sem
    }

    if (['Propósito', 'Desafio', 'Serviço'].includes(tone.essence)) {
      semantic[2] = 'do'
    }

    var guia = 'Eu sou guiado pelo '
    if (guide.key === seal.key) {
      guia += 'meu próprio poder duplicado'
    } else {
      guia += 'poder ' + sealPowerSemantic(guidePower) + ' ' + guidePower
    }

    return [
      tone.power + ' com o fim de ' + seal.action,
      toneAction + 'ndo ' + semantic[0] + ' ' + seal.essence,
      'Selo ' + semantic[1] + ' ' + seal.cell + ' ' +
      sealPowerSemantic(sealPower) + ' ' + sealPower,
      'Com o tom ' +
      tone.name + ' ' +
      semantic[2] + ' ' +
      tone.essence,
      guia,
      portalPhrase
    ]
  }

  const listKin = this.list()

  this.calc = function (year, month, day) {
    day = parseInt(day)
    month = parseInt(month)
    year = parseInt(year)

    var yearCode = {
      1962: 62,
      1963: 167,
      1964: 12,
      1965: 117,
      1966: 222,
      1967: 67,
      1968: 172,
      1969: 17,
      1970: 122,
      1971: 227,
      1972: 72,
      1973: 177,
      1974: 22,
      1975: 127,
      1976: 232,
      1977: 77,
      1978: 182,
      1979: 27,
      1980: 132,
      1981: 237,
      1982: 82,
      1983: 187,
      1984: 32,
      1985: 137,
      1986: 242,
      1987: 87,
      1988: 192,
      1989: 37,
      1990: 142,
      1991: 247,
      1992: 92,
      1993: 197,
      1994: 42,
      1995: 147,
      1996: 252,
      1997: 97,
      1998: 202,
      1999: 47,
      2000: 152,
      2001: 257,
      2002: 102,
      2003: 207,
      2004: 52,
      2005: 157,
      2006: 2,
      2007: 107,
      2008: 212,
      2009: 57,
      2010: 162,
      2011: 7,
      2012: 112,
      2013: 217
    }

    var monthCode = {
      1: 0,
      2: 31,
      3: 59,
      4: 90,
      5: 120,
      6: 151,
      7: 181,
      8: 212,
      9: 243,
      10: 13,
      11: 44,
      12: 74
    }

    var nYear = year
    if (nYear > 2013) {
      while (nYear > 2013) {
        nYear = nYear - 52
      }
    } else if (nYear < 1962) {
      while (nYear < 1962) {
        nYear = nYear + 52
      }
    }

    var codeKin = yearCode[nYear] + monthCode[month] + day

    if (codeKin > 260) {
      codeKin = codeKin - 260
    }

    return codeKin
  }

  this.calcYearOfKin = function (year, month, day) {
    day = parseInt(day)
    month = parseInt(month)
    year = parseInt(year)

    // 26/07 start the year
    var currentYear = year
    if (month < 7) {
      currentYear = year - 1
    } else if (month === 7) {
      if (day < 26) {
        currentYear = year - 1
      }
    }
    return this.calc(currentYear, 7, 26)
  }

  this.calcCalender = function (year, month, day) {
    day = parseInt(day)
    month = parseInt(month)
    year = parseInt(year)

    var kinYear = this.calcYearOfKin(year, month, day)
    var moon
    // var kinsOnMoon = [];
    if (!(month === 7 && day === 25)) {
      var moonM = new MoonModel()
      moon = moonM.calcMoon(kinYear, month, day)

      // var key = (moon['kinStart'] - 1);
      // var j = 0;

      // for (var i = 0; i < 28; i++) {
      //   if ((i + key) >= 260) {
      //     j = 0;
      //     key = (i + key) - 260;
      //   }

      //   kinsOnMoon.add(kins[j + key]);
      //   j++;
      // }
    }

    var newYear
    if (moon === undefined) {
      newYear = listKin[(this.calcYearOfKin(year, month, day + 1) - 1)]
    }

    return { year: kinYear, newYear, moon }
  }

  this.constructKin = function (kin) {
    var oracle = oracleM.getKins(kin, listKin)

    var wave = waveM.getWave(listKin, kin)

    var chromatics = {
      family: sealM.getByFamily(kin.seal.family),
      cla: sealM.getByCla(kin.seal.cla)
    }

    var harmonic = this.getTzolkinHarmonic(kin.key)

    return { kin, oracle, wave, chromatics, harmonic }
  }

  this.constructData = function (dateS) {
    var date = dateS.split('-')
    if (date[1] === '02' && parseInt(date[2]) === 29) {
      return { hunabKu: true, date: dateS }
    } else {
      var kinKey = this.calc(date[0], date[1], date[2])

      var kin = listKin[(kinKey - 1)]

      var calendar = this.calcCalender(date[0], date[1], date[2])
      calendar.year = listKin[calendar.year - 1]
      var cpsi
      if (calendar.moon !== undefined) {
        cpsi = cpsiM.getByDay(calendar.moon.moon.key, calendar.moon.positionDay)
      }

      var response = this.constructKin(kin)
      response.date = dateS
      response.cpsi = cpsi
      response.calendar = calendar
      response.hunabKu = false

      return response
    }
  }

  this.getTzolkinHarmonic = function (kinKey) {
    var harmonicKey = Math.ceil((kinKey / 4))
    return harmonics[harmonicKey - 1]
  }

   this.calcKin = function(date, kin, goKin) {
    var parts = date.split('-');
    var toDate = new Date(parts[0], parts[1] - 1, parts[2]);

    let difference = Math.abs(kin - goKin);

    if (goKin > kin) {
      console.log(difference);
        toDate.setDate(toDate.getDate() + difference);
        
        if (hasLeapDayBetween( new Date(date), toDate)) {
            toDate.setDate(toDate.getDate() + 1);
        }
    } else {
        toDate.setDate(toDate.getDate() - difference);
        if (hasLeapDayBetween(toDate,  new Date(date))) {
            toDate.setDate(toDate.getDate() - 1);
        }
    }

    let day = toDate.getDate().toString().padStart(2, '0');
    let month = (toDate.getMonth() + 1).toString().padStart(2, '0'); // getMonth() é baseado em zero
    let year = toDate.getFullYear();

    return `${year}-${month}-${day}`;
  }

  function isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  }

  function hasLeapDayBetween(startDateS, endDateS) {
    let startDate = new Date(startDateS)
    let endDate = new Date(endDateS)
      for (let year = startDate.getFullYear(); year <= endDate.getFullYear(); year++) {
          if (isLeapYear(year)) {
              let leapDay = new Date(year, 1, 29); // Mês é zero-indexado em JavaScript, então 1 representa fevereiro
              if (leapDay > startDate && leapDay < endDate) {
                  return true;
              }
          }
      }
      return false;
  }
}
