<template>
  <div class="kin sync1328">
    <div class="head">Data das 13 Luas</div>
    <div class="body">
      <div v-if="moon !== null">
        <div class="row margin-bottom-15 glutter-x-0">
          <div class="col-2 col-sm-2 col-md-2 -margin-top-15">
            <div class="column align-items-end">
              <img :src="year.tone.image" width="50" class="tone">
              <img :src="year.seal.image" width="30">
            </div>
          </div>
          <div class="col-10 col-sm-10 col-md-10 text-left flex align-items-end">
            <h5 class="padding-left-10">Ano {{year.name}}</h5>
          </div>
        </div>
        <h5 class="margin-bottom-15">{{moon.name}}</h5>
        <h5>Dia {{day}}</h5>
        <img :src="plasma.img" />
        <br/>
        <h5>{{plasma.name}}</h5>
      </div>
      <div v-else>
        <h5>Dia Fora do Tempo</h5>
        <div class="dayOutTime">
          <div class="flex-center title">
            <div class="yantra-kin">
              <div>
                <img :src="calendar.year.tone.image" width="40"/>
                <br/>
                <img :src="calendar.year.seal.image" width="40" />
                <br/>
              </div>
            </div>
            <img class="dayOutTimeIMG" :src="dayOutTime" width="40">
            <div class="yantra-kin">
              <div>
                <img :src="calendar.newYear.tone.image" width="40"/>
                <br/>
                <img :src="calendar.newYear.seal.image" width="40" />
                <br/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import plasma1 from '../../assets/images/plasma/plasma1.png'
import plasma2 from '../../assets/images/plasma/plasma2.png'
import plasma3 from '../../assets/images/plasma/plasma3.png'
import plasma4 from '../../assets/images/plasma/plasma4.png'
import plasma5 from '../../assets/images/plasma/plasma5.png'
import plasma6 from '../../assets/images/plasma/plasma6.png'
import plasma7 from '../../assets/images/plasma/plasma7.png'
import dayOutTime from '../../assets/images/diaforadotempo.png'

export default {
  name: 'Sync1328Component',
  props: {
    calendar: Object
  },
  data () {
    return { dayOutTime }
  },
  computed: {
    year () {
      return this.calendar.year
    },
    moon () {
      if (this.calendar.moon === undefined) {
        return null
      }
      return this.calendar.moon.moon
    },
    day () {
      if (this.calendar.moon === undefined) {
        return null
      }
      return this.calendar.moon.positionDay
    },
    plasma () {
      if (this.calendar.moon === undefined) {
        return null
      }
      var day = this.calendar.moon.positionDay
      if ([1, 8, 15, 22].includes(day)) {
        return { name: 'Dali', img: plasma1 }
      } else if ([2, 9, 16, 23].includes(day)) {
        return { name: 'Seli', img: plasma2 }
      } else if ([3, 10, 17, 24].includes(day)) {
        return { name: 'Gama', img: plasma3 }
      } else if ([4, 11, 18, 25].includes(day)) {
        return { name: 'Kali', img: plasma4 }
      } else if ([5, 12, 19, 26].includes(day)) {
        return { name: 'Alfa', img: plasma5 }
      } else if ([6, 13, 20, 27].includes(day)) {
        return { name: 'Limi', img: plasma6 }
      }
      return { name: 'Sílio', img: plasma7 }
    }
  }
}
</script>

<style scoped>
  .body {
    padding-left: 18px;
    padding-right: 18px;
  }
  .tone {
    margin-right: -10px;
  }
  .dayOutTimeIMG {
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 13px;
  }
</style>
