<template>
  <div class="kin wave">
    <div class="head">Onda Encantada #{{wave.key}}</div>
    <div class="body flex-center">
      <table>
        <tbody>
          <tr>
            <td>
              <div class="item" @click="openDetails(wave.kins[0])" :class="(isToday(0) ? 'bg-purple' : 'bg-green')">
                <img :src="wave.kins[0].tone.imageSm" class="tone" width="40"/>
                <br/>
                <img :src="wave.kins[0].seal.image" class="seal" width="40"/>
                <q-tooltip class="bg-purple text-body2" :offset="[10, 10]">
                  Qual é meu propósito?
                </q-tooltip>
              </div>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              <div class="item" @click="openDetails(wave.kins[1])" :class="(isToday(1) ? 'bg-purple' : 'bg-white')">
                <img :src="wave.kins[1].tone.imageSm" class="tone" width="40"/>
                <br/>
                <img :src="wave.kins[1].seal.image" class="seal" width="40"/>
                <q-tooltip class="bg-purple text-body2" :offset="[10, 10]">
                  Qual é meu desafio?
                </q-tooltip>
              </div>
            </td>
            <td></td>
            <td></td>
            <td>
              <div class="item" @click="openDetails(wave.kins[12])" :class="(isToday(12) ? 'bg-purple' : 'bg-green')">
                <img :src="wave.kins[12].tone.imageSm" class="tone" width="40"/>
                <br/>
                <img :src="wave.kins[12].seal.image" class="seal" width="40"/>
                <q-tooltip class="bg-purple text-body2" :offset="[10, 10]">
                  Como posso aumentar minha alegria e meu amor?
                </q-tooltip>
              </div>
            </td>
            <td>
              <div class="item" @click="openDetails(wave.kins[11])" :class="(isToday(11) ? 'bg-purple' : 'bg-white')">
                <img :src="wave.kins[11].tone.imageSm" class="tone" width="40"/>
                <br/>
                <img :src="wave.kins[11].seal.image" class="seal" width="40"/>
                <q-tooltip class="bg-purple text-body2" :offset="[10, 10]">
                  Como posso dedicar meu ser a tudo que existe?
                </q-tooltip>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="item" @click="openDetails(wave.kins[2])" :class="(isToday(2) ? 'bg-purple' : 'bg-white')">
                <img :src="wave.kins[2].tone.imageSm" class="tone" width="40"/>
                <br/>
                <img :src="wave.kins[2].seal.image" class="seal" width="40"/>
                <q-tooltip class="bg-purple text-body2" :offset="[10, 10]">
                  Qual é minha melhor maneira de servir?
                </q-tooltip>

              </div>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <div class="item" @click="openDetails(wave.kins[10])" :class="(isToday(10) ? 'bg-purple' : 'bg-white')">
                <img :src="wave.kins[10].tone.imageSm" class="tone" width="40"/>
                <br/>
                <img :src="wave.kins[10].seal.image" class="seal" width="40"/>
                <q-tooltip class="bg-purple text-body2" :offset="[10, 10]">
                  Como posso me liberar e deixar ir?
                </q-tooltip>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="item" @click="openDetails(wave.kins[3])" :class="(isToday(3) ? 'bg-purple' : 'bg-white')">
                <img :src="wave.kins[3].tone.imageSm" class="tone" width="40"/>
                <br/>
                <img :src="wave.kins[3].seal.image" class="seal" width="40"/>
                <q-tooltip class="bg-purple text-body2" :offset="[10, 10]">
                  Qual é minha forma de agir?
                </q-tooltip>
              </div>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <div class="item" @click="openDetails(wave.kins[9])" :class="(isToday(9) ? 'bg-purple' : 'bg-white')">
                <img :src="wave.kins[9].tone.imageSm" class="tone" width="40"/>
                <br/>
                <img :src="wave.kins[9].seal.image" class="seal" width="40"/>
                <q-tooltip class="bg-purple text-body2" :offset="[10, 10]">
                  Como aperfeiçoo o que faço?
                </q-tooltip>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="item" @click="openDetails(wave.kins[4])" :class="(isToday(4) ? 'bg-purple' : 'bg-green')">
                <img :src="wave.kins[4].tone.imageSm" class="tone" width="40"/>
                <br/>
                <img :src="wave.kins[4].seal.image" class="seal" width="40"/>
                <q-tooltip class="bg-purple text-body2" :offset="[10, 10]">
                  Como domino minha vida?
                </q-tooltip>
              </div>
            </td>
            <td>
              <div class="item" @click="openDetails(wave.kins[5])" :class="(isToday(5) ? 'bg-purple' : 'bg-white')">
                <img :src="wave.kins[5].tone.imageSm" class="tone" width="40"/>
                <br/>
                <img :src="wave.kins[5].seal.image" class="seal" width="40"/>
                <q-tooltip class="bg-purple text-body2" :offset="[10, 10]">
                  O que preciso para me equilibrar e organizar?
                </q-tooltip>
              </div>
            </td>
            <td>
              <div class="item" @click="openDetails(wave.kins[6])" :class="(isToday(6) ? 'bg-purple' : 'bg-white')">
                <img :src="wave.kins[6].tone.imageSm" class="tone" width="40"/>
                <br/>
                <img :src="wave.kins[6].seal.image" class="seal" width="40"/>
                <q-tooltip class="bg-purple text-body2" :offset="[10, 10]">
                  Como posso harmonizar meu serviço com os demais?
                </q-tooltip>
              </div>
            </td>
            <td>
              <div class="item" @click="openDetails(wave.kins[7])" :class="(isToday(7) ? 'bg-purple' : 'bg-white')">
                <img :src="wave.kins[7].tone.imageSm" class="tone" width="40"/>
                <br/>
                <img :src="wave.kins[7].seal.image" class="seal" width="40"/>
                <q-tooltip class="bg-purple text-body2" :offset="[10, 10]">
                  Como vivo o que acredito?
                </q-tooltip>
              </div>
            </td>
            <td>
              <div class="item" @click="openDetails(wave.kins[8])" :class="(isToday(8) ? 'bg-purple' : 'bg-green')">
                <img :src="wave.kins[8].tone.imageSm" class="tone" width="40"/>
                <br/>
                <img :src="wave.kins[8].seal.image" class="seal" width="40"/>
                <q-tooltip class="bg-purple text-body2" :offset="[10, 10]">
                  Como atingir meu propósito?
                </q-tooltip>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <q-dialog v-model="waveDetails">
    <q-layout view="lhh LpR lff" container class="dialog">
      <div class="details">
        <h3 class="text-center">#{{selectedKin.tone.key}} Tom {{selectedKin.tone.name}}</h3>
        <div class="text-center">
          <img :src="selectedKin.tone.image" width="80"/>
        </div>
        <h5 class="text-center margin-top-15">{{selectedKin.tone.question}}</h5>
        <h5 class="margin-top-15">Poder: {{selectedKin.tone.power}}</h5>
        <h5>Ação: {{selectedKin.tone.action}}</h5>
        <h5>Essência: {{selectedKin.tone.essence}}</h5>
        <p class="margin-top-15">{{selectedKin.tone.description}}</p>
        <div class="margin-top-15">
          <h3 class="text-center">Selo #{{selectedKin.seal.key}}: {{selectedKin.seal.name}}</h3>
          <div class="text-center">
            <img :src="selectedKin.seal.image" width="80"/>
          </div>
          <h5 class="margin-top-15">Arquétipo: {{selectedKin.seal.archetype}}</h5>
          <h5 class="margin-top-15">Poder: {{selectedKin.seal.power}}</h5>
          <h5>Ação: {{selectedKin.seal.action}}</h5>
          <h5>Essência: {{selectedKin.seal.essence}}</h5>
          <h5 class="margin-top-15">Direção: {{selectedKin.seal.direction}}</h5>
          <h5>Planeta: {{selectedKin.seal.planet}}</h5>
          <p class="margin-top-15">{{selectedKin.seal.desc}}</p>
        </div>
      </div>
    </q-layout>
  </q-dialog>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'WaveComponent',
  props: {
    wave: Object,
    kin: Object
  },
  data () {
    return {
      waveDetails: ref(false),
      selectedKin: {}
    }
  },
  methods: {
    isToday (key) {
      if (this.wave.kins[key].key === this.kin.key) {
        return true
      }
      return false
    },
    openDetails (kin) {
      this.selectedKin = kin
      this.waveDetails = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .wave {
    td {
      padding: 2px;
      // width: 66px;
      .item {
        cursor: pointer;
        padding: 2px;
        border-radius: 2px;
        &:hover {
          background-color: purple !important;
        }
        .seal {
          margin-top: -6px;
        }
        .tone {
          margin-top: -8px;
        }
      }
      .bg-white {
        background-color: var(--light-medium) !important
      }
      .bg-purple {
        background-color: purple !important
      }
      .bg-green {
        background-color: green !important
      }
    }
  }
</style>
