<template>
  <div class="details">
    <h4 class="text-center">Níquel</h4>
    <p>
      O níquel é um metal que ocorre naturalmente e é encontrado em abundância na crosta e no núcleo da Terra. Possui uma variedade de aplicações industriais, mas mais de dois terços da produção global são direcionados à produção de aço inoxidável.
      <br/><br/>
      A alergia ao níquel é comum em algumas estimativas, colocando a prevalência em 18% na América do Norte. Um teste de adesivo pode ser usado para diagnosticar conclusivamente uma alergia ao níquel.
      <br/><br/>
      Nas pessoas afetadas, a alergia ao níquel é uma causa comum de dermatite alérgica de contato e eczema disidrótico. Se você desenvolver uma erupção cutânea, seu médico pode recomendar a substituição de certos itens do dia a dia por alternativas hipoalergênicas.
      <br/><br/>
      Armações de jóias, piercings, pulseiras de relógio e óculos são apenas alguns dos produtos que podem expô-lo ao níquel.
      <br/><br/>
      Algumas pessoas afetadas seguem uma dieta pobre em níquel, com o objetivo de minimizar o consumo de alimentos com alto teor de níquel. Embora o níquel não possa ser totalmente evitado, é possível reduzir a ingestão alimentar do metal através de um planejamento cuidadoso.
    </p>
    <h5>Alimentos que contém Níquel</h5>
    <p>Feijão branco, alface, abacaxi, maçã, chocolate, nozes, trigo, aveia, ervilha, lentilha, milho.</p>
  </div>
</template>
