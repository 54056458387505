<template>
  <div class="details">
    <h4 class="text-center">Cobalto</h4>
    <p>
      É um mineral que integra a vitamina B-12, cujo nome é cianocobalamina. Tem como principal função prevenir a anemia, por meio da produção de células. Outra função importante é que o cobalto regula o sistema nervoso parassimpático. Exemplos dessa necessidade do cobalto são a palidez, como se a pessoa tivesse levado um susto, os espasmos musculares, tremores e cansaços, acompanhados de taquicardias e tensão emocional.
      <br/>
      Observa-se deficiência do cobalto em doenças como anemias, ansiedades, diarréias, fadigas físicas e mentais, nas hipertensões arteriais, nas palpitações e tremores de extremidades. O que pode inibir sua presença no organismo humano são as deficiências de vitamina B-6 e ferro.
      <br/>
      Recomenda-se a ingestão de vegetais, em especial a lentilha, produtos à base de soja e seus derivados, pêras, cerejas.
    </p>
  </div>
</template>
