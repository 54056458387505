<template>
  <div class="details">
    <h4 class="text-center">Histidina</h4><br/>
    <p>
      1. Cria a substância que regula as respostas anti-inflamatórias do organismo<br/>
      2. Diminui a acidez do estomago<br/>
      3. Melhora náuseas (principalmente em gestantes) <br/>
      4. Combate doenças circulatórias<br/>
      5. Atua no aparelho cardiovascular, pois é um excelente vasodilatador<br/><br/>
      Este aminoácido é encontrado em abundância na hemoglobina e é usado no tratamento de artrite reumatoide, alergias, úlceras e anemia. É essencial para o crescimento e o reparo dos tecidos. A <span class="bold">histidina</span> também é importante para a manutenção das bainhas de mielina que protegem as células nervosas, é necessária para a produção de glóbulos vermelhos e alvos no sangue, bem como protege o corpo dos danos da radiação e reduz a pressão arterial. A histidina auxilia na remoção de metais pesados do corpo e na excitação sexual.
    </p>
    <h5>Lista de alimentos ricos em Histidina</h5>
    <ul>
      <li>Trigo integral, cevada, centeio;</li>
      <li>Nozes, castanha do pará, castanha de caju;</li>
      <li>Cacau;</li>
      <li>Ervilha, feijão;</li>
      <li>Cenoura, beterraba, berinjela, nabo, mandioca, batata.</li>
    </ul>
  </div>
</template>
