<template>
  <div class="details">
    <h4 class="text-center">Ácido Glutâmico</h4><br/>
    <p>
      1. Importante neurotransmissor para a obtenção do glutamato, que desempenha um papel importante para o aprendizado e memória<br/>
      2. Origina substâncias para o bom funcionamento do organismo<br/>
      3. Ajuda a forma músculo<br/>
      4. Desintoxicação cerebral (amônia) <br/>
      5. Fortalecimento do sistema imunológico <br/>
      6. Produção de energia <br/>
      7. Diminui ansiedade <br/>
      8. Melhora função cardíaca e do cérebro <br/>
      9. Eliminação de substâncias toxica da circulação<br/>
      10. Mobiliza gordura e ajuda no emagrecimento<br/><br/>
      O <span class="bold">ácido glutâmico</span> é um aminoácido importante para o bom funcionamento do cérebro, além de ser fundamental para originar outras substâncias essenciais para o bom funcionamento do organismo, como o glutamato, a prolina, o ácido gama-aminobutírico (GABA), a ornitina e a glutamina, que é um aminoácido que fica rapidamente disponível e é fundamental para o processo de formação do músculo, sendo muitas vezes usada como suplemento por pessoas que desejam ganhar massa muscular.
    </p>
    <h5>Alimentos ricos em Ácido Glutâmico</h5>
    <p>Ervilha, feijão, aveia, amendoim, amêndoas, castanha do Pará, castanha de caju, vagem, quiabo, cenoura, beringela, beterraba, brócolis, aspargos, batata inglesa, alface, alho, mandioca, agrião, abóbora.</p>
  </div>
</template>
