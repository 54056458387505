export default function WaveModel () {
  this.getWaves = function (kinsList) {
    var waves = []
    kinsList.forEach(e => {
      if (e.tone.key === 1) {
        var kins = []
        for (var i = 0; i < 13; i++) {
          kins.push(kinsList[(e.key - 1) + i])
        }
        var key = (e.key - 1) / 13

        waves.push({ key: Math.round((key + 1)), seal: e.seal, kins: kins })
      }
    })

    return waves
  }

  this.getWave = function (kins, kin) {
    var waveKin = kins[(kin.key - kin.tone.key)]
    var key = Math.round(((waveKin.key - 1) / 13))

    var waves = this.getWaves(kins)

    return waves[key]
  }
}
