<template>
  <div class="details">
    <h4 class="text-center">Serina</h4><br/>
    <h5>Funções da Serina:</h5>
    <p>
      1. Aumenta a defesa do organismo <br/>
      2. Promove o bom funcionamento do sistema nervoso <br/>
      3. Atua na transformação das gorduras<br/>
      4. Crescimento muscular<br/><br/>
      5. Formar fosfolipídios necessários para criar todas as células do corpo humano<br/><br/>
      A <span class="bold">serina</span> serve para aumentar as defesas do organismo, ajudar no bom funcionamento do sistema nervoso, participar na transformação de gorduras e no crescimento muscular.
    </p>
    <h5>Lista de alimentos ricos em Serina</h5>
    <ul>
      <li>Avelã, castanha de caju, castanha do pará, noz pecã, amêndoas, amendoim;</li>
      <li>Feijão, milho;</li>
      <li>Cevada, centeio;</li>
      <li>Beterraba, berinjela, batata inglesa, cogumelos, abóbora, cebola roxa, alho.</li>
    </ul>
  </div>
</template>
