<template>
  <div class="details">
    <h4 class="text-center">Alanina</h4><br/>
    <p>
      1. É o aminoácido mais facilmente utilizado na fonte de energia para o corpo<br/>
      2. Melhora o metabolismo do álcool <br/>
      3. Utilizado como material para síntese de glicose (açúcar no sangue)<br/>
      4. Para rápida recuperação da fadiga durante os exercícios físicos <br/><br/>
      A <span class="bold">alanina</span> desempenha um papel importante na transferência de nitrogênio dos tecidos periféricos para o fígado, auxilia no metabolismo da glicose, um carboidrato simples que o corpo usa para obter energia, protege contra o acúmulo de substâncias tóxicas que são liberadas nas células musculares quando a proteína muscular decompõe-se rapidamente para atender às necessidades de energia, como ocorre nos exercícios aeróbiocos e, por fim, fortalece o sistema imunológico ao produzir anticorpos.
    </p>
    <h5>Lista de alimentos ricos em Alanina</h5>
    <ul>
      <li>Aspargo, mandioca, batata inglesa, cenoura, berinjela, beterraba;</li>
      <li>Aveia, cacau, centeio, cevada;</li>
      <li>Coco, abacate;</li>
      <li>Avelã, nozes, castanha de caju, castanha do pará, amêndoas, amendoim;</li>
      <li>Milho, feijão, ervilha.</li>
    </ul>
  </div>
</template>
