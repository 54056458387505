<template>
  <div class="details">
    <h4 class="text-center">COBRE</h4>
    <h5>O cobre é um dos minerais mais importantes para a saúde dos tecidos entre outras estruturas.</h5><br/>
    <p>
      O cobre está entre os minerais que possuem funções essenciais tanto na estrutura de tecidos e biomoléculas, como no próprio metabolismo, participando como cofatores enzimáticos, ativação de ação hormonal, como responsáveis pela pressão osmótica e pelo equilíbrio acidobásico. Os minerais podem ser divididos em macro e micronutrientes.<br/>
      O cobre é um microelemento químico, assim como zinco, selênio e cobalto, e é representado pelo símbolo Cu (do latim cuprum). Ele participa de diversos processos no organismo, tais como a metabolização e absorção do ferro, necessário para a produção de energia; a formação de elastina e do colágeno, que são proteínas presentes em várias partes do corpo como, por exemplo, nos vasos sanguíneos; ação antioxidante, regulador do colesterol e produção de melanina, responsável pela pigmentação da pele e pelos.
    </p>
    <h5>DOENÇAS DECORRENTES DA DEFICIÊNCIA DE COBRE</h5>
    <p>
      Como o cobre faz parte de diversas reações no organismo, é componente fundamental de diversas enzimas e de vários tecidos. Por isso, a sua deficiência levará a diversas síndromes e prejuízos.<br/>
      Mesmo sendo rara, as principais manifestações da deficiência de cobre são: anemia microcítica e hipocrômica, devido a redução do volume corpuscular e concentração de hemoglobina. Existem alguns sinais clínicos que tornam possível a constatação dessa deficiência, como a baixa pigmentação e a deficiência no crescimento; e a deficiência do sistema imunológico, já que as baixas no mineral levam à diminuição das células de defesa do sangue, aumentando a suscetibilidade para infecções.<br/>
      Quando o cobre está em excesso ou em deficiência no sangue, é importante dosá-lo, fazendo o tratamento adequado. O acúmulo de cobre provoca dores musculares e nas juntas, distúrbios no aprendizado, depressão e fadiga.<br/>
    </p>
    <h5>ALIMENTOS RICOS EM COBRE</h5>
    <p>
      Os alimentos ricos em cobre são sementes e oleaginosas. Podemos citar também cereais integrais, nozes, verduras de folha, ervilhas, beterraba, fígado, rim, germe de trigo, legumes, amêndoas, amendoim, chá preto, soja, lentilha, batata doce e quinoa. A recomendação de consumo de cobre para adultos é de 900mcg (microgramas) diárias, sendo que o organismo humano contém cerca de 80mg de cobre para um homem de 70 kg.<br/>
      O cobre é um nutriente importante para a saúde do nosso corpo, mas quando consumido em excesso também pode acarretar problemas. Ele tende a se acumular no sangue e, com isto, esgotar as reservas de zinco do cérebro. Além disso, altos níveis de cobre podem causar oxidação da vitamina A e diminuição da vitamina C, provocando dores musculares e nas juntas, distúrbios no aprendizado, depressão e fadiga.<br/>
      O excesso de cobre também se associa com: disfunções comportamentais, como irritação e depressão. Anemia aplástica e megaloblástica, talassemia, nefrite, Doença de Wilson, vários tipos de doenças hepáticas, esquizofrenia, eczema, anemia drepanocítica, Doença de Hodgkin, leucemias e outras doenças malignas.
    </p>
  </div>
</template>
