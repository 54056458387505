<template>
  <div class="details">
    <h4 class="text-center">Ácido Aspártico</h4><br/>
    <p>
      1. Estimula ganho de massa muscular<br/>
      2. Aumenta a testosterona <br/>
      3. Aumenta a fertilidade masculina<br/><br/>
      O ácido <span class="bold">aspártico</span> age no organismo muitas vezes como um estimulante à produção de energias nas células, pois fortalece o sistema imunológico e aumenta a produção da testosterona, hormônio masculino que auxilia no ganho e no aumento de massa muscular. Esta última característica do ácido aspártico, o estímulo do ganho de massa muscular, faz o aminoácido ter uma grande procura por parte de praticantes de musculação, mas, além deles, homens com problemas para ter filhos também o buscam por causa da testosterona, que por sua vez aumenta a fertilidade masculina. Entretanto, o consumo de ácido aspártico não se dá exclusivamente por meio de suplementos, há também uma lista de alimentos ricos neste aminoácido.
    </p>
    <h5>Lista de alimentos ricos em Ácido Aspártico</h5>
    <ul>
      <li>Castanha de caju, castanha do pará, nozes, amêndoas, amendoim, avelã;</li>
      <li>Abacate, ameixas, banana, pêssego, damasco, coco;</li>
      <li>Ervilha, milho, centeio, cevada;</li>
      <li>Alho, cogumelo, beterraba e berinjela.</li>
    </ul>
  </div>
</template>
