<template>
  <div class="details">
    <h4 class="text-center">VITAMINA Paba (Paraminobenzóico)</h4>
    <p>O PABA é pertencente ao grupo do complexo B, é hidrossolúvel, também conhecida como vitamina BX. É produzida no intestino pelas bactérias intestinais, que juntas produzem o ácido fólico (daí sua importância na produção das células vermelhas do sangue).</p>
    <h5>Funções do PABA no organismo</h5>
    <ul>
      <li>Contribui para redução do risco de câncer de pele, mantem a pigmentação e cria resistência contra queimaduras de sol; Reduz as dores causadas por queimaduras;</li>
      <li>Protege a pigmentação dos cabelos;</li>
      <li>Melhora a influência do ácido patotênico (vitamina B5);</li>
      <li>Auxilia na produção do ácido fólico;</li>
      <li>Utilizado no tratamento do Vitiligo (manchas brancas na pele) e eczema;</li>
      <li>Previne o mau hálito e odor corporal, principalmente quando administrado com vitamina B6, zinco e magnésio.</li>
    </ul>
    <h5>Quantidades diárias recomendadas?</h5>
    <p>Não existem doses diárias estabelecidas, é produzida pela microbiota intestinal, e se o intestino estiver em boas condições, não há necessidade de suplementos (é encontrada em todas as fórmulas do complexo B). Adulto necessitam de 30 e 100 mg diários.</p>
    <h5>Quais os alimentos que encontramos PABA?</h5>
    <p>É encontrado no arroz integral, grãos integrais não torrados, levedura de cerveja seca, farelo de trigo.</p>
  </div>
</template>
