<template>
  <div class="details">
    <h4 class="text-center">Enxofre</h4>
    <p>
      O enxofre desempenha um papel importante em funções cruciais do corpo, como produzir proteínas, regular a expressão gênica, construir e reparar o DNA e ajudar o corpo a metabolizar os alimentos.
      Esse elemento também é essencial para produzir e reciclar a glutationa – um dos principais antioxidantes do corpo que ajuda a reduzir a inflamação e a prevenir danos às células causados pelo estresse oxidativo.
      O enxofre também ajuda a manter a integridade dos tecidos conjuntivos, como pele, tendões e ligamentos.
    </p>
    <h5>Alimentos e bebidas ricos em enxofre</h5>
    <ul>
      <li>Leguminosas: especialmente soja, feijão preto, feijão-carioca, ervilha, lentilha e feijão branco;</li>
      <li>Nozes e sementes: especialmente amêndoas, castanha-do-pará, castanha-de-caju, amendoins, nozes e sementes de abóbora e gergelim;</li>
      <li>Frutas secas: especialmente pêssegos, uva-passa branca, damascos e figos secos;</li>
      <li>Certos vegetais: particularmente aspargos, brócolis, couve-de-bruxelas, repolho-roxo, alho-poró, cebola, rabanete e o agrião;</li>
      <li>Certos grãos: especialmente cevada, aveia, trigo e farinhas feitas com esses grãos;</li>
      <li>Certas bebidas: vinho, leite de coco e suco de uva e tomate;</li>
      <li>Condimentos e especiarias: especialmente raiz-forte, mostarda, marmita, curry em pó e gengibre em pó.</li>
    </ul>
    <p>A água potável também pode conter quantidades significativas de enxofre, dependendo de onde você mora. Isso pode ser especialmente verdadeiro se você extrair a água de um poço.</p>
  </div>
</template>
