import { db } from '../firebase'

const save = function (uid, datas, year, moon) {
  var collection = db.collection('users')
    .doc(uid)
    .collection('moon_goal')

  return collection
    .where('year', '==', year)
    .where('moon', '==', moon)
    .get().then((existent) => {
      if (existent.docs.length === 0) {
        return collection
          .add({
            selecteds: datas.selecteds,
            year: year,
            moon: moon
          })
          .then((res) => {
            return true
          })
          .catch((err) => {
            console.log(err)
            return false
          })
      } else {
        return collection.doc(existent.docs[0].id).update(datas)
          .then((res) => {
            return true
          })
          .catch((err) => {
            console.log(err)
            return false
          })
      }
    })
}

const list = function (uid, year, moon) {
  if (year !== undefined && moon !== undefined) {
    return db.collection('users')
      .doc(uid)
      .collection('moon_goal')
      .where('year', '==', year)
      .where('moon', '==', moon)
      .get()
      .then((res) => {
        return res
      })
      .catch((err) => {
        console.log(err)
        return false
      })
  }
  return db.collection('users')
    .doc(uid)
    .collection('moon_goal')
    .get()
}

export { save, list }
