import { db } from '../firebase'

const saveMoonGoal = function (uid, datas, year, moon) {
  const collection = db.collection('users')
    .doc(uid)
    .collection('moon_goal')

  return collection
    .where('year', '==', year)
    .where('moon', '==', moon)
    .get().then((existent) => {
      if (existent.docs.length === 0) {
        return collection
          .add({ year, moon })
          .then((res) => {
            return res.update(datas)
          })
          .catch((err) => {
            console.log(err)
            return false
          })
      } else {
        const first = existent.docs[0]
        const docRef = collection.doc(first.id)

        return docRef.update(datas)
          .then((res) => {
            return true
          })
          .catch((err) => {
            console.log(err)
            return false
          })
      }
    })
}

const listMoonGoal = function (uid, year, moon) {
  if (year !== undefined && moon !== undefined) {
    return db.collection('users')
      .doc(uid)
      .collection('moon_goal')
      .where('year', '==', year)
      .where('moon', '==', moon)
      .get()
      .then((res) => {
        return res
      })
      .catch((err) => {
        console.log(err)
        return false
      })
  }
  return db.collection('users')
    .doc(uid)
    .collection('moon_goal')
    .orderBy('year', 'desc')
    .orderBy('moon', 'desc')
    .get()
}

// Wave Goal

const listWaveGoal = function (uid, year, moon) {
  const collection = db.collection('users')
    .doc(uid)
    .collection('moon_goal')

  return collection
    .where('year', '==', year)
    .where('moon', '==', moon)
    .get()
    .then((res) => {
      if (res.docs.length > 0) {
        return collection.doc(res.docs[0].id).collection('waves').get()
      }
      return { docs: [] }
    })
}

const registerWaveGoal = function (uid, year, moon, datas) {
  const collection = db.collection('users')
    .doc(uid)
    .collection('moon_goal')

  return collection
    .where('year', '==', year)
    .where('moon', '==', moon)
    .get().then((existent) => {
      if (existent.docs.length === 0) {
        return collection
          .add({
            selecteds: [],
            year: year,
            moon: moon
          })
          .then((res) => {
            res.collection('waves').add(datas)
            return true
          })
          .catch((err) => {
            console.log(err)
            return false
          })
      } else {
        return collection.doc(existent.docs[0].id).collection('waves').add(datas)
          .then((res) => {
            return true
          })
          .catch((err) => {
            console.log(err)
            return false
          })
      }
    })
}

const removeWaveGoal = function (uid, year, moon, id) {
  const collection = db.collection('users')
    .doc(uid)
    .collection('moon_goal')

  return collection
    .where('year', '==', year)
    .where('moon', '==', moon)
    .get()
    .then((res) => {
      return collection.doc(res.docs[0].id).collection('waves').doc(id).delete().then((deleted) => {
        return true
      })
    })
    .catch((err) => {
      console.log(err)
      return false
    })
}

export {
  saveMoonGoal,
  registerWaveGoal,
  listMoonGoal,
  removeWaveGoal,
  listWaveGoal
}
