import { list, getUserView, setUserView } from '../database/notifications'

export default {
  namespaced: true,
  state: {
    list: {},
    howMuchToSee: 0
  },
  getters: {},
  actions: {
    list (context) {
      const uid = context.rootState.auth.user.uid
      context.commit('list', uid)
    },
    setUserView (context) {
      const uid = context.rootState.auth.user.uid
      context.commit('setUserView', uid)
    }
  },
  mutations: {
    list (state, uid) {
      list().then((docs) => {
        var _list = []
        var now = new Date(Date.now())
        docs.forEach(v => {
          if (v.get('created_at') && v.get('expires_at')) {
            var created = new Date(v.get('created_at').seconds * 1000)
            var expires = new Date(v.get('expires_at').seconds * 1000)
            if (now >= created && now <= expires) {
              _list.push(v.data())
            }
          } else {
            _list.push(v.data())
          }
        })
        state.list = _list
      })
      getUserView(uid).then(v => {
        state.howMuchToSee = v
      })
    },
    setUserView (state, uid) {
      setUserView(uid)
      state.howMuchToSee = 0
    }
  }
}
