<template>
    <div>
      <div class="kin arq">
        <div class="head">Arquétipo Galáctico</div>
        <div class="body" @click="openArqDialog()">
            <h4>{{kin.seal.archetype}}</h4>
            <div>
                <img :src="kin.seal.arqImg" width="250px"/>
            </div>
        </div>
      </div>
    </div>
    <q-dialog v-model="arqDialog">
        <q-layout view="lhh LpR lff" container class="dialog">
            <div class="details text-center">
                <h4 class="text-center">{{kin.seal.archetype}}</h4>
                <img :src="kin.seal.arqImg" height='300'/>
                <p style="white-space: pre-line">{{kin.seal.archetypeDesc}}</p>
            </div>
        </q-layout>
    </q-dialog>
</template>
  
<script>
  export default {
    name: 'ArqComponent',
    props: {
      kin: Object
    },
    data() {
        return {
            arqDialog: false
        }
    },
    methods: {
        openArqDialog() {
            this.arqDialog = true
        }
    }
  }
</script>

<style lang="scss" scoped>
    .body {
        cursor: pointer;
    }
</style>
  
