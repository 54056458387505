<template>
  <div class="details">
    <h4 class="text-center">CONHEÇA A VITAMINA B7/B8 (Colina)</h4>
    <p>
      A colina é uma das vitaminas do complexo B, mais especificamente é a vitamina B8. Ela cumpre diversas funções no organismo, especialmente no cérebro.
      <br/><br/>
      Resumidamente, essa vitamina está diretamente ligada à cognição e à memória (funções cerebrais), bem como ao movimento muscular, à respiração e à frequência dos batimentos cardíacos. Em relação ao cérebro e aos músculos, impulsiona a produção de acetilcolina – neurotransmissor que atua na passagem do impulso nervoso dos neurônios para as células musculares. Ainda, esse neurotransmissor também é necessário para o controle da frequência cardíaca.
    </p>
    <h5>Alimentos fonte de colina</h5>
    <p>Soja, Farelo de trigo, Farelo de aveia, Mostarda, Cereais integrais, Brócolis, Quinoa, Couve-flor, Couve-de-bruxelas.</p>
  </div>
</template>
