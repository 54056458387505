<template>
  <div class="details">
    <h4 class="text-center">Arginina</h4><br/>
    <p>
      1. Aminoácido essencial para saúde do fígado <br/>
      2. Desempenha um papel importante na dilatação dos vasos sanguíneos <br/>
      3. Facilita o fluxo de sangue (Qi) e a oxigenação dos tecidos<br/>
      4. Utilizado para eliminação do excesso de amônia do organismo humano<br/>
      5. Aumenta a função imunológica <br/>
      6. Atua em várias funções do organismo quando solicitado<br/><br/>
      A <span class="bold">arginina</span> retarda o crescimento de tumores e câncer, estimulando o sistema imunológico, aumenta o tamanho e a atividade da glândula timo, que produz as células T, que são componentes cruciais do sistema imunológico. Também ajuda na desintoxicação do fígado ao neutralizar a amônia, bem como reduz os efeitos da toxicidade crônica de álcool. Ela é usada no tratamento da infertilidade em homens; auxilia na perda de peso, pois facilita o aumento da massa muscular e a redução de gordura corporal; auxilia na liberação de hormônios de crescimento, o que é crucial para o reparo de músculos e tecidos; é um componente importante do colágeno, auxiliando no tratamento da artrite e de distúrbios do tecido conjuntivo e, por último, ajuda a estimular o pâncreas a liberar insulina.
    </p>
    <h5>Lista de alimentos ricos em Arginina</h5>
    <ul>
      <li>Abacate, uva, kiwi, melancia e morango (Sugere-se o consumo em saladas de frutas no café da manhã ou frescas no intervalo da manhã ou tarde);</li>
      <li>Amêndoas, castanha de caju, pistache, pinhões, amendoim, avelãs e nozes.</li>
    </ul>
  </div>
</template>
