<template>
  <home-bar />
  <div class="home">
    <div class="row margin-bottom-15">
      <div class="col-12 offset-0 offset-sm-3 col-sm-6 col-md-6 col-lg-6">
        <div class="q-pa kin">
          <div class="q-glutter-md gregorianCalc">
            <label>Data Gregoriana</label>
            <div class="input-group mb-2">
              <input type="number" class="form-control" v-model="date[2]">
              <input type="number" class="form-control" v-model="date[1]">
              <input type="number" class="form-control" v-model="date[0]">
              <q-btn @click="change()" size="sm" icon="fa fa-chevron-right"/>
            </div>
          </div>
          <kin-nav-component :kin="kin"/>
        </div>
      </div>
    </div>
    <div v-if="!hunabKu">
      <div class="row">
        <div class="d-sm-none col-12">
          <sync-1328-component class="" :calendar="calendar"></sync-1328-component>
        </div>
        <div class="order-2 order-sm-1 order-md-1 col-md-4 col-lg-4 col-sm-6">
          <sync-1328-component class="d-none d-sm-block" :calendar="calendar"></sync-1328-component>
          <wave-component :wave="wave" :kin="kin"></wave-component>
          
          <div class="d-lg-none">
            <chromatics-component :chromatics="chromatics"></chromatics-component>
            <chrono-psi-component :cPsi="cpsi"></chrono-psi-component>
          </div>
          <!-- <nutrition-component :kin="kin"/> -->
        </div>
        <div class="order-1 order-sm-2 order-md-2 col-md-4 col-lg-4 col-sm-6 ">
          <mantra-kin-component :kin="kin"></mantra-kin-component>
          <nutrition-component :kin="kin"/>
          <div class="d-lg-none">
            <oracle-component :oracle="oracle" :kin="kin"></oracle-component>
          </div>
        </div>
        <div class="order-3 col-lg-4 col-md-4 d-lg-block">
          <oracle-component :oracle="oracle" :kin="kin"></oracle-component>
          <chromatics-component :chromatics="chromatics"></chromatics-component>
          <chrono-psi-component :cPsi="cpsi"></chrono-psi-component>
        </div>
        <div class="order-4 d-none d-sm-block col-12 col-sm-10 offset-0 offset-sm-1 offset-md-3 col-md-6">
          <harmonic-component :harmonic="harmonic"/>
        </div>
        <div class="d-block d-sm-none order-4">
          <harmonic-component :harmonic="harmonic"/>
        </div>
        <div class="d-block order-5 d-sm-block col-12 col-sm-12 offset-0 offset-sm-0 offset-md-1 col-md-10">
          <tzolkin-component :kin="kin"/>
        </div>
      </div>
      <celestial-harmonic-component v-if="user != null && user.date !== date.join('-')" title="Harmonica com seu Kin" :kin="kin" :userKin="userKin" :harmonic="celestial_harmonic" />
    </div>
    <div v-else>
      <hunab-ku-day />
    </div>
  </div>

</template>

<script>
import OracleComponent from '../../components/kin/OracleComponent'
import WaveComponent from '../../components/kin/WaveComponent'
import ChromaticsComponent from '../../components/kin/ChromaticsComponent'
import MantraKinComponent from '../../components/kin/MantraKinComponent'
import Sync1328Component from '../../components/kin/Sync1328Component'
import ChronoPsiComponent from '../../components/kin/ChronoPsiComponent'
import NutritionComponent from '../../components/kin/NutritionComponent'
import CelestialHarmonicComponent from '../../components/kin/CelestialHarmonicComponent'
import HarmonicComponent from '../../components/kin/HarmonicComponent'
import HunabKuDay from '../../components/HunabKuDay'
import TzolkinComponent from '../../components/kin/TzolkinComponent'
import { ref } from 'vue'
import HomeBar from '../../components/toolbars/HomeBar'
import moment from 'moment'
import KinNavComponent from '../../components/kin/KinNavComponent'

export default {
  computed: {
    date: {
      get () {
        var d = this.$store.state.home.date
        if (typeof d === 'string') {
          return d.split('-')
        }
        return d.join('-')
      },
      set (value) {
        return this.$store.commit('home/changeDate', value)
      }
    },
    hunabKu () {
      return this.$store.state.home.hunabKu
    },
    kin () {
      return this.$store.state.home.kin
    },
    oracle () {
      return this.$store.state.home.oracle
    },
    wave () {
      return this.$store.state.home.wave
    },
    chromatics () {
      return this.$store.state.home.chromatics
    },
    calendar () {
      return this.$store.state.home.calendar
    },
    cpsi () {
      return this.$store.state.home.cpsi
    },
    userKin () {
      return this.$store.state.home.userKin
    },
    user () {
      return this.$store.state.auth.user
    },
    harmonic () {
      return this.$store.state.home.harmonic
    },
    celestial_harmonic () {
      return this.$store.state.home.celestialHarmonic
    }
  },
  methods: {
    change () {
      var d = this.date
      d = moment([d[0], (d[1] - 1), d[2], 15, 25, 50, 125]).format('YYYY-MM-DD')

      if (d !== 'Invalid date') {
        this.$store.commit('home/changeKin', d)
      }
    }
  },
  data () {
    return {
      lifeSecretDialog: ref(false)
    }
  },
  name: 'Home',
  components: {
    OracleComponent,
    WaveComponent,
    ChromaticsComponent,
    MantraKinComponent,
    Sync1328Component,
    ChronoPsiComponent,
    NutritionComponent,
    CelestialHarmonicComponent,
    HarmonicComponent,
    HunabKuDay,
    HomeBar,
    TzolkinComponent,
    KinNavComponent
  }
}
</script>

<style lang="scss">
.home {
  padding: 15px;

  .home-box {
    width: 100%;
    display: flex;
    font-size: 30pt;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: calc(50vh - 15px);
    border: 1px solid var(--dark-medium);
    small {
      font-size: 1.3rem;
    }
    .money {
      color: var(--featured);
    }
    &:nth-child(2), &:nth-child(4) {
      border-left: none;
    }
    &:nth-child(3), &:nth-child(4) {
      border-top: none;
    }
  }
}
</style>
