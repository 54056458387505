<template>
    <div>
      <div class="kin tzolkin">
        <div class="head">Tzolkin</div>
        <div class="body">
            <kin-nav-component :kin="this.kin"/>
            <div class="tzkn-col">
                <div v-for="(x, i) in tzolkin[0]" :key="i">
                    <div class="tzkn-unit">
                        <img :src="x.seal.image" width="35" class="v-align-top" />
                    </div>
                </div>
            </div>
            <div class="tzkn-col" v-for="(column, i) in tzolkin" :key="i">
                <div
                @click="changeKin(c.key)" 
                    :class="
                        (c.key == kin.key)
                            ? 'purple'
                        : (c.isPortal) 
                            ? 'green': c.seal.color
                    " 
                    class="tzkn-unit" v-for="(c, j) in column" :key="j">
                    {{ c.key }}
                    <img :src="c.tone.imageSm" width="33" class="toneimg v-align-top"/>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>


<script>
import KinModel from '../../models/kin_model';
import KinNavComponent from './KinNavComponent';

export default {
    name: 'TzolkinComponent',
    props: {
        kin: Object
    },
    methods: {
        changeKin(k) {
            console.log('change to kin k', k, this.kin.key)
            var kinM = new KinModel()
            var d = this.$store.state.home.date
            var toDate = kinM.calcKin(d, this.kin.key, k)
            this.$store.commit('home/changeKin', toDate)
        }
    },
    data() {
        var kinM = new KinModel()
        var tzolkin = []
        var column = []
        var i = 0
        kinM.list().forEach(kin => {
            column.push(kin)
            if ([
                '1',
                '2',
                '3',
                '4',
                '5',
                '6',
                '7',
                '8',
                '9',
                '10',
                '11',
                '12',
                '13'
                ].includes((kin.key / 20).toString())) {
                tzolkin.push(column);
                column = [];
                }
        });

        return {'tzolkin': tzolkin};
    },
    components: {
        KinNavComponent
    }
}
</script>

<style lang="scss" scoped>

@media screen and (max-width: 530px) {
    .tzkn-unit {
        height: 25px !important;
        font-size: 10px !important;
        img {
            width: 24px !important;
        }
    }
    .tzkn-col {
        width: 25px !important;
    }
}

@media screen and (max-width: 400px) {
    .tzkn-unit {
        height: 22px !important;
        font-size: 10px !important;
        img {
            width: 21px !important;
        }
    }
    .tzkn-col {
        width: 22px !important;
    }
}

@media screen and (max-width: 350px) {
    .tzkn-unit {
        height: 20px !important;
        font-size: 8px !important;
        img {
            width: 19px !important;
        }
    }
    .tzkn-col {
        width: 20px !important;
    }
}

@media screen and (max-width: 300px) {
    .tzkn-unit {
        height: 18px !important;
        font-size: 8px !important;
        img {
            width: 17px !important;
        }
    }
    .tzkn-col {
        width: 18px !important;
    }
}

.tzkn-col {
    width: 35px;
    padding: 0;
    display: inline-grid;
}

.tzkn-unit {
    font-size: 15px;
    height: 35px;
    margin: 1px;
    color: #000;
    cursor: pointer;
}

.v-align-top {
    vertical-align: text-top;
}

.green {
    background-color: #00a740;
    &:hover {
        background-color: #017c31;
    }
}

.purple {
    background-color: #8003ce;
    &:hover {
        background-color: #6502a2;
    }
}

.Vermelho, .Vermelha {
    background-color: #de0505;
    &:hover {
        background-color: #990202;
    }
}

.Amarelo, .Amarela {
    background-color: #dcdc0b;
    &:hover {
        background-color: #afaf01;
    }
}

.Azul {
    background-color: #002aff;
    &:hover {
        background-color: #021fb1;
    }
}

.Branco {
    background-color: #ffffff;
    &:hover {
        background-color: #cacaca;
    }
}

</style>
