export default function OracleModel () {
  this.getSealsOracle = function (seals, seal, tone) {
    var guide

    var ocult = (21 - seal.key)
    var antipode = (seal.key - 10)
    var analog = (19 - seal.key)

    if (antipode < 1) {
      antipode += 20
    }

    if (analog < 1) {
      analog += 20
    }
    if (analog > 20) {
      analog -= 20
    }

    if (ocult < 1) {
      ocult += 20
    }
    if (ocult > 20) {
      ocult -= 18
    }
    if (ocult > 20) {
      ocult -= 18
    }

    var doble = [1, 6, 11]
    var less8 = [2, 7, 12]
    var plus4 = [3, 8, 13]
    var less4 = [4, 9]
    var plus8 = [5, 10]

    if (doble.includes(tone.key)) {
      guide = seal.key
    } else if (less8.includes(tone.key)) {
      guide = (seal.key - 8)
    } else if (less4.includes(tone.key)) {
      guide = (seal.key - 4)
    } else if (plus4.includes(tone.key)) {
      guide = (seal.key + 4)
    } else if (plus8.includes(tone.key)) {
      guide = (seal.key + 8)
    }

    if (guide <= 0) {
      guide = guide + 20
    } else if (guide > 20) {
      guide = guide - 20
    }

    return { destine: seal, guide: seals[(guide - 1)], ocult: seals[ocult - 1], analog: seals[analog - 1], antipode: seals[antipode - 1] }
  }

  this.getKins = function (kin, kins) {
    var ocultTone = 14 - kin.tone.key
    var kinGuide
    var kinOcult
    var kinAnalog
    var kinAntipode

    kins.forEach(e => {
      if (e.seal.key === kin.oracle.guide.key && e.tone.key === kin.tone.key) {
        kinGuide = e
      } else if (e.seal.key === kin.oracle.ocult.key &&
          e.tone.key === ocultTone) {
        kinOcult = e
      } else if (e.seal.key === kin.oracle.analog.key &&
          e.tone.key === kin.tone.key) {
        kinAnalog = e
      } else if (e.seal.key === kin.oracle.antipode.key &&
          e.tone.key === kin.tone.key) {
        kinAntipode = e
      }
    })

    return {
      guide: kinGuide,
      ocult: kinOcult,
      analog: kinAnalog,
      antipode: kinAntipode
    }
  }
}
