import seal1 from '../assets/images/seals/seal1.png'
import seal2 from '../assets/images/seals/seal2.png'
import seal3 from '../assets/images/seals/seal3.png'
import seal4 from '../assets/images/seals/seal4.png'
import seal5 from '../assets/images/seals/seal5.png'
import seal6 from '../assets/images/seals/seal6.png'
import seal7 from '../assets/images/seals/seal7.png'
import seal8 from '../assets/images/seals/seal8.png'
import seal9 from '../assets/images/seals/seal9.png'
import seal10 from '../assets/images/seals/seal10.png'
import seal11 from '../assets/images/seals/seal11.png'
import seal12 from '../assets/images/seals/seal12.png'
import seal13 from '../assets/images/seals/seal13.png'
import seal14 from '../assets/images/seals/seal14.png'
import seal15 from '../assets/images/seals/seal15.png'
import seal16 from '../assets/images/seals/seal16.png'
import seal17 from '../assets/images/seals/seal17.png'
import seal18 from '../assets/images/seals/seal18.png'
import seal19 from '../assets/images/seals/seal19.png'
import seal20 from '../assets/images/seals/seal20.png'

import zayin from '../assets/images/hebrew-letters/zayin.png'
import pei from '../assets/images/hebrew-letters/pei.png'
import guimel from '../assets/images/hebrew-letters/guimel.png'
import vav from '../assets/images/hebrew-letters/vav.png'
import resh from '../assets/images/hebrew-letters/resh.png'
import heth from '../assets/images/hebrew-letters/heth.png'
import lamed from '../assets/images/hebrew-letters/lamed.png'
import yodh from '../assets/images/hebrew-letters/yodh.png'
import teth from '../assets/images/hebrew-letters/teth.png'
import dalet from '../assets/images/hebrew-letters/daleth.png'
import qoph from '../assets/images/hebrew-letters/qoph.png'
import beth from '../assets/images/hebrew-letters/beth.png'
import nun from '../assets/images/hebrew-letters/nun.png'
import samekh from '../assets/images/hebrew-letters/samekh.png'
import ayin from '../assets/images/hebrew-letters/ayin.png'
import mem from '../assets/images/hebrew-letters/mem.png'
import shin from '../assets/images/hebrew-letters/shin.png'
import kaph from '../assets/images/hebrew-letters/kaph.png'
import sadhe from '../assets/images/hebrew-letters/sadhe.png'
import heh from '../assets/images/hebrew-letters/heh.png'

import arq1 from '../assets/images/arq/arq1.png'
import arq2 from '../assets/images/arq/arq2.png'
import arq3 from '../assets/images/arq/arq3.png'
import arq4 from '../assets/images/arq/arq4.png'
import arq5 from '../assets/images/arq/arq5.png'
import arq6 from '../assets/images/arq/arq6.png'
import arq7 from '../assets/images/arq/arq7.png'
import arq8 from '../assets/images/arq/arq8.png'
import arq9 from '../assets/images/arq/arq9.png'
import arq10 from '../assets/images/arq/arq10.png'
import arq11 from '../assets/images/arq/arq11.png'
import arq12 from '../assets/images/arq/arq12.png'
import arq13 from '../assets/images/arq/arq13.png'
import arq14 from '../assets/images/arq/arq14.png'
import arq15 from '../assets/images/arq/arq15.png'
import arq16 from '../assets/images/arq/arq16.png'
import arq17 from '../assets/images/arq/arq17.png'
import arq18 from '../assets/images/arq/arq18.png'
import arq19 from '../assets/images/arq/arq19.png'
import arq20 from '../assets/images/arq/arq20.png'

export default [
  {
    key: 1,
    name: 'Dragão',
    power: 'Nascimento',
    action: 'Nutrir',
    essence: 'Ser',
    cell: 'entrada',
    cla: 'Fogo',
    family: 'Cardeal',
    color: 'Vermelho',
    archetype: 'A Força Primordial',
    archetypeDesc: 'Eu sou a Força Primordial\nCodificada pelo Dragão Vermelho\nMeu número é um\nA unificação do ser primordial\nSou o Ancião dos Dias\nEntro em seu Universo\nAtravés do Primeiro Portão de Luz\nGuardo a memória do ser cósmico\nDentro da fonte primordial\nO local de nascimento e recreação\nDos criadores e mestres estelares\nA Eternidade é o meu diadema\nDou nascimento a todas as formas\nE ao nutrir cada uma delas\nNutro a compaixão por cada ser vivo\nDespertando neles\nUma essência selada no eterno retorno à gênese de minha origem\nConhecer-me é vislumbrar a matriz primordial\nE se você me encontrar\nSerá como um mistério\nRefletido de volta a você\nAtraindo-o por todo o sempre',
    image: seal1,
    arqImg: arq1,
    direction: 'Lestre',
    planet: 'Netuno',
    aminoacid: 'Metionina',
    genCode: 'AUG',
    desc: 'A causa primária, origem do todo, criar, começo, fonte de vida. Poder do nascimento. Energia de mãe, sangue, nutrição. Enérgico, criativo e protetor. Está sempre lidando com uma profusão de sentimentos.\n Receba e expresse os poderes do Nascimento e da Confiança. Focalize a auto nutrição e a nutrição do Universo. Abra seu ser para receber o sustento e o suprimento da vida.',
    hebrew: 'ZAYIN',
    hebrewDesc: 'Shabat a Coroa da Criação\n\nGuematria: Sete\n\nSignificado: 1. Coroa 2. Arma 3. Sustenta\n\nPalavra Espírito:\nHEY YOD HEY',
    hebrewImg: zayin,
    lifeCode: 'Ponto: Tornozelo esquerdo\n\nAminoglifo: Dá o impulso inicial que promove a vida, favorece o processo de ignição da regeneração dos padrões para alcançar a transfiguração crística. Dá sustento à vida superior.\nNutrir o nascimento. Lugar de Luz, onde nasce o Sol. \nGera luz interna.\n(Dragão) Representa unidade, simboliza o início, a criação,  é o número para pensar em si mesmo.',
    vitamin: 'B8',
    mineral: 'Silício'
  },
  {
    key: 2,
    name: 'Vento',
    power: 'Espirito',
    action: 'Comunicar',
    essence: 'Alento',
    cell: 'entrada',
    cla: 'Fogo',
    family: 'Central',
    color: 'Branco',
    archetype: 'A Suma Sacerdotiza',
    archetypeDesc: 'Eu sou a Suma Sacerdotisa\nCodificada pelo Vento Branco\nEntro em seu universo\nAtravés do Segundo Portão de Luz\nMeu número é dois\nO desafio do Vento\nConforme ele viaja por todas as direções\nAproveitando o poder da “palavra”\nDeixe sua língua falar somente o glorioso\nÉ meu desafio para você – pois sou a Senhora dos Ventos\nGuardiã dos túneis mentais abertos através da canção cristal\nDe minha coroa, meu cabelo emerge\nComo a crista do infinito\nSou a portadora do espírito da profecia\nAproveito a energia da respiração preenchendo o corpo com vitalidade\nA joia em minha testa é a sabedoria indestrutível da iluminação\nCada pensamento meu\nÉ enviado por decreto divino\nMeu comando é sempre direcionado\nSomente para a sua essência espiritual\nEscuta-me sempre quando o vento\nSurge do nada',
    image: seal2,
    arqImg: arq2,
    direction: 'Norte',
    planet: 'Netuno',
    aminoacid: 'Fenilalanina',
    genCode: 'UUC UUU',
    desc: 'Vida e alento. Comunica o espírito. Energia cósmica, inspiração, princípio vital, sistema respiratório. Comunicação consigo mesmo e com os demais. Interessado na verdade. Sinceridade e honra. Consciência individual. Ágil, inteligente e multifacetado.\nReceba e expresse o poder do Espírito e da Comunicação. Focalize a presença do Espírito agindo segundo sua inspiração natural. Deixe que o Alento do Espírito o guie para comunicar suas verdades.',
    hebrew: 'PEI',
    hebrewDesc: 'Comunicação \n\nDesenho: Boca com um dente emergindo; Espaço em branco do pei é um bet.\n\nGuematria: Oitenta \n\nSignificado: Boca \n\nPalavra Espírito: \nYOD YOD YOD\nWOD YOD YOD ',
    hebrewImg: pei,
    lifeCode: 'Ponto: Joelho esquerdo\n\nAminoglifo: Abra os portais bioenergéticos. Estimula o rejuvenescimento e a manutenção do arquétipo da eterna juventude e integridade. \nAumenta as habilidades criativas e de comunicação do plano espiritual.\nLugar de sabedoria, onde está a luz que nos guia. \nHora de purificar a luz.\nSimboliza a polaridade, é a expressão de duas forças que produzem Energia, o espírito, o princípio vital.',
    vitamin: 'E',
    mineral: 'Molibdênio'
  },
  {
    key: 3,
    name: 'Noite',
    power: 'Abundancia',
    action: 'Sonhar',
    essence: 'Intuição',
    cell: 'entrada',
    cla: 'Fogo',
    family: 'Sinal',
    color: 'Azul',
    archetype: 'O Sonhador',
    archetypeDesc: 'Eu sou o Sonhador\nCodificado pela Noite Azul\nEntro em seu Universo\nAtravés do Terceiro Portão de Luz\nMeu número é três\nAtivando e vinculando o sonho\nO limite do infinito\nDa noite estrelada é a minha morada\nA intuição é minha guia\nSirvo a todos os sonhadores\nCom a lembrança divina e a inspiração da verdade cósmica\nNão existe quem consiga viver sem mim\nPois não há quem que não sonhe\nMeu sonho é a verdade dentro do sonho\nQuando vou até você, não duvide de mim\nPois trago a memória do Grande Sonho da Terra\nQuando as tribos do tempo sonharam consigo mesmas humanas\nE os humanos sonharam o sonho do livre-arbítrio\nSempre há uma mensagem que eu trago\nApenas para você, que só você saberá decifrar\nEscuta-me e descubra por si só\nO inacabável Sonho do Mundo Arco-íris de esplendor majestoso',
    image: seal3,
    arqImg: arq3,
    direction: 'Oeste',
    planet: 'Saturno',
    aminoacid: 'Alanina',
    genCode: 'GCU GCC GCA GCG',
    desc: 'É o interior, a caverna, a introspecção, o desconhecido, o mistério. Sonha a abundância. Recebe informações e transmite-as. Conservador, demonstra lógica e habilidades organizacionais. Dá muita importância ao lar.\nReceba e expresse o poder do Sonho e da Abundância. Focalize a entrada no santuário do seu Eu - a escuridão interior - o lugar do mistério dentro de você. Una-se com sua quietude e intuição.',
    hebrew: 'GUIMEL',
    hebrewDesc: 'Caridade \n\nDesenho: 1. Homem rico correndo 2. vav e yud. 3. Camelo\n\nGuematria: Três \n\nSignificado: 1. Alimentar 2. Diminuído 3. Camelo 4. Ponte \n\nPalavra Espírito: \nYOD WOD HEY\nWOD WOD HEY\nHEY WOD HEY \nHEY WOD HEY',
    hebrewImg: guimel,
    lifeCode: 'Ponto: Cabeça do fêmur esquerdo.\n\nAminoglifo:  melhora a ligação céu-terra. Dá uma visão mais profunda de nosso mundo interno. Fornece inspiração mística para nossa elevação espiritual.\nFaz-nos perceber a imensidão do Universo.\nLugar de transformação, onde se esconde o Sol. Transforme a luz interior. \nO ritmo, representa a densificação da Luz em matéria.',
    vitamin: 'C',
    mineral: 'Enxofre'
  },
  {
    key: 4,
    name: 'Semente',
    power: 'Florescimento',
    action: 'Focalizar',
    essence: 'Percepção',
    cell: 'entrada',
    cla: 'Fogo',
    family: 'Portal',
    color: 'Amarelo',
    archetype: 'O Inocente',
    archetypeDesc: 'Eu sou o Inocente\nCodificado pela Semente Amarela\nEntro em seu Universo\nAtravés do Quarto Portão de Luz\nMeu número é quatro\nClaro, autoexistente, intrínseco\nA consciência é minha natureza\nTransparente e radiante\nPlanto as sementes da omnisciência da Terra\nSou o florescimento da consciência intrínseca\nEm pura consciência colho os frutos do espírito\nSou a eclosão inimaginável do novo\nImaculado por desejo, invenção ou motivação secreta\nSou o tolo sagrado\nAs pessoas me confundem com um simplório\nMas sou apenas o reflexo da face de todas as suas projeções\nSe for puro e quiser\nCompartilharei meu segredo contigo\nConhecer-me é elevar a força do espírito\nE resistir a tudo que não for do bem',
    image: seal4,
    arqImg: arq4,
    direction: 'Sul',
    planet: 'Júpter',
    aminoacid: 'Treonina',
    genCode: 'ACU ACC ACA ACG',
    desc: 'Potência criadora. Semear. Refere-se ao florescimento, à sabedoria, amadurecimento, ideia, princípio de geração, sexo e reprodução. Poder do crescimento. Interesse cósmico pelos mistérios da criação. Ativo, dinâmico, sexual e influente. Relaciona-se com o mundo através dos cinco sentidos físicos.\nReceba e expresse os poderes da Percepção e do Florescimento. Focalize o poder de suas intenções. Plante as sementes essenciais. Expanda e receba o crescimento natural da semeadura.',
    hebrew: 'VAV/WOD',
    hebrewDesc: 'Conexão \n\nDesenho: Gancho e conduto.\n\nGuematria: Seis \n\nSignificado: 1. Gancho 2. A palavra "e", 3. Na frente de uma palavra, transforma o passado em futuro ou o futuro em passado.\n\nPalavra Espírito: \nYOD WOD HEY\nWOD WOD HEY \nHEY WOD HEY\nHEY WOD HEY',
    hebrewImg: vav,
    lifeCode: 'Letra em Hebraico: \n\nPonto: Pubis\n\nAminoglifo: Ajuda a dissolver os bloqueios que o ego criou. Livre do medo de viver, faz com que o padrão de amor pela vida seja estabelecido. Isso nos permite extrair o melhor de tudo que vivemos. Purifique a alma.\nLugar de vida e crescimento. É hora de expandir a luz. Simboliza a aparência do crescimento da semente, a vegetação que cresce presa à Terra.',
    vitamin: 'B2',
    mineral: 'Selênio'
  },
  {
    key: 5,
    name: 'Serpente',
    power: 'Força Vital',
    action: 'Sobreviver',
    essence: 'Instinto',
    cell: 'armazém',
    cla: 'Sangue',
    family: 'Polar',
    color: 'Vermelha',
    archetype: 'O Iniciado da Serpente',
    archetypeDesc: 'Eu sou o Iniciado Serpente da Sabedoria\nCodificado pela Serpente Vermelha\nCinco é o meu número\nPois a quinta força é meu poder secreto\nEu sou o morador das cavernas ocultas e templos da Terra\nOs mundos perdidos e as raças do passado\nEstão incluídas em meu ser\nMeu corpo é o eremitério que guarda\nO conhecimento do lado oculto das coisas\nSou a Serpente da luz astral\nO poder da Kundalini\nA fonte de luz e a vibração em si\nGeradora do circuito elétrico vermelho\nO mistério da eletricidade\nEstá enrolado em meu ser\nNa minha testa eu visto\nO sinal da serpente que cria\nOs ciclos da vida universal\nA árvore do tempo que gira nos dois sentidos\nÉ o símbolo de minha sabedoria\nPara conhecer-me, deve deixar de ser\nQuem você pensa que é',
    image: seal5,
    arqImg: arq5,
    direction: 'Leste',
    planet: 'Maldek (cint. de asteróides)',
    aminoacid: 'Histidina',
    genCode: 'CAU CAC',
    desc: 'Felicidade, prosperidade, tem bom humor e não se deixa escravizar. Analítico e certeiro. Gera a força. Sistema nervoso. Sexo. Integração e sucesso das funções físicas. Forte consciência de sua individualidade. Recorda e recebe conhecimento.\nReceba e expresse os poderes da Força Vital e do Instinto. Focalize a sabedoria intrínseca do seu corpo. Honre a perfeição de seu templo físico. Expresse plenamente sua paixão, vitalidade e sensualidade.',
    hebrew: 'REISH',
    hebrewDesc: 'O Mal \n\nDesenho: Curvado, carece do yud do dalet.\n\nGuematria: Duzentos \n\nSignificado: 1. Pobre 2. Mal 3. Cabeça\n\nPalavra Espírito: \nYOD HEY WOD\nWOD HEY WOD',
    hebrewImg: resh,
    lifeCode: 'Ponto: Pulso direito\n\nAminoglifo: Permite uma conexão vigorosa com a energia vital. Dá confiança na vida e permite o desdobramento da magnificência espiritual no plano terreno. Reorganizar a energia vital para que a vida flua em uma cadência harmoniosa com o ritmo universal.\nLugar de Luz, onde nasce o Sol. Gere luz interna. Representa o surgimento da autonomia, a individualidade necessária para iniciar o caminho para a unidade.',
    vitamin: 'B9',
    mineral: 'Zinco'
  },
  {
    key: 6,
    name: 'Enlaçador de Mundos',
    power: 'Morte',
    action: 'Igualar',
    essence: 'Oportunidade',
    cell: 'armazém',
    cla: 'Sangue',
    family: 'Cardeal',
    color: 'Branco',
    archetype: 'O Hierofante',
    archetypeDesc: 'Eu sou o Hierofante\nCodificado pelo Enlaçador de Mundos Branco\nSeis é o meu número\nA raiz do Cubo\nSou o grande igualador\nRestaurando o equilíbrio a todos os mundos\nA ordem sagrada de todas as dimensões ilumina meu ser\nPois sou o mestre das artes da imortalidade\nQue todos os buscadores da verdade devem chegar a conhecer\nA morte é o grande reino interdimensional da galáxia\nO armazém de minha sabedoria\nÉ o grande tesouro oculto livre do medo\nHábil na arte de enlaçar mundos\nSou o grande destruidor da ilusão\nDescascando o verniz de convencionalidades\nQue a civilização sobrepõe\nProfundamente abismal é a extensão de meu conhecimento\nEu revelo tudo, mas apenas aqueles que sabem podem ver o que revelo\nO arco-íris é minha coroa\nPerfeita é a ordem que mantenhoConhecer-me é morrer o seu falso eu',
    image: seal6,
    arqImg: arq6,
    direction: 'Norte',
    planet: 'Marte',
    aminoacid: 'Valina',
    genCode: 'GUU GUC GUA GUG',
    desc: 'A porta dimensional na qual se unem dois aspectos da existência: vida e morte. Consciência do aqui e do além, reencarnação, regeneração, iguala a morte, transmutação, ponte entre dois mundos.\nReceba e expresse o poder da Morte e da Rendição ou Entrega. Permita a ocorrência das mortes necessárias para criar renovação e oportunidades. Deixe ir. Perdoe. Renuncie à necessidade do ego de controlar e relaxe na Graça do Plano Maior.',
    hebrew: 'CHET',
    hebrewDesc: 'Casamento \n\nDesenho: Vav e Zayin mais uma ponte interligando.\n\nGuematria: Oito\n\nSignificado: Vida\n\nPalavra Espírito: \nYOD YOD HEY\nWOD YOD HEY\nHEY YOD HEY\nHEY YOD HEY',
    hebrewImg: heth,
    lifeCode: 'Ponto: Cotovelo direito\n\nAminoglifo: Suporta transições de vida. Trabalhe nos ciclos de morte, renascimento de células, portanto, um poderoso regenerador. \nMude os antigos padrões de morte pelos padrões da vida eterna. \nMudar a personalidade humana na transfiguração do Cristo Solar. Ele permite que você caminhe com dignidade e compreensão pelas mudanças da vida.\nLugar de sabedoria, onde está a luz que nos guia. É hora de purificar a luz. Representa os ciclos, equilíbrio e desequilíbrio, o momento de entender que a vida é mais composta de experiências do que de finais.',
    vitamin: 'B12',
    mineral: 'Potássio'
  },
  {
    key: 7,
    name: 'Mão',
    power: 'Realização',
    action: 'Conhecer',
    essence: 'Cura',
    cell: 'armazém',
    cla: 'Sangue',
    family: 'Central',
    color: 'Azul',
    archetype: 'O Avatar',
    archetypeDesc: 'Eu sou o Avatar\nCodificado pela Mão Azul\nPelo poder do sete\nMovimento-me através do seu mundo\nDescendendo das alturas cósmicas dos sete céus\nPossuo as chaves para os sete dias da criação\nSou o realizador, o criador de formas\nSou o construtor dos mundos que virão\nNa arquitetura interdimensional\nEu preparo o caminho do conhecimento para você lembrar\nConhecer, curar e realizar\nEu também aponto essas palavras para você perceber\nAtravés de todas as suas ações\nPois sou o guardião do pacto\nQue vincula todo o conhecimento para a cura da alma do mundo\nO código está em meu ser\nUm enigma envolto em mistério\nDeixando pistas divinas\nCom quem eu encontro\nMinha missão é exemplificar\nO que outros ainda não conhecem',
    image: seal7,
    arqImg: arq7,
    direction: 'Oeste',
    planet: 'Terra',
    aminoacid: 'Asparagina',
    genCode: 'AAU AAC',
    desc: 'Bem-aventurança, nobreza, sensibilidade, trabalho, temor, nervosismo, preocupação, atenção e tensão constantes. Generoso, altruísta. Ação, realizar, concretizar, tornar-se responsável, atuar. Cura física.\nReceba e expresse os poderes da Realização e da Cura. Invoque conscientemente o que deseja que aconteça. Mova-se para o nível seguinte, mais elevado de ser. Entre pelo portal da cura.',
    hebrew: 'LAMED',
    hebrewDesc: 'Professores e Alunos \n\nDesenho: Kaf e Vav\n\nGuematria: Trinta e 26 \n\nSignificado: 1. Aprender 2. Ensinar\n\nPalavra Espírito: \nYOD HEY HEY\nWOD HEY HEY',
    hebrewImg: lamed,
    lifeCode: 'Ponto: Ombro direito\n\nAminoglifo: extrai poderosamente a força de cura. Permite a assimilação dos átomos de luz do corpo de luz e os transfere para o sistema nervoso central com o qual a capacidade sensorial do ser é recuperada e holograma humano. Ativa a troca entre a energia cósmica e a energia individualizada, criando circuitos de movimento espiral constante. Ajuda o sentimento de unidade graças à interconexão, isso é experimentado com o todo.\nLugar de transformação, onde se esconde o Sol. Transforme a luz interior. É a compreensão do finito, na medida que permite conhecer o Universo através de seus ciclos.',
    vitamin: 'B6',
    mineral: 'Sódio'
  },
  {
    key: 8,
    name: 'Estrela',
    power: 'Elegancia',
    action: 'Embelezar',
    essence: 'Arte',
    cell: 'armazém',
    cla: 'Sangue',
    family: 'Sinal',
    color: 'Amarela',
    archetype: 'O Artista',
    archetypeDesc: 'Eu sou o Artista\nCodificado pela Estrela Amarela\nOito é meu número, que abre meu portal\nAs frequências ressonantes são minha paleta\nPara harmonizar de acordo\nCom os impulsos mais profundos do universo\nOuse ser belo!\nSou a elegância da iluminação\nAtravés de minha arte dou cor ao seu mundo\nSou o arco-íris em seu dia\nE o brilho da Lua em sua noite\nSou o artista\nTudo que faço vem das estrelas\nSer estelar, criança estelar, cantor estelar, são meus nomes\nSou o acorde galáctico\nRessonando da chave cósmica\nInvento as canções e recito o juramento do cristal\nQue viaja o infinito Zuvuya\nAs maravilhosas harmônicas em eterna expansão\nConhecer-me é percorrer a canção sem fim\nRetomar ao Mestre Artista de toda Criação',
    image: seal8,
    arqImg: arq8,
    direction: 'Sul',
    planet: 'Vênus',
    aminoacid: 'Isoleucina',
    genCode: 'AUC AUU AUA',
    desc: 'Conscientes de sua meta; a luz do amor, a beleza do plano cósmico. O cálculo perfeito; a harmonia; o artístico. Aprendizado do amor. Transforma o auto amor em profundo amor pela humanidade e pelo planeta Terra. Práticos, férteis, prósperos, lutadores. Compreensão intuitiva da vida superior.\nReceba e expresse os poderes da Beleza e da Elegância. Focalize a co-criação e a oferta da harmonia. Deixe ir o autojulgamento e veja sua vida como uma obra de arte. Aquilo que você vê manifesta-se. Reconheça a beleza e a arte.',
    hebrew: 'YUD',
    hebrewDesc: "E o Décimo Será Sagrado \n\nDesenho: 1. Ponto 2. Chama\n\nGuematria: Dez \n\nSignificado: 1. Judeu 2. Mão (de D'us) 3. Continuidade-Yehudá\n\nPalavra Espírito:\nHEY YOD WOD\nHEY YOD YOD \nHEY YOD HEY",
    hebrewImg: yodh,
    lifeCode: 'Ponto: Entre cervical 7 e dorsal 1\nAminoglifo: A dignidade é vivida como filhos do sol. Reconhecimento da absoluta beleza de Cristo. O manto dourado da impecabilidade de Cristo que é usado com um broche solar. O reconhecimento da capacidade ilimitada de ser.\nLugar de vida e crescimento. É hora de expandir a luz. Representa a oitava musical, a vida que renasce, amor.',
    vitamin: 'Paba',
    mineral: 'Fósforo'
  },
  {
    key: 9,
    name: 'Lua',
    power: 'Água Universal',
    action: 'Purificar',
    essence: 'Fluxo',
    cell: 'processo',
    cla: 'Sangue',
    family: 'Portal',
    color: 'Vermelha',
    archetype: 'A Curandeira',
    archetypeDesc: 'Eu sou a Curandeira\nCodificada pela Lua Vermelha\nMeu número é nove\nO poder do tempo, do destino e dos ciclos da vida\nProvêm das águas viventes\nUno-me ao fluxo universal\nPurificando tudo dentro de minha torrente sagrada\nE elevando a vibração de cada reino da natureza\nSou a harmonia da totalidade\nE a regeneração da vida\nSou fluida e fluo em uníssono com as fases da Lua\nSou a rainha da água que nutre a vida\nSou a chuva e a torrente tal como ela flui\nAlimentando as plantas e florescendo as ervas\nEm minha torrente guardo a afinidade com o todo\nSou coroada pelo esplendor revigorante\nDas folhas, raízes, sementes e flores\nA supremacia da mente divina\nConhecer a mim e a meu poder infinito de cura\nÉ abandonar toda dúvida e seguir o fluxo único da fé',
    image: seal9,
    arqImg: arq9,
    direction: 'Leste',
    planet: 'Mercúrio',
    aminoacid: 'Leucina',
    genCode: 'UUA UUG \nCUU CUC CUA CUG',
    desc: 'Purificação do espírito. Sentimentos. Emoções. Princípio de comunicação e expansão da vida superior. Vive pela razão. Inicia e gera (faz brotar a semente) e também formula e transmite o impulso criativo. Tende a ser dominado por sentimentos fortes.\nReceba e expresse os poderes da Purificação e do Fluxo. Focalize a purificação do seu corpo, mente e percepção. Sustente seu mundo físico ancorando a vibração de sua totalidade.',
    hebrew: 'TET',
    hebrewDesc: 'Isto Também é Para o Melhor \n\nDesenho: 1. Recipiente ( com tampa curva) 2. Inclinar a cabeça em prece.\n\nGuematria: Nove \n\nSignificado: Bom ou Melhor \n\nPalavra Espírito:\nHEY YOD YOD\nHEY YOD YOD\nYOD YOD WOD\nWOD YOD WOD\nHEY YOD WOD\nHEY YOD WOD',
    hebrewImg: teth,
    lifeCode: 'Ponto: Osso atlas à esquerda.\n\nAminoglifo: Dá uma direção construtiva ao movimento da vida da pessoa. Aumenta a resistência aos desafios da vida e aumenta a capacidade de resposta positiva em um nível emocional.\nCrie paradigmas de resposta emocional de acordo com a essência espiritual. Livre-se do medo da morte.\nFaz ajustes para responder com precisão de acordo com o arquétipo divino.\nLugar de Luz, onde nasce o Sol. Gere luz interna. É o número mágico de tempo, representa a periodicidade.',
    vitamin: 'B8',
    mineral: 'Magnésio'
  },
  {
    key: 10,
    name: 'Cachorro',
    power: 'Coração',
    action: 'Amar',
    essence: 'Lealdade',
    cell: 'processo',
    cla: 'Verdade',
    family: 'Polar',
    color: 'Branco',
    archetype: 'O Compassivo',
    archetypeDesc: 'Sou o Compassivo\nCodificado pelo Cachorro Branco\nDez é meu número completo\nSou o consolador eterno – o leal\nO amor é minha lei\nSábio, estou nos caminhos do conhecimento empático\nDe minhas duas mãos de luz\nIrradio boa vontade para todos os seres em todos os reinos\nElevo minha mão direita\nA palma aberta e livre – sem segredos\nNa ausência do medo não há nada a esconder\nInteiramente coração – paciência e bondade\nEsta é a mais profunda sabedoria universal permissiva\nNão existe quem não seja\nUma expressão deste amor que abarca o todo\nPorque o amor é o manifesto\nDo poder da compaixão\nConhecer-me é recordar\nQue o amor é a luz que mantém o sonho da união',
    image: seal10,
    arqImg: arq10,
    direction: 'Norte',
    planet: 'Mercúrio',
    aminoacid: 'Serina',
    genCode: 'AGU AGC\nUCU UCC UCA UCG',
    desc: 'Lealdade, nobreza, fidelidade, amor incondicional, integridade, defesa, proteção, bravura, valentia. Faz-se valer, impõe-se. Trabalha bem em equipe e é bom líder. Necessita de variedade na vida. Aprimora as informações do espírito e torna-as acessíveis a outras pessoas.\nReceba e expresse os poderes do Amor e da Lealdade. Focalize a transcendência de padrões emocionais limitadores e a ativação de sua força espiritual nos relacionamentos. Sintonize-se com o Coração Único. Seja leal à sua essência e ao seu caminho.',
    hebrew: 'DALET',
    hebrewDesc: 'A festa de Pessach \n\nDesenho: 1. Perna se estende até o guimel 2. Batente e coluna 3. Yud e reish \n\nGuematria: Quatro \n\nSignificado: 1. Pobre 2. Porta 3. Levanta \n\nPalavra Espírito: \nYOD HEY HEY \nWOD HEY HEY \nYOD WOD YOD\nWOD WOD YOD \nHEY WOD YOD\nHEY WOD YOD',
    hebrewImg: dalet,
    lifeCode: 'Ponto: Esfenóide esquerdo\n\nAminoglifo: Eleva nossa capacidade de dar e receber amor. Gera a estrutura bioquímica para sustentar a alta frequência do amor verdadeiro. Oferece a virtude de aceitar a vida como um presente glorioso.\nFaz uma associação direta entre o amor e a alegria de viver em uma simbiose perfeita. \nMantém a frequência do Amor crístico em todas as células do corpo para sua perfeita ancoragem.\nNos ajuda a caminhar pela vida com amor e aceitar os outros como eles são.\nLugar de sabedoria, onde está a luz que nos guia. \nÉ hora de purificar a luz. Simboliza a aparência do animais com vida emocional, lealdade, fidelidade.',
    vitamin: 'B4',
    mineral: 'Cobalto'
  },
  {
    key: 11,
    name: 'Macaco',
    power: 'Magia',
    action: 'Brincar',
    essence: 'Ilusão',
    cell: 'processo',
    cla: 'Verdade',
    family: 'Cardeal',
    color: 'Azul',
    archetype: 'O Ilusionista',
    archetypeDesc: 'Sou o Ilusionista\nCodificado pelo Macaco Azul\nMeu número é 11, o código mestre\nElevo-me fora do nada\nCom a percepção autoliberada\nPor meio do duplo onze\nProjeto múltiplas realidades\nPela causa da transcendência universal\nComo gerador do circuito elétrico azul\nSou o alquimista azul\nA ilusão da minha própria existência\nProjetada pelo meu cristal de dupla terminação\nSou o exemplo do enigma da realidade, nada vem nem vai\nNada termina, nem começa\nPara aqueles que pensam que sou um enigma\nEu sou real\nPara aqueles que pensam que sou real\nSou um enigma\nOnde quer que a ilusão persista\nLá estou eu para dissipá-la e dissolvê-la\nPorque guardo as mesas e números mágicos\nQue enviam você em direção ao jogo mental cósmico',
    image: seal11,
    arqImg: arq11,
    direction: 'Oeste',
    planet: 'Vênus',
    aminoacid: 'Triptofano',
    genCode: 'UGG',
    desc: 'Cientista, matemático, artesão. Habilidade, conhecimento, truques, magia, brincadeira, movimento, artes em geral e jogos. Atrai a atenção. Formula informações e as transmite. Abundância geral de energia. Estabelece a ponte de passagem entre as etapas evolutivas. Brinca com a magia porque possui o conhecimento. \nReceba e expresse os poderes da Magia e da Brincadeira. Focalize a celebração de sua criança interior divina por meio da espontaneidade, inocência e brincadeira. Ria de si mesmo e dissolva toda seriedade. Anime-se! Aplique o humor e torne-se invencível!',
    hebrew: 'KUF',
    hebrewDesc: 'O Macaco\n\nDesenho: Um hei com pé esquerdo por baixo da linha da moralidade.\n\nGuematria: Cem\n\nSignificado: Macaco',
    hebrewImg: qoph,
    lifeCode: 'Ponto: Esfenóide direito\n\nAminoglifo: levante o véu de Maya ou ilusão. O estado de êxtase como um atributo primordial para a transfiguração crística. Estimula a presença do observador equânime que sempre age a partir do centro espiritual nas diferentes circunstâncias da vida. Promove o relaxamento, flexibilidade e conhecimento de vida.\nLugar de transformação, onde se esconde o Sol. Transforme a luz interior. Simboliza aleatoriamente a instabilidade que permite a mudança, o início de uma vida superior inteligente, o artista.',
    vitamin: 'B5',
    mineral: 'Níquel'
  },
  {
    key: 12,
    name: 'Humano',
    power: 'Livre-arbítrio',
    action: 'Influenciar',
    essence: 'Sabedoria',
    cell: 'processo',
    cla: 'Verdade',
    family: 'Central',
    color: 'Amarelo',
    archetype: 'O Sábio',
    archetypeDesc: 'Sou o Sábio\nCodificado pelo Humano Amarelo\nDoze é meu número\nA raíz perfeita do místico 144\nA perfeição do templo humano\nBenevolente, bondoso e surpreendente\nSou o sábio, o juíz e o discriminador\nControlo os sete centros\nE aperfeiçoo as leis da forças internas e externas\nO controle da mente superior é o poder da minha influência\nEm minha mão direita, seguro o cristal de dupla terminação\nEquilibrando a forma com habilidade\nEm minha mão esquerda, seguro a bola de cristal\nDa sabedoria universalmente imparcial e da visão clara\nMeu caminho é a conduta espontânea\nLiberta da mentira\nSou o esboço do humano-solar purificado\nFalo com a voz da Noosfera\nAnunciando o retorno de todas as coisas boas\nConhecer-me é conhecer a ti mesmo',
    image: seal12,
    arqImg: arq12,
    direction: 'Sul',
    planet: 'Terra',
    aminoacid: 'Glicina',
    genCode: 'GGU GGC GGA GGG',
    desc: 'Recipiente da mente superior, antenas de sintonia. Livre vontade, limitações humanas, insegurança, receio. Constitui a consciência global.\nReceba e expresse os poderes do Livre-Arbítrio e da Influência. Decida ser abastecido com sabedoria superior, expansiva. Desperte as habilidades de sua forma humana guiando seu livre-arbítrio com auto-amor. Usufrua sua humanidade, ofereça sabedoria.',
    hebrew: 'BET',
    hebrewDesc: 'Criação \n\nDesenho: Uma linha vertical e duas horizontais, representando leste, sul e oeste.\n\nGuematria: 2 e 412\n\nSignificado: Casa\n\nPalavra Espírito: \nYOD HEY HEY\nWOD HEY HEY \nHEY HEY HEY \nHEY HEY HEY',
    hebrewImg: beth,
    lifeCode: 'Ponto: Osso atlas à direita.\n\nAminoglifo: Dá uma dimensão de profundidade eterna à existência. Aumenta a compreensão de vida aos níveis abrangentes da criação. Estabelece os paradigmas comportamentais da nova humanidade no sentido ético, estético, moral e religioso. Ele universaliza o entendimento e unifica o sentimento.\nA configuração biológica, a expressão sublime de Deus.\nLugar de vida e crescimento. É hora de expandir a luz. Representa a aparência do cérebro humano, a estabilidade complexa.',
    vitamin: 'K',
    mineral: 'Cálcio'
  },
  {
    key: 13,
    name: 'Caminhante do Céu',
    power: 'Espaço',
    action: 'Explorar',
    essence: 'Vigilancia',
    cell: 'saída',
    cla: 'Verdade',
    family: 'Sinal',
    color: 'Vermelho',
    archetype: 'O Profeta',
    archetypeDesc: 'Eu sou o Profeta\nCodificado pelo Caminhante do Céu Vermelho\nTreze é meu número\nA onda encantada da criação\nMuitas épocas e muitos mundos conheci\nPorque sou o agente do tempo universal\nExplorando o espaço em permanente vigília\nSou o colonizador de mundos perdidos\nO navegador galáctico do tempo\nO olho que tudo vê da quinta força\nConsagra minha testa\nEm minha mão direita tenho o livro da Lei do Tempo\nUma dádiva da família estelar\nEm minha mão esquerda tenho a bússola galáctica, o giro de Sirius\nO resumo do caminho profético\nA profecia é a lei natural, uma função do tempo\nMinha veste exibe os dois números sagrados 13 e 20\nCruzando as barreiras do tempo e do espaço\nSou coroado pelo símbolo dos ciclos infinitos do tempo\nConhecer-me é conhecer a profecia do tempo\nNa promessa do novo amanhecer',
    image: seal13,
    arqImg: arq13,
    direction: 'Leste',
    planet: 'Marte',
    aminoacid: 'Glutamina',
    genCode: 'CAA CAG',
    desc: 'Emociona-se facilmente. Pilar entre céu e terra. Pacificador. Lutador por princípio. Explora o espaço. Buscador da verdade. Amor à aventuras, ilusões, mentiras. Assume desafios, mas é sempre desafiado a domar sua natureza obstinada.\nReceba e expresse os poderes da Exploração e da Vigilância. Compartilhe mensagens sagradas para unir o Céu e a Terra. Enfrente desafios e cresça. Participe ativamente da criação do novo futuro.',
    hebrew: 'NUN',
    hebrewDesc: "Mashiach\n\nDesenho: 1. NUN inclinado- serve a D’us por temor ( reverência), Nun reto - serve a D’us por amor. 2- Nun inclinado- aquele que caiu; Nun reto- aquele que se elevou l. 3. Aquele que está inclinado neste mundo estará ereto no Mundo Vindouro.\n\nGuematria: Cinquenta \n\nSignificado: 1. Engano 2. Reinado 3. Peixe 4. Aborto 5. Milagre \n\nPalavra Espírito: \nHEY HEY WOD\nHEY HEY WOD",
    hebrewImg: nun,
    lifeCode: 'Ponto: Entre o esterno e a clavícula\n\nAminoglifo: Expande a capacidade do cérebro em diferentes dimensões. Treine o cérebro humano entrar em contato telepático com inteligências estelares, galácticas e cósmicas.\nHabilitar habilidade para compreender a sabedoria universal. Permite viajar em estados de consciência desperta pelo cosmos. Ative a segregação de substâncias que favorecem a atividade neural e o despertar das qualidades cerebrais até agora desconhecidas para a humanidade, facilitando o advento do Cristo vivo em nós (theos homme, homem deus).\nLugar de Luz, onde nasce o Sol. Gere luz interna. Representa o controle dos instintos, o princípio do eu superior, o caminhante dos céus.',
    vitamin: 'Colina',
    mineral: 'Lítio'
  },
  {
    key: 14,
    name: 'Mago',
    power: 'Atemporalidade',
    action: 'Encantar',
    essence: 'Receptividade',
    cell: 'saída',
    cla: 'Verdade',
    family: 'Portal',
    color: 'Branco',
    archetype: 'O Mago',
    archetypeDesc: 'Eu sou o Mago\nCodificado pelo Mago Branco\nO dobro de sete é meu número\nO símbolo de minha absorção supermental\nNas forças da criação cósmica\nDentro de minha esfera de cristal\nEstá tudo o que pode ser visto ou conhecido\nAtravés de minha suprema receptividade\nDeixo para você os códigos que definem a ressonância\nQue você chama de tempo\nSuspenda todo o pensamento e entre comigo\nNo infinito encantamento de meu oráculo, o Oráculo do Mago\nPelo qual as dimensões estão todas juntas\nMinha mente é o universo\nMeu corpo é o mundo que você vê\nMinha fala é o som melodioso do mundo natural\nMeu terceiro olho é a triangulação de corpo, fala e mente\nSou o doador dos nomes mágicos\nGuardo o segredo do acorde perdido – a música das esferas\nAs vibrações mais elevadas da lei cósmica\nConhecer-me é conhecer as árvores\nSou o movimento e a medida da ordem natural – Hunab’Ku',
    image: seal14,
    arqImg: arq14,
    direction: 'Norte',
    planet: 'Maldek (Cint. de asteróides)',
    aminoacid: 'Lisina',
    genCode: 'AAA AAG',
    desc: 'O momento presente, o aqui e agora. Companheiro da águia que protege os guerreiros. Sigiloso, lutador, muita força potencial, o vidente noturno, adivinhador e visionário. Energia construtiva. Poder de curar a si mesmo e aos outros.\nReceba e expresse os poderes da Intemporalidade e do Encantamen- to. Torne-se um mago transparente que permite que a magia seja criada através de si. Use o coração para receber a Harmonização Divina. Seja receptivo ao tempo radial, não linear e não-centralizado.',
    hebrew: 'SAMECH',
    hebrewDesc: 'Apoiar e Curar \n\nDesenho: 1. Círculo Fechado 2. Aliança de Casamento \n\nGuematria: Sessenta \n\nSignificado: 1. Apoio 2. Remédio \n\nPalavra Espírito:\nHEY HEY HEY\nHEY HEY HEY',
    hebrewImg: samekh,
    lifeCode: 'Ponto: Ombro esquerdo\n\nAminoglifo: Contribui para governar desde a maestria, aos elementos da natureza. Desenvolva o comunicação intuitiva com os elementos: fogo, água, terra, ar, quartzo, pedras, plantas e animais.\nLugar de sabedoria, onde está a luz que nos guia. \nÉ hora de purificar a luz.\nSimboliza a aparência do vidente, do feiticeiro, aquele com poderes mágicos, o mais alto desenvolvimento da consciência individual.',
    vitamin: 'B15',
    mineral: 'Magnésio'
  },
  {
    key: 15,
    name: 'Águia',
    power: 'Visão',
    action: 'Criar',
    essence: 'Mente',
    cell: 'saída',
    cla: 'Céu',
    family: 'Polar',
    color: 'Azul',
    archetype: 'O Vidente',
    archetypeDesc: 'Eu sou o Vidente\nCodificado pela Águia Azul\nQuinze é meu número – três portais de cinco\nSão abertos pela minha mente\nMeu lar é o céu sem fim\nPorque sou o nascido do céu\nA galáxia pulsa\nCom meus pensamentos luminosos\nPelo poder da visão deslizo facilmente pelas dimensões\nE profetizo a vinda das épocas de mudança\nVoo através dos universos paralelos\nNa trilha única do Zuvuya\nA visão é a fonte de minha inteligência\nQue mantém o todo planetário\nTodos aqueles que procuram conhecer a mente e criar\nDe acordo com o plano universal, virão a mim e verão\nDentro de minha mente está o poder da mente universal\nEm sua criatividade infinita\nConhecer-me é conhecer o plano astral do infinito\nQue abarca o todo do qual a mente superior se origina',
    image: seal15,
    arqImg: arq15,
    direction: 'Oeste',
    planet: 'Júpter',
    aminoacid: 'Arginina',
    genCode: 'AGA AGG\nCGU CGC CGA CGG',
    desc: 'Visão ampla, valente, ousado, fanfarrão, soberbo, independente e cuidadoso. Mente superior coletiva, mente planetária e consciência. A visão da essência das coisas. Expressa as informações que recebe. Natureza crítica e exigente.\nReceba e expresse os poderes da Visão e da Mente. Veja, de cima, o plano maior, como uma águia de olhos aguçados e visão clara. Use o poder da mente para criar, inspirado pelo compromisso com sua visão. Acredite em sua conexão com a Mente Planetária.',
    hebrew: 'AYIN',
    hebrewDesc: 'Liderança \n\nDesenho: 1. Um Vav e um Nun 2. Dois olhos conectados ao nervo óptico.\n\nGuematria: Setenta \n\nSignificado: 1. Olhos 2. Salvação \n\nPalavra Espírito:\nHEY HEY HEY\nHEY HEY HEY \nYOD HEY WOD\nWOD HEY WOD\nHEY HEY WOD\nHEY HEY WOD',
    hebrewImg: ayin,
    lifeCode: 'Ponto: Cotovelo esquerdo\n\nAminoglifo: Ativa a capacidade de ver as coisas com equanimidade. Oferece um estado de consciência ampliada que é capaz de sintetizar um grande número de variáveis, pontos de vista, etc.\nÉ o ser universal de compreensões abrangentes.\nA diversidade é assimilada em uma unidade perfeita. Isso nos dá confiança na vida e respeito pelo poder superior. Ative as qualidades de serenidade, honestidade, veracidade e autoconfiança.\nLugar de transformação, onde se esconde o Sol. Transforme a luz interior. Simboliza a conexão das mentes, a consciência coletiva do Planeta.',
    vitamin: 'A',
    mineral: 'Iodo'
  },
  {
    key: 16,
    name: 'Guerreiro',
    power: 'Inteligencia',
    action: 'Questionar',
    essence: 'Intrepidez',
    cell: 'saída',
    cla: 'Céu',
    family: 'Cardeal',
    color: 'Amarelo',
    archetype: 'O Descobridor',
    archetypeDesc: 'Eu sou o Descobridor\nCodificado pelo Guerreiro Amarelo\nMeu número é o dobro de oito\nA ressonância harmônica superior do dezesseis\nSou o explorador da matriz radial\nPelo poder da inteligência\nAbro o caminho através da ignorância do mundo ilusório\nSeguindo os sinais deixados pelo Transformador de Mundos\nVejo caminhos que os outros ainda não viram\nMeu espírito destemido remove os obstáculos ao longo do caminho\nPara que os outros possam seguir caminhos cada vez mais elevados\nTrajando a insígnia do Quinto Sol\nTrilho os passos do Profeta Antigo\nPara o templo que abriga a casa da noite cósmica\nSou o pioneiro do futuro\nTodos os caminhos me levam a abrir e investigar em nome da ciência cósmica\nAonde quer que o caminho possa levar\nHaverá sempre mais um tesouro do conhecimento para aprender\nConhecer-me é banir o medo\nE ver sua verdadeira face brilhante e límpida',
    image: seal16,
    arqImg: arq16,
    direction: 'Sul',
    planet: 'Saturno',
    aminoacid: 'Ácido Glutâmico',
    genCode: 'GAA GAG',
    desc: 'Fogo, longa vida, prosperidade, alegria, vida metódica, planificada, disciplinada, maturidade. Guerreiro pelo Espírito, age à partir do coração universal. Harmoniza as massas através da inteligência. Força cósmica.\nReceba e expresse os poderes do Destemor e da Inteligência. Focalize sua voz interior ao trilhar o caminho do “Ponto de Interrogação Cósmico”. Avance em direção a seus medos. Seja o guerreiro da graça.',
    hebrew: 'MEM',
    hebrewDesc: 'Metamorfose \n\nDesenho: 1. Mem aberto- Torá revelada; Mem fechado- Torá oculta; 2. Mem fechado- período de gestação; Men aberto- parto.\n\nGuematria: Quarenta \n\nSignificado: 1. Água 2. Mashiach\n\nPalavra Espírito:\nHEY HEY HEY \nHEY HEY HEY',
    hebrewImg: mem,
    lifeCode: 'Ponto: Pulso esquerdo\n\nAminoglifo: Ativa a inteligência criativa e intuitiva. Fortalece a elevação espiritual ao transformar energia básica e instintiva em substância espiritual e gloriosa. Amplia as habilidades de compreensão no físico / alma / espírito.\nEle permite incorporar o corpo de luz radiante na estrutura biológica humana.\nOferece clareza de consciência para mantê-lo equilibrado, centrado, iluminado.\nLugar de vida e crescimento. É hora de expandir a luz.\nRepresenta a conexão e o contato com a mente da Galáxia.',
    vitamin: 'B1',
    mineral: 'Cobre'
  },
  {
    key: 17,
    name: 'Terra',
    power: 'Navegação',
    action: 'Evoluir',
    essence: 'Sincronicidade',
    cell: 'matriz',
    cla: 'Céu',
    family: 'Central',
    color: 'Vermelha',
    archetype: 'O Navegador',
    archetypeDesc: 'Eu sou o Navegador\nCodificado pela Terra Vermelha\nDezessete é o meu número\nMinhas insígnias são os selos harmônicos\nDa mente planetária e da mente estelar retornando à fonte\nSou a invisibilidade do tempo e da consciência\nGuardo os mapas da origem celestial\nE sigo os sinais da sincronicidade\nEm nome da evolução cósmica\nMinha coroa é o portal interdimensional\nDo todo da vida galáctica\nO código 13 e 7 ficam acima e abaixo\nNo centro e nos lados está o 441\nO 11 e o 27, chaves para o poder sincrônico do sete\nAs lentes sincrônicas permitem-me ver todas as coisas radialmente\nSou a evolução em movimento\nSou o habilidoso viajante estelar\nGuiado pelas frequências telepáticas que mantêm\nAs estrelas e sistemas planetários em ordem\nA bússola galáctica é a minha roda do leme\nChaves para o conhecimento da navegação galáctica\nCujos sinais são as direções\nPara que todo viajante conheça\nConhecer-me é conhecer a linguagem das estrelas',
    image: seal17,
    arqImg: arq17,
    direction: 'Leste',
    planet: 'Urano',
    aminoacid: 'Prolina',
    genCode: 'CCU CCC CCA CCG',
    desc: 'Gera ideias e se autogoverna. Mente ativa, muita energia, sempre em movimento. Enfrenta tudo. Coração da galáxia; evolui a navegação, desenvolve. Alinhamento das forças planetárias. Liberal, progressista, controvertido.\nReceba e expresse os poderes da Navegação e da Evolução. Sincronize-se com seu caminho mais elevado. Navegue na realidade tempo/espaço para abrir portais de evolução. Ofereça profundo amor à Mãe Terra e receba o mesmo dela.',
    hebrew: 'SHIN',
    hebrewDesc: 'O Matriarcas \n\nDesenho: 3 ou 4 linhas verticais; 4 tipos de Shin 2. Sin 3. silencioso 4. quatro linhas sobre o tefilin \n\nGuematria: Trezentos \n\nSignificado: 1. Dente 2. Constante 3. Mudança 4. Retorno 5. Ano \n\nPalavra Espírito:\nYOD WOD WOD WOD WOD WOD \nHEY WOD WOD \nHEY WOD WOD',
    hebrewImg: shin,
    lifeCode: 'Ponto: Sacro\n\nAminoglifo: Oferece ganhos da existência humana sobre as condições de vida na terra. Aumenta a capacidade orgânica em todo o seu potencial, e eleva a vitalidade aos níveis mais elevados do desenho humano original. Sabe recuperar o poder sobre a vida e a capacidade de exercer sabedoria no ato de viver. \nAmor pela própria vida e dignidade ao viver. Favorece a adaptabilidade a diferentes climas, altitudes, fusos horários, costumes e tradições.\nLugar de Luz, onde nasce o Sol. Gere luz interna. Simboliza a sincronização da mente do homem com inteligência do planeta.',
    vitamin: 'B3',
    mineral: 'Ferro'
  },
  {
    key: 18,
    name: 'Espelho',
    power: 'Infinito',
    action: 'Refletir',
    essence: 'Ordem',
    cell: 'matriz',
    cla: 'Céu',
    family: 'Sinal',
    color: 'Branco',
    archetype: 'Yogue/Yoguine',
    archetypeDesc: 'Eu sou o Yogue/Yoguine\nCodificado pelo Espelho Branco\nDezoito é meu número\nVivo em estado de meditação eterna\nAlém da palavra, do pensamento e da ação\nSentado em minha caverna interior\nSou o reflexo do sonho infinito\nGuardo a chave dos universo do espelho\nDo universo cósmico que espelha o todo\nSou a meditação do êxtase do vazio\nA parede de minha caverna é minha vista do universo\nSupremo em meus poderes de iogue\nSou o exemplo da autotranscendência para a Noosfera\nPorque sou o precursor da evolução\nEnvolto pela aura do arco-íris\nProduto de minha autotransmutação interior\nManifesto sinais de poderes paranormais\nSou o único que pode tornar claro o caminho para todos os seres\nUm orbe à minha direita incandesce com sua própria luz\nO resultado da luminosidade pré-existente\nAcima da minha cabeça aparece a mandala da autoperfeição primordial\nUm sinal de que todos podemos atingir o caminho da luz infinita\nConhecer-me é saber\nQue a prática solitária traz conhecimento e sabedoria',
    image: seal18,
    arqImg: arq18,
    direction: 'Norte',
    planet: 'Netuno',
    aminoacid: 'Ácido Aspártico',
    genCode: 'GAU GAC',
    desc: 'Atua com o coração universal. Reflete a perfeição divina. O auto sacrifício do ego, que produz luz e consciência. Valor, renúncia, luta interna, caminho direito, enfrenta a vida com estratégia, combativo, temperança. Muito sociável.\nReceba e expresse os poderes do Reflexo e do Infinito. Procure distinguir a verdade da ilusão. Enfrente sua sombra e inconsciência. Libere tudo que não o reflita autenticamente. Reflita a luz do guerreiro espiritual. Aprenda com o espelho de pessoas e situações.',
    hebrew: 'CAF',
    hebrewDesc: 'Isto Também é para o Melhor \n\nDesenho: 1. Cano curvo 2. Submissão a D’us 3. O Rei dos Reis \n\nGuematria: Vinte \n\nSignificado: Coroa \n\nPalavra Espírito:\nYOD HEY HEY \nWOD HEY HEY',
    hebrewImg: kaph,
    lifeCode: 'Ponto: Cabeça do fêmur direito\n\nAminoglifo: Sincronização com a pulsação divina. Combine a vibração humana com a vibração cósmica. Permite unificar parâmetros da existência humano-divina. Promove a identificação de Cristo em nós e nos outros.\nLugar de sabedoria, onde está a luz que nos guia. É hora de purificar a luz. Simboliza a purificação, o corredor espelhos, a transformação interna ao tomar consciência.',
    vitamin: 'B7',
    mineral: 'Fluór'
  },
  {
    key: 19,
    name: 'Tormenta',
    power: 'Autogeração',
    action: 'Catalizar',
    essence: 'Energia',
    cell: 'matriz',
    cla: 'Céu',
    family: 'Portal',
    color: 'Azul',
    archetype: 'O Transformador de Mundos',
    archetypeDesc: 'Eu sou o Transformador de Mundos\nCodificado pela Tormenta Azul\nMeu número é dezenove\nO poder de todos os números\nSou o mestre alquimista\nA força por trás da pedra filosofal\nHabilidoso nas artes da transmutação\nSou o transformador catalítico\nDa consciência do mundo e da vida planetária\nEstou atento às mudanças do tempo\nSou o trovão que abala seu sistema de mundo\nSou o arrebatamento das nuvens carregadas de raios\nPara iluminar a verdade por trás de suas ilusões\nSou aquele que traz a chuva que purifica\nDei origem aos monumentos de triplo aspecto do arquétipo do Avatar\nQue aparecem em torno dos templos antigos\nEstou atento às estrelas da Manhã e do Anoitecer\nContemplo o criador do Quinto Sol, o mundo atual\nSou enviado para morar entre vocês,\nConvocando o Sexto Sol\nPara a Grande Regeneração da alma do mundo\nConhecer-me é conhecer o seu próprio poder de autogeração',
    image: seal19,
    arqImg: arq19,
    direction: 'Oeste',
    planet: 'Plutão',
    aminoacid: 'Tirosína',
    genCode: 'UAU UAC',
    desc: 'Alegre, contagiante, amigável e prestativo. Sorte incerta. Mudanças. Humor variável. Regeneração. Catalisa (acelera ou diminui) a autogeração. Transformação que precede a realização. Preocupa-se com a cura e a purificação. Receba e expresse os poderes da Autogeração e da Energia. Desperte o ser-trovão interior. Catalise a transformação. Libere a energia. Seja receptivo à iniciação e purificação.',
    hebrew: 'TSADIK',
    hebrewDesc: 'O Baal Teshuvá\n\nDesenho: Yud e nun curvado \n\nGuematria: Noventa \n\nSignificado: Justo \n\nPalavra Espírito:\nYOD HEY YOD\nWOD HEY YOD',
    hebrewImg: sadhe,
    lifeCode:
      // 'Alimentos: Algas marinhas, sementes de abóbora, sementes de gergelim, feijão, banana, pêssego, amêndoas.\n\n' +
      // 'Ponto de acupuntura: Ponto 35 (Ponto do Estômago).\n\n' +
      'Ponto: Joelho direito\n\n' +
      // 'Indicação: Síndrome joelhos frios e problemas no joelho.\n\n' +
      // 'Joelho: Presente.\n\n' +
      // 'Joelho na visão sistêmica: Humildade. Dificuldade de se curvar diante do Divino.\n\n' +
      // 'Mantra Vibracional: Tzad\n\n' +
      // 'Frase: Somente os humildes verão a Deus.\n\n' +
      // 'Vitamina: B13- Ácido Orótico\n\n' +
      // 'Alimentos com vitamina B13: Tubérculos\n\n' +
      // 'Função: Recuperação da memória Espacial.\n\n' +
      // 'Mineral Vibracional: Magnésio \n\n' +
      // 'Planta Medicinal: Espinheiro Branco \n\n' +
      // 'Aromaterapia: Aloe Vera \n\n' +
      // 'Quartzo: Obsidiana\n\n' +
      'Aminoglifo: confere uma poderosa força interior para superar os desafios da vida. Intensifica a determinação para seguir em frente com coragem e vontade. \nLiberta psique humana do vício, sofrimento, doença e morte. Abre um poderoso vórtice de luz para que a existência humana seja elevada aos planos divinos. Restabelece a condição de submissão à Vontade superior e retorna a Deus (Pai de todas as coisas) sua autoridade original. Ele dissolve a lacuna ou separação entre o homem e Deus, a vontade particular à vontade divina. Renda-se a Deus.\nLugar de transformação, onde se esconde o Sol. Transforme a luz interior. Representa a tempestade, a mudança.',
    vitamin: 'B13',
    mineral: 'Magnésio'
  },
  {
    key: 20,
    name: 'Sol',
    power: 'Fogo Universal',
    action: 'Iluminar',
    essence: 'Vida',
    cell: 'matriz',
    cla: 'Fogo',
    family: 'Polar',
    color: 'Amarelo',
    archetype: 'O Iluminado',
    archetypeDesc: 'Eu sou o Iluminado\nCodificado pelo Sol Amarelo\nMeu número é vinte\nA totalidade do universo\nEm meditação fui concebido\nDa meditação nasci e por muitas eras tenho habitado seu abismo\nSou a verdade superior e a renovação da vida\nGiro a roda cósmica da lei\nDando ensinamentos da mente imaculada entre as estrelas\nSou o guardião harmônico dos campos resplandecentes da luz infinita\nSou chamado por muitos nomes\nMas só dão nome à minha forma exterior\nMeu manto ígneo que você chama de Sol\nA iluminação de um é a iluminação de todos\nSou o cumprimento da profecia de Hunab’Ku\nDespertar todos os seres simultaneamente é a minha tarefa\nTenho despertado em muitos lugares\nE todo lugar para onde vou conheço somente pelo nome Tulan\nTulan dos despertos\nConhecer-me é conhecer a luz da verdade\nQue ilumina todas as coisas',
    image: seal20,
    arqImg: arq20,
    direction: 'Sul',
    planet: 'Plutão',
    aminoacid: 'Cisteína',
    genCode: 'UGU UGC',
    desc: 'Consciência, auto regulador, amoroso, dedicado, artístico, sonhador, romântico. Bem intencionado, nobre e digno. Amor à vida. Mente solar, mestria, conhecimento, vitalidade, desenvoltura, habilidade de enfocar o todo galáctico.\nReceba e expresse os poderes da Ascensão e da Iluminação. Deixe que os raios de seu sol interior iluminem e tragam poder a sua vida. Aqueça-se com o poder de sua liberdade e de sua totalidade. Ascenda a seu centro cardíaco e acesse o amor incondicional.',
    hebrew: 'HEI',
    hebrewDesc: 'A ação \n\nDesenho: 1. A largura e altura do dalet, mais yud, igual à espiritualidade.\n\nGuematria: Cinco \n\nSignificado: 1. Aqui está 2. Para ser perturbado 3. Veja \n\nPalavra Espírito:\nYOD HEY YOD \nWOD HEY YOD',
    hebrewImg: heh,
    lifeCode: 'Letra em Hebraico: HEI\n\nPonto: Tornozelo direito\n\nAminoglifo: O círculo de criação da transfiguração solar, devolvendo ao ser humano todas as qualidades, habilidades, faculdades e aptidões que correspondem ao Filho do Sol. Oferece a sublime luminescência a cada partícula do humano para que brilhe como um Sol. É a fusão do físico e da alma com a condição do espírito. \nO retornar da consciência para ser o Deus Criador.',
    vitamin: 'D',
    mineral: 'Cromo'
  }
]
