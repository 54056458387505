<template>
  <div class="details">
    <h4 class="text-center">VITAMINA B12 (Cobalamina)</h4>
    <p>
      As principais fontes de vitamina B12 são: leveduras nutricionais, queijo de soja e servem para manter as células vermelhas do sangue saudáveis, atuando na prevenção e no combate da anemia. Além disto, a vitamina B12 previne danos aos nervos, mantém a fertilidade e promove o crescimento e desenvolvimento normais.<br/><br/>
      A deficiência da vitamina B12 no organismo pode causar morte de neurônios e desencadear lesões irreversíveis no sistema nervoso. Veganos e vegetarianos, geralmente, possuem baixa quantidade dessa vitamina no organismo. Anemia megaloblástica, incontinência urinária e falhas na memória também podem ocorrer devido à falta desta vitamina.<br/><br/>
      Para veganos, chlorella é uma ótima alternativa de consumo de vitamina B12. A alga nori, é fonte dessa vitamina e pode ser usada em diferentes receitas.
    </p>
  </div>
</template>
