<template>
  <div class="details">
    <h4 class="text-center">Fenilalanina</h4><br/>
    <p>
      1. Atua na formação de neurotransmissores<br/>
      2. Melhora a memória, pois aumenta a capacidade mental<br/>
      3. Melhora o humor <br/>
      4. Inibidor natural do apetite<br/>
      5. Aumenta níveis de endorfina no cérebro (efeito de analgesia natural) <br/>
      6. Reduz dores e desconforto mesmo crônicos (não trata a causa da dor) <br/>
      7. Atua em tratamento de manchas na pele como vitiligo, pois produz melanina<br/>
      8. Combate à depressão e transtorno bipolar <br/>
      9. Ajuda na disposição <br/>
      10. Aumenta a quantidade de endorfina no cérebro<br/>
      11. Cria a sensação de positivismo em relação à vida, trazendo maior vitalidade<br/>
      12. Ajuda no emagrecimento <br/>
      13. Desperta vontade de atividade física<br/><br/>
      A <span class="bold">felilalanina</span> é um dos aminoácidos usados pelo cérebro para produzir norepinefrina, uma substância química que transmite sinais entre as células nervosas no cérebro, promove o estado de alerta e vitalidade. A fenilalanina eleva o humor, diminui a dor, auxilia na memória e no aprendizado, bem como é usada para tratar artrite, depressão, cólicas menstruais, enxaquecas, obesidade, doença de Parkinson e esquizofrenia. A fenilalanina tem a capacidade de aumentar os níveis de endorfinas, substâncias que ajudam a reduzir dores e desconfortos e ainda transmitem uma sensação de vitalidade e positivismo em relação à vida. Isso acontece devido ao aumento da produção de tirosina e de neurotransmissores excitatórios que melhoram a disposição mental, combatendo vários dos sintomas característicos da depressão. Necessária ao corpo humano visto que é parte integral de todas as proteínas, é a precursora da melanina, pigmento responsável por dar coloração à epiderme. Outro efeito da fenilalanina no cérebro é diminuir a sensação de fome, funcionando como um inibidor natural do apetite. Sua presença também contribui para maior sensação de energia e vitalidade para realização de exercícios físicos. Por todos esses motivos a fenilalanina pode ser encontrada como suplemento, no entanto, a suplementação de fenilalanina deve ser supervisionada por um médico para evitar excesso desse aminoácido ou mesmo sua ingestão por indivíduos intolerantes a ele.<br/><br/>
      A maioria das nozes são ricas em proteínas, o que fornece uma quantidade significativa de fenilalanina. Cinco nozes contêm 540 mg do aminoácido, 10 amêndoas contém 980 mg, 30 amendoins torrados contém 1.400 mg. A manteiga de amendoim contém mais de 1.000 mg em 28 gramas. O feijão, grão de bico e lentilhas contêm cerca de 400 mg de fenilalanina por porção. Produtos de soja, incluindo proteína isolada de soja, farinha de soja e tofu, também são boas fontes do aminoácido, contendo 2000 mg a cada 100 gramas e podem ser inseridas na dieta.<br/><br/>
      Grãos e outros hidratos de carbono normalmente não contêm grandes quantidades de proteína e são muitas vezes bastante baixos em fenilalanina. As exceções a essa regra são os pães e outros grãos que são feitos com amido reduzido. Lentilhas secas integrais são alimentos ricos em fenilalanina excelente para os veganos e podem fornecer em torno de 2500 mg do aminoácido por xícara.<br/><br/>
      Cacau é a base do chocolate em pó. O pó de cacau que é usado para fazer chocolate quente ou bebidas com sabor de chocolate têm fenilalanina em quantidade moderada. O cacau em pó contém mais de sete vezes mais fenilalanina por grama em comparação com a ingestão de bebidas com sabor de chocolate.
    </p>
    <h5>Lista de alimentos ricos em felilalanina</h5>
    <ul>
      <li>Feijão, nozes, grão de bico e lentilhas;</li>
      <li>Beterraba, cenoura, inhame</li>
      <li>Soja e produtos de soja (proteína isolada de soja, farinha de soja, tofu);</li>
      <li>Cacau em pó.</li>
    </ul>
  </div>
</template>
