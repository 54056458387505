<template>
  <div class="kin oracle">
    <div class="head">Oráculo</div>
    <div class="body">
      <div class="flex-center">
        <div class="item" @click="openDetails('guide')">
          <div>
            <img :src="oracle.guide.tone.image" width="70">
            <br/>
            <img :src="oracle.guide.seal.image" width="70">
            <q-tooltip class="bg-purple text-body2" :offset="[10, 10]" max-width="300px">
              {{titles.guide}}
            </q-tooltip>
          </div>
        </div>
      </div>
      <div class="flex-center">
        <div class="row">
          <div class="col-4 item" @click="openDetails('antipode')">
            <div>
              <img :src="oracle.antipode.tone.image" width="70">
              <br/>
              <img :src="oracle.antipode.seal.image" width="70">
              <q-tooltip class="bg-purple text-body2" :offset="[10, 10]" max-width="300px">
                {{titles.antipode}}
              </q-tooltip>
            </div>
          </div>
          <div class="col-4 item" @click="openDetails('destine')">
            <div>
              <img :src="kin.tone.image" width="70">
              <br/>
              <img :src="kin.seal.image" width="70">
              <q-tooltip class="bg-purple text-body2" :offset="[10, 10]" max-width="300px">
                {{titles.destine}}
              </q-tooltip>
            </div>
          </div>
          <div class="col-4 item" @click="openDetails('analog')">
            <div>
              <img :src="oracle.analog.tone.image" width="70">
              <br/>
              <img :src="oracle.analog.seal.image" width="70">
              <q-tooltip class="bg-purple text-body2" :offset="[5, 5]" max-width="300px">
                {{titles.analog}}
              </q-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-center">
        <div class="item" @click="openDetails('ocult')">
          <div>
            <img :src="oracle.ocult.tone.image" width="70">
            <br/>
            <img :src="oracle.ocult.seal.image" width="70">
            <q-tooltip class="bg-purple text-body2" :offset="[10, 10]" max-width="300px">
              {{titles.ocult}}
            </q-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>

  <q-dialog v-model="oracleDetails">
    <q-layout view="lhh LpR lff" container class="dialog">
      <div class="details">
        <h5 class="text-center">{{itemSelected.title}}</h5>
        <div class="margin-top-15">
          <mantra-kin-component :kin="itemSelected.kin" />
        </div>
      </div>
    </q-layout>
  </q-dialog>
</template>

<script>
import { ref } from 'vue'
import MantraKinComponent from './MantraKinComponent.vue'

export default {
  components: { MantraKinComponent },
  name: 'OracleComponent',
  props: {
    oracle: Object,
    kin: Object
  },
  data () {
    return {
      oracleDetails: ref(false),
      itemSelected: {},
      titles: {
        ocult: 'KIN OCULTO: Fator inesperado e escondido, poder inconsciente.',
        analog: 'KIN ANALOGO: Dá apoio e tem afinidade, melhor amigo energético',
        destine: 'KIN DESTINO: Energia principal',
        antipode: 'KIN ANTÍPODA: Energia oposta, indica o desafio',
        guide: 'KIN GUIA: Força natural que protege e orienta, dá inspiração, coloca no caminho'
      }
    }
  },
  methods: {
    openDetails (item) {
      if (item === 'destine') {
        this.itemSelected = {
          kin: this.kin
        }
      } else {
        this.itemSelected = {
          kin: this.oracle[item]
        }
      }
      this.itemSelected.title = this.titles[item]
      this.oracleDetails = true
    }
  }
}
</script>

<style lang="scss" scoped>
  @media (max-width: 335px) {
    img {
        width: 60px !important;
    }
  }
  .item {
    cursor: pointer;
    padding: 3px;
    &:hover {
      background-color: purple !important;
    }
  }
</style>
