<template>
  <div class="details">
    <h4 class="text-center">Zinco</h4>
    <h5>O Zinco é um nutriente mineral essencial para todas as formas de vida.</h5><br/>
    <p>
      O Zinco acumula-se, principalmente, nas coroides oculares, nos ossos e dentes, pele e fâneros, mas armazena-se também no fígado e no tecido muscular.<br/><br/>
      Importante para:<br/>
      - Crescimento;<br/>
      - Ossos;<br/>
      - Diminuir a Oxidação;<br/>
      - Formação do Colágeno;<br/>
      - Imunidade;<br/>
      - Cabelos e Unhas.<br/><br/>
      A necessidade do Zinco para a absorção e na atividade das vitaminas, especialmente da vitamina A e das vitaminas do complexo B. A proteína que transporta a vitamina A, armazenada no fígado, para os olhos e para os outros tecidos, por exemplo, é dependente de Zinco.<br/>
      A insuficiência maternal de Zinco na Gravidez está associada a diversas intercorrências Gestacionais como: baixo peso ao nascer, o parto prematuro e outras complicações perinatais.<br/>
      Os principais sinais e sintomas da carência explícita do Zinco são o retardo ou a interrupção do crescimento e do desenvolvimento infantis, o atraso ou ausência de maturação sexual na adolescência, erupções cutâneas, diarreia crônica e intense, comprometimento do Sistema imunitário, atraso da cicatrização de feridas, anorexia, diminuição do paladar e do olfato, cegueira noturna, ceratite, e alterações comportamentais.<br/>
      A carência moderada de Zinco também é observada em pacientes diabéticos. Apresentam uma maior excreção urinária de Zinco nos portadores de Diabetes Melito, o que contribui para uma deficiência marginal deste elemento.<br/>
      O Zinco atua também no processo cicatricial, participa da incorporação da cistina e da conversão de Glicina e da prolina em colágeno.<br/><br/>
      Fontes de Zinco:<br/>
      - Nozes, amêndoas;<br/>
      - Semente de abóbora e de girassol;<br/>
      - Legumes.<br/><br/>
      O Zinco dos alimentos de origem vegetal, como os cereais de origem integral, tem um aproveitamento muito menor pelo organismo porque o ácido fítico, presente nestes alimentos, inibe a sua absorção, diminuendo a biodisponibilidade. Os pães de farinhas refinadas são pobres em Zinco, mesmo fermentados, porque o zinco foi removido no processo industrial de refine.<br/>
      O acompanhamento médico é importante porque o consumo excessivo e prolongado de Zinco pode levar a deficiência de Cobre e Molibdênio. Acúmulo de zinco resulta do consumo de alimentos ácidos, de bebidas enlatadas em recipientes com revestimento de zinco (galvanizadas) ou, em certas indústrias, da inalação de vapores de óxido de zinco. Excesso de zinco causa náuseas, vômitos e diarreia. A inalação de vapores de óxido de zinco pode causar respiração rápida, sudorese, febre e um gosto metálico na boca, uma doença chamada febre do fumo metálico. O consumo excessivo e prolongado de zinco pode ainda reduzir a absorção de cobre, provocar anemia e afetar o sistema imunológico.<br/>
    </p>
  </div>
</template>
