<template>
  <div class="details">
    <h4 class="text-center">CONHEÇA A VITAMINA C</h4>
    <h5>Funções da vitamina C e consequências de sua falta e consumo em excesso.</h5><br/>
    <p>
      A vitamina C faz parte do grupo de substâncias necessárias para o bom funcionamento do organismo. Também chamada de ácido ascórbico, a vitamina C integra o grupo das vitaminas hidrossolúveis. Ou seja, é dissolvida em água e, por isso, é facilmente metabolizada e consequentemente mais rapidamente excretada pelo organismo.<br/><br/>
      A vitamina C desempenha função importante na manutenção do tecido conjuntivo, ajuda a fortalecer o sistema imunológico contra infecções, além de ser antioxidante, e facilitar a absorção de ferro.
    </p>
    <h5>ONDE ENCONTRAR VITAMINA C</h5>
    <p>
      A dosagem diária de vitamina C varia conforme a idade, o sexo e outros fatores. Por dia, recomenda-se o consumo de 75mg para mulheres de 15 a 70 anos e 90mg para homens na mesma faixa etária.<br/><br/>
      Popularmente, sabe-se que as frutas são os alimentos em que encontramos a vitamina C em abundância, principalmente na laranja, limão, acerola, morango, abacaxi, manga, kiwi, goiaba e caju. Mas também está presente no brócolis, na couve, no repolho, no tomate e no pimentão.<br/><br/>
      É importante ressaltar que a vitamina C é um componente muito instável, capaz de perder boa parte de suas propriedades quando exposta ao oxigênio ou à luz. Por isso, para obter o máximo do alimento, o ideal é consumi-lo o mais fresco possível e evitar armazená-lo por muito tempo, mesmo se for congelado.
    </p>
    <h5>BENEFÍCIOS DA VITAMINA C</h5>
    <p>
      A vitamina C é muito famosa por seus supostos efeitos de cura e prevenção de gripes e resfriados. Segundo uma série de estudos científicos na área da medicina, boa parte dessa fama pode ser considerada mito. O que se tem certeza é que a vitamina C é um nutriente importante para o fortalecimento do sistema imunológico, essencial para o nosso sistema de defesa, o que leva a diminuir o risco de infecções virais como a gripe. No entanto, aumentar a dose diária de vitamina C com suplementação durante a doença, não surte muito efeito.<br/><br/>
      A vitamina C, no entanto, é capaz de gerar diversos outros benefícios. O ácido ascórbico é um excelente antioxidante. Ele combate os radicais livres e previne o envelhecimento das células. Também em virtude de suas funções antioxidantes, a vitamina C atua contra o acúmulo de colesterol nas artérias, o que diminui o risco de doenças cardiovasculares.<br/><br/>
      A facilitação de absorção do ferro e o controle dos níveis de açúcar no sangue também estão entre os benefícios de uma ingestão correta de vitamina C. Ela colabora para níveis saudáveis desses nutrientes no sangue, beneficiando pacientes com anemia e pessoas diabéticas.
    </p>
    <h5>DEFICIÊNCIA DE VITAMINA C</h5>
    <p>
      A não ser em casos específicos, é incomum casos de deficiência de vitamina C. Esse problema pode ocorrer em pessoas que apresentem um quadro de subnutrição ou de alimentação extremamente inadequada, em gestantes com pré-eclampsia e em fumantes.<br/><br/>
      Escorbuto é a principal doença desencadeada pela deficiência de vitamina C. Ela manifesta-se através de sintomas como o sangramento fácil da gengiva ao escovar os dentes e difícil cicatrização. Também pode acarretar em perda dos dentes, inflamação e dor nas articulações e queda de cabelos A falta da vitamina C prejudica a formação de colágeno, que sustenta a pele, ligamentos e cartilagens, e de sulfato de condroitina, que juntos geram os sinais e sintomas típicos da doença.<br/><br/>
      Tendo em vista a importância da vitamina C no processo de absorção de ferro pelo organismo, os primeiros sintomas notáveis de uma deficiência acentuada do nutriente são fraqueza muscular e anemia.
    </p>
    <h5>EXCESSO DE VITAMINA C</h5>
    <p>
      Dados da Pesquisa de Orçamentos Familiares do IBGE mostram que a média de consumo de vitamina C pelo brasileiro é adequada. A quantidade diária recomendada de vitamina C não é difícil de se atingir por meio da alimentação, por isso, a não ser em casos específicos, a suplementação é desaconselhável.<br/><br/>
      Os efeitos de uma quantidade alta de vitamina C podem variar de pessoa para pessoa. O excesso da vitamina, além de sobrecarregar os rins, responsáveis por excretar o nutriente, pode provocar dor abdominal, diarreia, vômito, insônia e, em alguns casos, predispor à formação de cálculos renais.
    </p>
  </div>
</template>
