import { createStore } from 'vuex'
import auth from './auth'
import home from './home'
import friend from './friend'
import notes from './notes'
import waveGoal from './wave_goal'
import moonGoal from './moon_goal'
import goals from './goals'
import whatsMatters from './whatsMatters'
import notifications from './notifications'
import dialogs from './dialogs'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    home,
    friend,
    notes,
    goals,
    waveGoal,
    moonGoal,
    whatsMatters,
    notifications,
    dialogs
  }
})
