
import './styles/quasar.scss'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/fontawesome-v6/fontawesome-v6.css'
import { Notify, Loading } from '../node_modules/quasar'

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: [
    Notify,
    Loading
  ]
}
