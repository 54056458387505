<template>
  <div class="notifications">
    <h4 class="margin-bottom-15 text-center">Notificações</h4>
    <div class="all">
      <div v-if="notifications.length === 0">
        <div class="flex-center">
          <h5>Não encontramos nenhuma notificação</h5>
        </div>
      </div>
      <div v-else>
        <div v-for="(item, keyK) in notifications" :key="keyK">
          <div class="n">
            <div v-html="item['message']"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Notifications',
  computed: {
    notifications () {
      return this.$store.state.notifications.list
    },
    counter () {
      return this.$store.state.notifications.howMuchToSee
    }
  }
}
</script>
<style scoped lang="scss">

  .notifications{
    color: white;
    position: absolute;
    padding: 10px;
    height: 300px;
    width: 370px;
    background-color: #653780;
    z-index: 10000;
    top: 45px;
    border-radius: 10px;
    right: 22px;
    border: 2px solid;
    .all {
      overflow-y: scroll;
      height: 200px;
      height: 225px;
    }
    .n {
      min-height:30px;
      margin: 7px;
      padding: 5px;
      border: 1px solid;
      background-color: rgba(0, 0, 0, 0.432);
      &:hover {
        background-color:rgba(253, 131, 253, 0.466);
      }
    }
  }

  @media (max-width: 400px) {
    .notifications {
      height: 300px;
      width: 100%;
      right: 0;
      top: 49px;
    }
  }
</style>
