<template>
  <div class="details">
    <h4 class="text-center">Cromo</h4>
    <p>
      O cromo é um dos minerais mais importantes para a saúde do organismo, pois está associado ao controle do apetite, combate de diabetes e demais funções essenciais. Por isso, é importante saber quais são os alimentos ricos em cromo e quais seus benefícios ao corpo.
      <br/>
      Quando associado à prática de exercícios físicos regulares, o cromo pode proporcionar redução de peso. Por estimular a sensibilidade à insulina, pode influenciar também o metabolismo proteico, promovendo maior ganho muscular.
    </p>
    <h5>Alimentos que contém cromo:</h5>
    <ul>
      <li>Grãos integrais como aveia, linhaça e chia;</li>
      <li>Arroz integral;</li>
      <li>Frutas, como uva, maçã e laranja;</li>
      <li>Vegetais, como espinafre, brócolis, alho e tomate;</li>
      <li>Leguminosas, como feijão, soja e milho.</li>
    </ul>
    <p>
      O ideal é que esses alimentos fontes de cromo sejam consumidos juntamente com uma fonte de vitamina C, como laranja, abacaxi e acerola, para que seja possível aumentar a absorção desse mineral no intestino.
    </p>
  </div>
</template>
