<template>
  <div class="row">
    <div class="offset-md-2 col-md-8 offset-0 col-sm-12 glutter-x-0" style="flex-wrap: wrap !important;">
      <div class="kin">
        <div class="head">Hunab Ku 21</div>
        <div class="body">
          <img :src="hunabKuImg" width="200">
          <p>
            De todos os tesouros ocultos que vêm à luz<br/>
            Nada existe de mais vasto que Hunab’Ku 21<br/>
            Unidade da totalidade – o segredo do 441<br/>
            Doador único do movimento e da medida<br/>
            Hunab’Ku não pode ser visto ou compreendido<br/>
            Exceto por alguns especialmente designados<br/>
            Conhecido pelo nome de Mago do Infinito<br/>
            Muitos somos nós e ainda sim somos um só<br/>
            Mestres do Cubo da Lei, emanamos e radiamos os poderes do sete<br/>
            Com os ouvidos celestiais, ouvimos os sons sagrados<br/>
            Do vazio universal<br/>
            Nosso voto é de simplicidade<br/>
            Não comemos nada, não pegamos nada<br/>
            Só sabemos como doar<br/>
            Como mestres do Zuvuya<br/>
            Percorremos as ondas do infinito infinitamente<br/>
            Você nos reconhecerá quando ver a si mesmo<br/>
            Emanando do centro do cubo<br/>
            Conhecer-nos é entrar completamente<br/>
            No incrível e infinito esplendor galáctico que se revela
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import hunabKuImg from '../assets/images/arq21.png'
export default {
  name: 'HunabKuDay',
  data () {
    return { hunabKuImg }
  }
}
</script>
