import { db } from '../firebase'

const save = async function (uid, payload) {
  try {
    return db.collection('users')
      .doc(uid)
      .update({ whatsMatters: payload })
      .then((res) => {
        return true
      })
  } catch (error) {
    console.log(error.code)

    return error.code
  }
}

export {
  save
}
