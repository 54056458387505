import { register, list, remove, update } from '../database/friend'
import KinModel from '../models/kin_model'

export default {
  namespaced: true,
  state: {
    list: {},
    selectedFriend: {}
  },
  getters: {},
  actions: {
    update (context, payload) {
      const uid = context.rootState.auth.user.uid
      return update(uid, payload, payload.id).then((res) => {
        context.commit('list', uid)
        return res
      })
    },
    save (context, payload) {
      const uid = context.rootState.auth.user.uid
      return register(uid, payload).then((res) => {
        context.commit('list', uid)
        return res
      })
    },
    list (context) {
      const uid = context.rootState.auth.user.uid
      return context.commit('list', uid)
    },
    remove (context, id) {
      const uid = context.rootState.auth.user.uid
      return remove(uid, id).then((res) => {
        context.commit('list', uid)
      })
    }
  },
  mutations: {
    list (state, uid) {
      list(uid).then((res) => {
        const kinM = new KinModel()
        const kinList = kinM.list()
        var list = []
        res.docs.forEach(e => {
          list.push({
            id: e.id,
            name: e.get('name'),
            date: e.get('date'),
            kin: kinList[e.get('kin') - 1],
            hunabKu: e.get('hunabKu'),
            view: false,
            selected: false
          })
        })
        state.list = list
      })
    },
    selectFriend (state, friend) {
      state.selectedFriend = friend
    }
  }
}
