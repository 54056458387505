<template>
  <div class="details">
    <h4 class="text-center">VITAMINA B13 (Ácido Orótico)</h4>
    <p>
      O ácido orótico é uma substância natural produzida no corpo humano pela flora intestinal. O ácido também é conhecido como vitamina B13 ou ácido pirimidinocarboxílico. Na verdade, não é uma vitamina, mas foi originalmente classificada como tal depois de ser adicionada à dieta de animais de laboratório na década de 1960. A adição dele à comida encorajou a saúde cardíaca e mostrou outros benefícios semelhantes às vitaminas.
      <br/><br/>
      As principais funções desse ácido são metabolizar o ácido fólico junto com a vitamina B12. Uma abundância de ácido orótico atua como um contador para a deficiência de vitamina B12 e tem um efeito poupador sobre a vitamina B12 no corpo. Uma abundância de ácido orótico é usada no lugar da vitamina B12 – não substituindo-a completamente, mas diminuindo o dano causado por uma deficiência de B12.
      <br/><br/>
      Este ácido é usado em cosméticos, produtos médicos e como suplemento de ração animal. Também é utilizado no mundo da musculação competitiva, visto que aumenta a produção de trifosfato de adenosina (ATP), que é a principal fonte de energia do corpo. O ácido orótico faz isso agindo como um precursor do fosforibosilpirofosfato (PRPP), que por sua vez é usado na biossíntese da pirimidina. Quanto maior a quantidade de PRPP presente em um corpo, mais ATP pode ser produzido.
      <br/><br/>
      Os orotatos, ou os sais deste ácido orótico, também são usados em aplicações comerciais, como carreadores minerais para suplementos dietéticos. Um exemplo disso é o orotato de lítio, que é uma combinação de lítio e sal de ácido orótico. Isso é frequentemente usado para tratar o estresse, transtorno de déficit de atenção e hiperatividade (TDAH) e alcoolismo.
      <br/><br/>
      Estudo apontam o ácido orótico como recuperador da memória espacial.
    </p>
  </div>
</template>
