<template>
  <div class="details">
    <h4 class="text-center">Tirosina</h4><br/>
    <p>
      1. Componente importante para conservar a proteína<br/>
      2. Produção de neurotransmissor<br/>
      3. Tem efeitos estimulantes<br/>
      4. Melhora o humor<br/>
      5. Junto com outro aminoácido tem efeito contra ansiedade (Triptofano)<br/>
      6. Ela é desenvolvida no nosso organismo através da fenilalanina <br/>
      7. Quando em quantidade suficiente, é utilizado para gerar sinais elétricos entre neurônios. <br/>
      8. Quando os níveis dos neurotransmissores como Adrenalina, Nora adrenalina e Dopamina tem seus níveis em excesso, ela modifica os processos químicos do organismo relacionados ao estado de alerta, atenção e foco cerebral.<br/><br/>
      Entre os benefícios de ingestão de <span class="bold">tirosina</span>, apontados em estudos científicos, estão a modulação dos efeitos do estresse agudo, a melhoria do desempenho cognitivo sob condições estressantes, o estímulo à sensação de bem estar, o combate à fadiga, o acréscimo qualitativo ao desempenho em treinamentos musculares e físicos e a estimulação da perda de peso. Também tem efeitos contra a ansiedade, quando combinada com outros aminoácidos específicos. A quantidade de consumo da tirosina é de até 12 gramas diárias, em doses divididas. Mais que isto não é recomendado, exceto sob recomendação médica.
    </p>
    <h5>Lista de alimentos ricos em Tirosina</h5>
    <ul>
      <li>Algas, a exemplo de spirulina;</li>
      <li>Soja, castanhas, amêndoas e amendoim, semente de abóbora e de gergelim e feijão.</li>
      <li>Banana e pêssego;</li>
    </ul>
  </div>
</template>
