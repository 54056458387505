import moons from '../datas/moons'

export default function MoonModel () {
  this.calcMoon = function (year, month, day) {
    var currentMoon
    var positionDay
    var kinStart

    moons.forEach(function (moon) {
      if (moon.monthStart.key === month) {
        if (day >= moon.dayStart) {
          currentMoon = moon
          positionDay = (day - moon.dayStart) + 1
        }
      } else if (moon.monthFinal.key === month) {
        if (day <= moon.dayFinal) {
          currentMoon = moon
          var posToFinalMonth = (moon.monthStart.finalDay - moon.dayStart)
          positionDay = (day + posToFinalMonth) + 1
        }
      }
    })

    // O kin que começa o mes
    if (currentMoon !== null) {
      kinStart = ((currentMoon.key - 1) * 28) + year
      if (kinStart > 260) {
        kinStart = kinStart - 260
      }
    }

    return {
      moon: currentMoon,
      positionDay: positionDay,
      kinStart: kinStart
    }
  }
}
