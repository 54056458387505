import moment from 'moment'
import KinModel from '../models/kin_model'

var kinM = new KinModel()
var listKin = kinM.list()

export default {
  namespaced: true,
  state: kinM.constructData(moment().format('YYYY-MM-DD')),
  getters: {},
  actions: {
    constructKin (context, kin) {
      return kinM.constructKin(listKin[kin - 1])
    }
  },
  mutations: {
    changeDate (state, newDate) {
      state.date = newDate
    },
    changeKin (state, date) {
      state.date = date
      var data = kinM.constructData(state.date)
      if (date !== null) {
        if (data.hunabKu) {
          state.hunabKu = true
          state.calendar = {}
        } else {
          state.hunabKu = false
          state.kin = data.kin
          state.oracle = data.oracle
          state.wave = data.wave
          state.chromatics = data.chromatics
          state.calendar = data.calendar
          state.cpsi = data.cpsi
          state.harmonic = data.harmonic
          if (state.userKin) {
            var celestialHarmonicKey = state.kin.key + state.userKin.key
            while (celestialHarmonicKey > 260) {
              celestialHarmonicKey = celestialHarmonicKey - 260
            }
            state.celestialHarmonic = kinM.constructKin(listKin[(celestialHarmonicKey - 1)])
          }
        }
      }
    },
    setCelestialHarmonic (state, uKin) {
      var user = this.getters['auth/user'];
      if (state.kin != undefined && state.kin != null && user != undefined && user['kin'] != null) {
        var celestialHarmonicKey = state.kin.key + uKin
        while (celestialHarmonicKey > 260) {
          celestialHarmonicKey = celestialHarmonicKey - 260
        }
        state.celestialHarmonic = kinM.constructKin(listKin[(celestialHarmonicKey - 1)])
        state.userKin = listKin[uKin - 1]
      }
    }
  }
}
