import seals from '../datas/seals'

export default function () {
  this.getByFamily = function (name) {
    function isFamily (seal) {
      return seal.family === name
    }
    return seals.filter(isFamily)
  }

  this.getByCla = function (name) {
    function isCla (seal) {
      return seal.cla === name
    }
    return seals.filter(isCla)
  }

  this.getByAminoacid = function (amino) {
    function isTheSeal (seal) {
      return seal.aminoacid === amino
    }
    return seals.filter(isTheSeal)
  }
}
