<template>
  <div class="details">
    <h4 class="text-center">Lisina</h4><br/>
    <p>
      1. Utilizado para tratamento de herpes<br/>
      2. Combate infecções por vírus<br/>
      3. Antioxidante <br/>
      4. Eficaz na osteoporose (absorção de cálcio)<br/>
      5. Desenvolvimento ósseo muscular infantil <br/>
      6. Participa da atividade do hormônio do crescimento <br/>
      7. Artrose, artrite, gota e artrite reumatóide<br/>
      8. Reumatismo<br/>
      9. Lombalgia e lombociatalgia. <br/>
      10. Tendinite <br/>
      11. Neurite <br/>
      12. Distensão muscular<br/>
      13. Contusão <br/>
      14. Alívio da dor odontológica<br/>
      15. Dismenorréias <br/>
      16. Cirurgia ortopédica<br/>
      17. Condições traumáticas pós-operatórias<br/><br/>
      A <span class="bold">lisina</span> tem poder antioxidante e é um componente das proteínas. Portanto, é necessária para a construção de massa muscular, para a recuperação de lesões e feridas, na produção de hormônios, enzimas e anticorpos. Também participa da absorção de cálcio e estimula a liberação do hormônio do crescimento. É precursora da carnitina, molécula implicada no transporte das gorduras ao interior da mitocôndria. A lisina compete com a arginina enquanto a sua absorção no intestino. Esta qualidade pode ser utilizada para reduzir os surtos de herpes. A deficiência de lisina gera fatiga, náuseas, anorexia, irritabilidade, anemia, alterações na fertilidade e limita o crescimento.
    </p>
    <h5>Lista de alimentos ricos em Lisina</h5>
    <p>Levedura de cerveja, soja, legumes, sementes, cereais integrais, alfarroba, tremoço, agrião, espargos e espinafres.</p>
  </div>
</template>
