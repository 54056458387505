<template>
  <div class="details">
    <h4 class="text-center">Cisteína</h4><br/>
    <p>
      1. Junto com a Metionina pode fortalecer o crescimento do cabelo <br/>
      2. Ajuda na formação de pele<br/><br/>
      Entre outros benefícios da <span class="bold">cisteína</span> se encontram a ajuda ao sistema imunológico e a processos inflamatórios, a melhora da pele e do cabelo, o combate à osteoporose e o ganho de massa muscular. Entretanto, altas doses desse aminoácido podem ser danosas, causando efeitos colaterais, por isso, o acompanhamento se torna ainda mais primordial para a ingestão da cisteína.<br/>
      Já a sua falta, em raros casos, pode desencadear inchaços, letargia, lesões na pele, deficiência do sistema imunológico e susceptibilidade a danos oriundos de toxinas do metabolismo de substâncias pelo fígado e pelos radicais livres.
    </p>
    <h5>Lista de alimentos ricos em Cisteína</h5>
    <ul>
      <li>Girassol, nozes e soja.</li>
      <li>Cebola roxa, cereais integrais e amendoim</li>
      <li>Em menores escalas, o aminoácido pode ser encontrado no brócolis, nos pimentões vermelhos e amarelos e também na cebola e no alho.</li>
    </ul>
  </div>
</template>
