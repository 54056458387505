import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/home/Home'
import { auth } from '../firebase'
import store from '../store'
import {
  Loading,
  // with custom spinner
  QSpinnerBall
} from 'quasar'
import process from 'process'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/user/Login')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/user/Register')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/user/Settings')
  },
  {
    path: '/friends',
    name: 'friends',
    component: () => import(/* webpackChunkName: "friends" */ '../views/friends/Friends')
  },
  {
    path: '/intentos',
    name: 'intentos',
    component: () => import(/* webpackChunkName: "metas" */ '../views/goals/Goals')
  },
  {
    path: '/o-que-importa',
    name: 'whatMatters',
    component: () => import(/* webpackChunkName: "whatMatters" */ '../views/goals/WhatMatters')
  },
  {
    path: '/notes',
    name: 'notes',
    component: () => import(/* webpackChunkName: "notes" */ '../views/notes/Notes')
  },
  {
    path: '/addNote',
    name: 'addNote',
    component: () => import(/* webpackChunkName: "addNote" */ '../views/notes/AddNote')
  },
  {
    path: '/updateNote',
    name: 'updateNote',
    component: () => import(/* webpackChunkName: "updateNote" */ '../views/notes/UpdateNote')
  },
  {
    path: '/viewNote',
    name: 'viewNote',
    component: () => import(/* webpackChunkName: "viewNote" */ '../views/notes/ViewNote')
  },
  {
    path: '/calc',
    name: 'calc',
    component: () => import(/* webpackChunkName: "calc" */ '../views/calc/Calc')
  },
  {
    path: '/nutricao',
    name: 'nutrition',
    component: () => import(/* webpackChunkName: "nutrition" */ '../views/nutrition/Introduction')
  },
  {
    path: '/nutricao/intro',
    name: 'nutritionIntro',
    component: () => import(/* webpackChunkName: "nutritionIntro" */ '../views/nutrition/Introduction')
  },
  {
    path: '/nutricao/alimentando-os-4-corpos',
    name: 'nutrition4Bodies',
    component: () => import(/* webpackChunkName: "nutrition4Bodies" */ '../views/nutrition/4bodies')
  },
  {
    path: '/nutricao/alimentos-para-cada-tom',
    name: 'nutritionFoodsForTone',
    component: () => import(/* webpackChunkName: "nutritionFoodsForTone" */ '../views/nutrition/FoodsForTone')
  },
  {
    path: '/nutricao/minerais',
    name: 'nutritionMinerals',
    component: () => import(/* webpackChunkName: "nutritionMinerals" */ '../views/nutrition/Minerals')
  },
  {
    path: '/nutricao/vitaminas',
    name: 'nutritionVitamins',
    component: () => import(/* webpackChunkName: "nutritionVitamins" */ '../views/nutrition/Vitamins')
  },
  {
    path: '/nutricao/aminoacidos',
    name: 'nutritionAminoacid',
    component: () => import(/* webpackChunkName: "nutritionAminoacid" */ '../views/nutrition/Aminoacid')
  },
  {
    path: '/nutricao/curando-o-comer',
    name: 'nutritionCuringEating',
    component: () => import(/* webpackChunkName: "nutritionCuringEating" */ '../views/nutrition/CuringEating')
  },
  {
    path: '/holon',
    name: 'Holon',
    component: () => import(/* webpackChunkName: "Holon" */ '../views/Holon')
  },
  {
    path: '/vida-bioquimico/introducao',
    name: 'bioLifeCode-intro',
    component: () => import(/* webpackChunkName: "bioLifeCode-intro" */ '../views/bioLifeCode/Intro')
  },
  {
    path: '/vida-bioquimico/pratica',
    name: 'bioLifeCode-practice',
    component: () => import(/* webpackChunkName: "bioLifeCode-practice" */ '../views/bioLifeCode/Practice')
  },
  {
    path: '/vida-bioquimico/holon',
    name: 'bioLifeCode-holon',
    component: () => import(/* webpackChunkName: "bioLifeCode-holon" */ '../views/bioLifeCode/Holon')
  },
  {
    path: '/vida-bioquimico/mantra',
    name: 'bioLifeCode-mantra',
    component: () => import(/* webpackChunkName: "bioLifeCode-mantra" */ '../views/bioLifeCode/Mantra')
  },
  {
    path: '/segredo-da-vida',
    name: 'lifeSecret',
    component: () => import(/* webpackChunkName: "lifeSecret" */ '../views/LifeSecret')
  },
  {
    path: '/onda-de-transito',
    name: 'trafficWave',
    component: () => import(/* webpackChunkName: "trafficWave" */ '../views/trafficWave/TrafficWave')
  },
  {
    path: '/politicas-de-privacidade',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '../views/PrivacyPolicy')
  },
  {
    path: '/informacoes-adicionais',
    name: 'registerUserDetails',
    component: () => import(/* webpackChunkName: "registerUserDetails" */ '../views/user/RegisterUserDetails')
  },
  {
    path: '/meditacao',
    name: 'meditation',
    component: () => import(/* webpackChunkName: "meditation" */ '../views/meditation/Chackras')
  },
  {
    path: '/introducao',
    name: 'introduction',
    component: () => import(/* webpackChunkName: "introduction" */ '../views/introduction/Sync1328')
  },
  {
    path: '/introducao/sincronario-das-13-luas',
    name: 'introduction-sync1328',
    component: () => import(/* webpackChunkName: "introduction-sync1328" */ '../views/introduction/Sync1328')
  },
  {
    path: '/introducao/kin',
    name: 'introduction-kin',
    component: () => import(/* webpackChunkName: "introduction-kin" */ '../views/introduction/Kin')
  },
  {
    path: '/introducao/oraculo-do-destino',
    name: 'introduction-oracle',
    component: () => import(/* webpackChunkName: "introduction-oracle" */ '../views/introduction/Oracle')
  },
  {
    path: '/introducao/tzolkin',
    name: 'introduction-tzolkin',
    component: () => import(/* webpackChunkName: "introduction-tzolkin" */ '../views/introduction/Tzolkin')
  },
  {
    path: '/introducao/matriz-tzolkin',
    name: 'introduction-matriz-tzolkin',
    component: () => import(/* webpackChunkName: "introduction-matriz-tzolkin" */ '../views/introduction/MatrizTzolkin')
  },
  {
    path: '/introducao/onda-encantada',
    name: 'introduction-wave',
    component: () => import(/* webpackChunkName: "introduction-wave" */ '../views/introduction/Wave')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const hideLoad = function () {
  setTimeout((res) => {
    Loading.hide()
  }, 1000)
}

const AuthUser = async function () {
  Loading.show({
    spinner: QSpinnerBall,
    spinnerColor: 'green',
    spinnerSize: 180
  })
  return new Promise((resolve, reject) => {
    auth.onAuthStateChanged(user => {
      if (!user) {
        resolve(false)
        hideLoad()
      } else {
        store.dispatch('auth/setUser', user).then(async (res) => {
          resolve(true)
          hideLoad()
        }).catch(res => {
          console.log('catch')
          store.dispatch('auth/setUser', user).then((res) => {
            resolve(true)
            hideLoad()
          })
        })
      }
    }, reject)
  })
}

router.beforeEach(async (to, from, next) => {
  const user = await AuthUser()

  function exclusiveAccessPages(rName) {
    var exclusivePages = [
      'vida-bioquimico',
      'nutritionFoodsForTone',
      'nutritionAminoacid',
      'nutritionVitamins',
      'nutritionMinerals',
      'addNote',
      'whatMatters',
      'calc'
    ]
    var isExclusive = exclusivePages.some(str => str.includes(rName))
    console.log('exclusiveAccessPages', rName, exclusivePages, isExclusive)
    return isExclusive
  }

  if (!user) {
    if (exclusiveAccessPages(to.name)) {
      return next({ name: 'home' })
    }
    return next()
  } else {
    // Condição para expiração e criação de assinatura criada pelo mercadopago
    if (store.state.auth.user == null) {
      if (to.name !== 'registerUserDetails') {
        return next({ name: 'registerUserDetails' })
      }
    } else {
      if (!store.state.auth.userPro) {
        if (exclusiveAccessPages(to.name)) {
          return next({ name: 'home' })
        }
      }
    }
    return next()
  }
})

export default router
