import { registerWaveGoal, listWaveGoal, removeWaveGoal } from '../database/goals'
import WaveModel from '../models/wave_model'
import KinModel from '../models/kin_model'
import moment from 'moment'

const kinM = new KinModel()
const waveM = new WaveModel()
const kinList = kinM.list()

var currentDate = moment().format('YYYY-MM-DD')
var date = currentDate.split('-')
var kin = kinM.calc(date[0], date[1], date[2])
var wave = waveM.getWave(kinList, kinList[kin - 1])
var currentSync = kinM.calcCalender(date[0], date[1], date[2])
var year = currentSync.year
var moon = (currentSync.moon !== undefined) ? currentSync.moon.moon.key : null

export default {
  namespaced: true,
  state: {
    list: {},
    currentWave: wave
  },
  getters: {},
  actions: {
    save (context, payload) {
      const uid = context.rootState.auth.user.uid
      return registerWaveGoal(uid, year, moon, payload).then((res) => {
        context.commit('list', uid)
        return res
      })
    },
    list (context) {
      const uid = context.rootState.auth.user.uid
      return context.commit('list', uid)
    },
    remove (context, id) {
      const uid = context.rootState.auth.user.uid
      return removeWaveGoal(uid, year, moon, id).then((res) => {
        context.commit('list', uid)
      })
    }
  },
  mutations: {
    list (state, uid) {
      listWaveGoal(uid, year, moon).then((res) => {
        var list = []
        res.docs.forEach(e => {
          if (e.get('wave').id === wave.key) {
            list.push({
              title: e.get('title'),
              id: e.id
            })
          }
        })
        state.list = list
      })
    }
  }
}
