<template>
  <div class="details">
    <h4 class="text-center">Iodo</h4>
    <h5>Mineral é responsável pela produção dos hormônios da tireoide.</h5><br/>
    <p>
      A principal função do iodo no organismo é participar da formação da tiroxina e triiodotiroxina, hormônios tireoidianos responsáveis por acelerar quase todas as reações celulares do corpo humano.<br/><br/>
      Este mineral foi incorporado à composição do sal de cozinha no Brasil em 1953 por determinação da vigilância sanitária, para prevenir especialmente o bócio, inchaço que forma um papo pelo aumento do volume da tireoide em função da falta de iodo. Mesmo presente em baixas quantidades, cerca de 15 a 23 mg, este íon desempenha variadas e importantes atividades no organismo.<br/><br/>
      O iodo participa da conversão de betacaroteno em vitamina A, da síntese de proteínas e da absorção intestinal dos carboidratos. Presente na atuação de várias enzimas, o micromineral também colabora para o desenvolvimento do cérebro e para reprodução.
    </p>
    <h5>QUANTIDADE RECOMENDADA, FONTE E EFEITOS DA DEFICIÊNCIA E EXCESSO DE CÁLCIO</h5>
    <p>
      A ingestão diária de iodo recomendada é de 150 microgramas, ou seja, um milésimo de um miligrama. A principal fonte de iodo é o sal de cozinha iodado, lembrando que o sal grosso é pobre em iodo pois a secagem da água do mar realizada para sua obtenção leva também à evaporação do iodo, que é volátil.<br/>
      Outras fontes importantes de iodo são especialmente algas marinhas. As algas secas contêm 4.500 mcg de iodo por porção de 7g, o equivalente a 3.000% da necessidade diária. Pode parecer muito, mas não se preocupe, o organismo tem boa tolerância à ingestão de altas doses de iodo, expelindo o excesso com facilidade.<br/>
      Apesar de raro, níveis elevados de iodo no organismo podem causar supressão da atividade tireoidiana. Quando em falta no organismo, o iodo pode causar bócio, hipotireoidismo, hipertireoidismo, dificuldade de raciocínio e desenvolvimento do cérebro, atraso no desenvolvimento físico e até raquitismo.<br/>
      Alimentos como pêssego, amêndoas, soja e mandioca tendem a dificultar a absorção de iodo, não devendo ser consumidos juntos às refeições ricas neste mineral. O volume reduzido de iodo contribui indiretamente para o ganho de peso, um dos sintomas do hipotireoidismo, quando há baixa produção de hormônios da tireoide.
    </p>
  </div>
</template>
