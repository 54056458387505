<template>
  <div class="details">
    <h4 class="text-center">VITAMINA A: FUNÇÕES E IMPORTÂNCIA</h4>
    <h5>Considerada uma das mais importantes, a vitamina A é famosa por seus benefícios à visão e sua composição antioxidante.</h5><br/>
    <p>
      Considerada uma das mais importantes, a vitamina A é chamada também de Retinol. Esta vitamina é famosa por seus benefícios à visão e por sua composição antioxidante, que fortalece o sistema imunológico e protege a pele. Ela é do tipo lipossolúvel, ou seja, é absorvida quando há presença de lipídeos (gordura), bile e suco pancreático.<br/><br/>
      Nos alimentos de origem vegetal o nutriente está na forma de grupos de carotenoides, que inclui o betacaroteno. Essas substâncias serão convertidas na vitamina. A quantidade ideal média de consumo é de 700 microgramas por dia para mulheres adultas e 900 para homens adultos.
    </p>
    <h5>ONDE ENCONTRAR VITAMINA A</h5>
    <p>
      No seu dia a dia, você pode consumir a vitamina através de frutas como manga, mamão, caju, pêssego, damasco e até laranja. Em verduras e legumes, está na cenoura, no milho, na batata doce, abóbora, moranga, couve, espinafre, brócolis, folhas de beterraba, alface e agrião.<br/><br/>
    </p>
    <h5>BENEFÍCIOS DA VITAMINA A</h5>
    <p>
      A vitamina A protege a córnea, parte do olho que transmite e concentra a luz que entra no olho, portanto ela acaba sendo importante para a visão. O nutriente também age para manter a integridade e função das células da pele e das mucosas, sendo utilizado por exemplo em cosméticos.<br/>
      Na infância, ela é importante para o crescimento de diferentes maneiras. A vitamina A atua na proliferação e divisão celular e também regula a expressão do gene para a formação do hormônio de crescimento, o GH.<br/><br/>
      Através de seu efeito antioxidante, esta vitamina evita a ação dos radicais livres que teriam efeito nocivo para as células e são tidos como causadores de arteriosclerose, catarata, tumores e doenças de pele.
    </p>
    <h5>DEFICIÊNCIA DE VITAMINA A</h5>
    <p>
      A deficiência de vitamina A pode causar uma série de problemas de saúde. Em crianças, a deficiência do nutriente prejudica o crescimento e o desenvolvimento, pode causar cegueira irreversível e aumentar o risco de mortalidade infantil.<br/><br/>
      Outros problemas causados pela deficiência de vitamina A são: aumento da gravidade de infecções comuns como diarreia e infecções respiratórias, endurecimento das membranas mucosas dos tratos respiratório, gastrointestinal e urinário, redução do olfato e do paladar, ressecamento da esclera (parte branca dos olhos) e da córnea, inflamação da pele (dermatite) e estresse.<br/><br/>
      A falta de amamentação ou interrupção precoce do processo de amamentação, uma dieta com poucos alimentos ricos em vitamina A e baixa ingestão de gordura, afinal ela é lipossolúvel e a gordura desempenha papel importante na manutenção dela no organismo, e excesso no consumo de álcool, são todos fatores que podem causar a deficiência de vitamina A.
    </p>
    <h5>EXCESSO DE VITAMINA A</h5>
    <p>
      Assim como a deficiência, o excesso de vitamina A também pode acarretar em problemas de saúde, como dores de cabeça, tonturas e náuseas, dores nos ossos e nas articulações, problemas no crescimento e falta de apetite, cansaço, irritabilidade e sangramentos.
      Embora o excesso de vitamina A possa ocorrer por conta da ingestão de grandes quantidades de fígado de animais, óleo de fígado de bacalhau e óleo de outros peixes, a maioria dos casos de toxicidade de vitamina A resultam de uso excessivo de suplementos vitamínicos. Procure sempre orientação médica ao realizar suplementação.
    </p>
  </div>
</template>
