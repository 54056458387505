<template>
    <q-layout view="lhh LpR lff" container class="addFriend">
      <div class="flex-center glutter-x-0">
        <div>
          <div class="q-pa-md">
            <q-form @submit.prevent ref="myForm" class="q-gutter-md">
              <h3 class="margin-top-15">Adicionar amig@</h3>
              <q-input dark v-model="friend.name" filled type="text" label="Nome"
                lazy-rules :rules="[ val => val && val.length > 0 || 'Digite o nome']"/>
              <div class="flex-center margin-0">
                <div class="q-glutter-md gregorianCalc text-center">
                  <label>Data Gregoriana</label>
                  <div class="input-group">
                    <input type="number" class="form-control" v-model="friend.date[2]">
                    <input type="number" class="form-control" v-model="friend.date[1]">
                    <input type="number" class="form-control" v-model="friend.date[0]">
                    <q-btn @click="dateChange()" size="sm" icon="fa fa-chevron-right"/>
                  </div>
                </div>
              </div>
              <div v-if="!invalidDate">
                <div v-if="!friend.hunabKu">
                  <div class="yantra-kin">
                    <div>
                      <img :src="friend.kin.tone.image" width="80"/>
                      <br/>
                      <img :src="friend.kin.seal.image" width="80" />
                      <br/>
                    </div>
                  </div>
                  <div class="text-center">
                    <h5>{{friend.kin.name}}</h5>
                  </div>
                </div>
                <div v-else>
                  <div class="yantra-kin flex-center">
                    <img :src="hunabKuImg" width="130"/>
                  </div>
                  <div class="text-center">
                    <h5>Hunab Ku 21</h5>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 offset-md-3">
                  <q-btn @click="save()" class="w-100" outline color="green" :disable="(loading)" :label="(loading) ? 'Salvando...' : 'Salvar'" />
                </div>
              </div>
            </q-form>
          </div>
        </div>
      </div>
    </q-layout>
</template>

<script>
import KinModel from '../../models/kin_model'
import hunabKuImg from '../../assets/images/arq21.png'
import moment from 'moment'

export default {
  name: 'AddFriendDialog',
  props: { closeDialog: Function },
  data () {
    return {
      friend: {
        date: this.$store.state.home.date.split('-'),
        name: '',
        kin: this.$store.state.home.kin,
        hunabKu: this.$store.state.home.hunabKu
      },
      loading: false,
      invalidDate: false,
      hunabKuImg
    }
  },
  methods: {
    dateChange () {
      var kinM = new KinModel()
      var d = this.friend.date
      d = moment([d[0], (d[1] - 1), d[2], 15, 25, 50, 125]).format('YYYY-MM-DD')

      if (d !== 'Invalid date') {
        const response = kinM.constructData(d)
        this.friend.kin = response.kin
        this.friend.hunabKu = response.hunabKu
        this.invalidDate = false
      } else {
        this.invalidDate = true
      }
    },
    save () {
      this.loading = true
      this.dateChange()
      this.$refs.myForm.validate().then(success => {
        if (success) {
          this.$store.dispatch('friend/save', {
            name: this.friend.name,
            date: this.friend.date.join('-'),
            kin: (this.friend.hunabKu) ? null : this.friend.kin.key,
            hunabKu: this.friend.hunabKu
          }).then((res) => {
            this.loading = false
            if (res) {
              this.closeDialog()
              this.$q.notify({
                color: 'green',
                textColor: 'white',
                icon: 'success',
                message: 'Amig@ Salv@'
              })
            } else {
              this.$q.notify({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'Ops, tente novamente mais tarde'
              })
            }
          })
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .addFriend {
    border: 1px solid #fff;
    background-color: #000 ;

    .flex-center {
      color: #fff;
      margin-top: 35px;
      .yantra-kin {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -10px;
      }
    }
  }
</style>
