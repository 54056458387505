<template>
  <div class="details">
    <h4 class="text-center">Silício</h4>
    <p>O silício é um dos minerais mais importantes para o corpo humano e deve fazer parte da dieta. Pois, ele realiza diversas funções essenciais no organismo. Ele é essencial para a produção de colágeno e cicatrização da pele, além de prevenir o envelhecimento precoce. Também fortalece ossos, unhas, cabelos e protege a saúde cerebral, evitando doenças degenerativas.</p>
    <h5>Funções do silício no organismo</h5>
    <ul>
      <li>Fortalece as unhas</li>
      <li>Importante para a pele e o cabelo</li>
      <li>Regulação do metabolismo</li>
      <li>Síntese de colágeno</li>
      <li>Previne o envelhecimento precoce</li>
      <li>Essencial para a saúde óssea</li>
    </ul>
    <h5>Alimentos ricos em silício</h5>
    <h5>Frutas</h5>
    <p>
      De modo geral, a maior parte das frutas contém silício em sua composição. Além disso, são fonte de fibras, vitaminas e outros minerais também essenciais para a saúde. Algumas frutas especialmente recomendadas para aumentar a ingestão de silício são: banana, maçã, manga, tangerina, laranja e abacaxi, assim como as frutas secas, como o damasco, tâmara e ameixa.
    </p>
    <h5>Legumes e verduras</h5>
    <p>Legumes e verduras são excelentes fontes desse mineral. Em especial, alimentos como cenoura, beterraba, folhas verdes e abóbora são indicados como parte de uma dieta rica no mineral. </p>
    <h5>Leguminosas e oleaginosas</h5>
    <p>Ainda, as leguminosas, como lentilha, soja, feijão e grão-de-bico são ótimas fontes. Não só, as oleaginosas – em especial a amêndoa, também contêm grande quantidade em sua composição.</p>
    <h5>Cereais</h5>
    <p>Aveia, cevada, centeio, arroz integral e companhia são uma boa maneira de acrescentar silício na dieta. Além disso, cereais integrais são fontes de fibras, o que beneficia a digestão e aumenta a saciedade, atuando assim a favor do emagrecimento saudável.</p>
  </div>
</template>
