<template>
  <div class="details">
    <h4 class="text-center">VITAMINA B7 (BIOTINA)</h4>
    <h5></h5>
    <p>
      As principais fontes de vitamina B7 são: Abacate, ervilha, banana, aveia, framboesa, amendoim, nozes, tomate, cebola, cenoura, alface, couve-flor e amêndoa. Antes chamada de vitamina H, a vitamina B7 auxilia no crescimento celular, na produção de ácidos graxos, metabolismo de carboidratos, lipídeos e proteínas, e na utilização das vitaminas do complexo B. A biotina pode evitar a queda de cabelos em alguns homens.<br/><br/>
      A deficiência da vitamina B7 pode causar queda de cabelo, descamação e ressecamento da pele, feridas nos cantos da boca, inchaço e dor na língua, olhos secos, perda de apetite, cansaço, e insônia. A substância também pode ser produzida pelas bactérias da flora intestinal, o que ajuda a manter seus níveis adequados no organismo. Pessoas hospitalizadas que não se alimentam adequadamente, pacientes com diabetes ou que fazer hemodiálise e mulheres grávidas, eventualmente podem apresentar uma quadro de deficiência de vitamina B7.
    </p>
  </div>
</template>
