<template>
  <div>

    <div class="kin chromatics">
      <div class="head">Família {{chromatics.family[0].family}}</div>
      <div class="body">
        <div class="seals">
          <div class="seal" v-for="(seal, key) in chromatics.family" :key="key">
            <img :src="seal.image" width="30" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="kin chromatics">
      <div class="head">Clã {{chromatics.cla[0].cla}}</div>
      <div class="body">
        <div class="seals">
          <div class="seal" v-for="(seal, key) in chromatics.cla" :key="key">
            <img :src="seal.image" width="30" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChromaticsComponent',
  props: {
    chromatics: Object
  }
}
</script>

<style lang="scss" scoped>
  .chromatics {
    .seals {
      display: flex;
      align-items: center;
      justify-content: center;

      .seal {
        margin: 0 5px 0 5px;
      }
    }
  }
</style>
