import { db } from '../firebase'

const list = function () {
  return db.collection('notifications')
    .orderBy('created_at', 'desc')
    .get()
    .then((v) => {
      return v.docs
    })
    .catch((e) => {
      console.log('catch')
      console.log(e)
    })
}

const setUserView = function (uid) {
  var userDoc = db.collection('users').doc(uid)
  return list().then((_notifications) => {
    var _allID = []
    _notifications.forEach(v => {
      _allID.push(v.id)
    })
    var data = {
      notificationsViews: _allID
    }
    return userDoc.update(data)
  })
}

const getUserView = function (uid) {
  var userDoc = db.collection('users').doc(uid)
  return list().then((_notifications) => {
    var counterNotificationsToSee = _notifications.length
    return userDoc.get().then((value) => {
      if (value.get('notificationsViews')) {
        counterNotificationsToSee = 0
        var _userNotificationsViews = value.get('notificationsViews')
        _notifications.forEach(v => {
          if (!(_userNotificationsViews.indexOf(v.id) > -1)) {
            counterNotificationsToSee++
          }
        })
      }
      return counterNotificationsToSee
    })
  })
}

export { list, setUserView, getUserView }
