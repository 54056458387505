<template>
  <div class="details">
    <h4 class="text-center">Leucina</h4><br/>
    <p>
      1. Aumenta a massa muscular <br/>
      2. Melhora a mobilidade física em idosos <br/>
      3. Diminui a atrofia muscular em idosos <br/>
      4. Ajuda baixar nível de açúcar no sangue<br/>
      5. Aumenta a defesa do organismo<br/>
      6. Ajuda na cicatrização de ossos fraturados <br/><br/>
      A <span class="bold">leucina</span> interage com os aminoácidos isoleucina e valina para promover a cicatrização de feridas. Atua no tecido muscular, pele e ossos e é recomendada para quem está se recuperando de uma cirurgia e até antes da realização do procedimento.
    </p>
    <h5>Lista de alimentos ricos em leucina</h5>
    <p>Feijão Branco, amendoim, beringela, quiabo, castanhas, repolho, espinafre, milho, lentilha, semente bóbora.</p>
  </div>
</template>
