import { auth } from '../firebase'
import router from '../router'
import moment from 'moment'
// import store from '@/store'

import {
  register,
  login,
  updateUser,
  getUserById,
  reLogin,
  updatePassword,
  resetPassword,
  registerUserDetails,
  userIsPro,
  assignPlan,
  manageSubs
} from '../database/user'

// initial state
const state = () => ({ user: null, uid: null, expired: false, userPro: false, freeTrial: false, userProOld: false })

// getters
const getters = {
  user: function (state) {
    return state.user
  },
  userPro: function(state) {
    return state.userPro
  },
  freeTrial: function(state) {
    return state.freeTrial
  },
}

// actions
const actions = {
  register: async function ({ commit }, payload) {
    const authi = await register(payload)
    if (authi === true) {
      // router.push('/')
      return ''
    } else if (authi === 'auth/email-already-in-use') {
      return 'Este email ja está sendo usado'
    } else if (authi === 'auth/weak-password') {
      return 'Digite uma senha maior'
    } else if (authi === 'verifyEmail') {
      return 'Falta você verificar seu email, acabamos de enviar outro'
    } else if (authi === 'errorVerifyEmail') {
      return 'Perdão, nao conseguimos enviar o email de verificação'
    } else {
      console.log('error', authi)
      return 'Tente novamente mais tarde'
    }
  },
  login: async function ({ commit }, payload) {
    const user = await login(payload)
    if (user === true) {
      router.push('/')
      return ''
    } else {
      if (user === 'auth/user-not-found') {
        return 'Email não encontrado'
      } else if (user === 'auth/wrong-password') {
        return 'Senha Incorreta'
      } else if (user === 'verifyEmail') {
        return 'Falta você verificar seu email, acabamos de enviar outro'
      } else if (user === 'errorVerifyEmail') {
        return 'Perdão, nao conseguimos enviar o email de verificação'
      } else {
        return 'Tente novamente mais tarde'
      }
    }
  },
  reLogin: async function ({ commit }, payload) {
    const result = await reLogin(payload)

    commit('reLogin', result)
  },
  resetPassword: async function (commit, payload) {
    const result = await resetPassword(payload)
    if (result === 'error') {
      return false
    } else {
      return true
    }
  },

  updatePassword: async function ({ commit }, payload) {
    await updatePassword(payload)
  },
  logout: async function ({ commit }) {
    await auth.signOut()
    commit('setUser', { res: null, payload: null })
    commit('setUserPro', false)
    commit('setFreeTrial', false)
    commit('setUserProOld', false)
    router.push('/login')
  },
  getUserById: async function ({ commit }, payload) {
    try {
      const result = await getUserById(payload)
      if (result.exists) {
        commit('auth/setUser', result.data())
      }
    } catch (error) {
      console.log(error)
    }
  },
  registerUserDetails: async function (context, payload) {
    if (payload !== null) {
      return registerUserDetails(context.state.uid, payload).then((res) => {
        return res
      })
    }
  },
  setUser: function (context, payload) {
    if (payload !== null) {
      context.commit('setUID', payload.uid)
      return getUserById(payload.uid).then(async (res)  => {
        if (res.exists) {
          if (res.get('name') !== undefined && res.get('date') !== undefined) {
            context.commit('setUser', { res: res.data(), payload })
            await this.dispatch('auth/setUserSubs')
            if (res.get('subscribeAt') === undefined) {
              // context.commit('setExpired', true)
            } else {
              var subsAt = new Date(res.get('subscribeAt'))
              var now = new Date(moment().format('YYYY-MM-DD'))
              if (now > subsAt) {
                // context.commit('setExpired', true)
              } else {
                context.commit('setUserPro', true)
                context.commit('setUserProOld', true)
              }
            }

            // Verify free trial
            var creationTime = Date.parse(auth.currentUser.metadata.creationTime)
            var fromNowItCounts = new Date(2024, 5, 27)
            if (creationTime < fromNowItCounts) {
              creationTime = fromNowItCounts;
            }
            
            let futureTime = new Date(creationTime);
            futureTime.setDate(futureTime.getDate() + 3);
            
            if (futureTime > new Date()) {
              if (!context.state.userPro) {
              context.commit('setFreeTrial', true)
              }
              context.commit('setUserPro', true)
            }

            this.commit('home/setCelestialHarmonic', res.data().kin)
            this.dispatch('friend/list')
            this.dispatch('notes/list')
            this.dispatch('notifications/list')
            return true
          }
        }
        return false
      })
    }
  },
  updateUser: async function ({ commit, state }, payload) {
    try {
      await updateUser(state.user.uid, payload)
      commit('addUserDetails', payload)
    } catch (error) {
      console.log(error)
    }
  },
  setUserSubs:  async function ({ commit, state }, payload) {
    if (state.user) {
      return userIsPro(state.user.uid).then((isPro) => {
      commit('setUserPro', isPro)
    })
    }
  },
  assignPlan: function ({ commit, state }, payload) {
    console.log('assign into store', state.user);
    if (state.user) {
      return assignPlan()
    }
    return 'login'
    // login to assign
  },
  manageSubs: function({commit, state}, payload) {
    if (state.user) {
      manageSubs();
    }
  }
}

// mutations
const mutations = {
  setExpired: (state, expired) => {
    state.expired = expired
  },
  setUID: (state, uid) => {
    state.uid = uid
  },
  setUser: (state, { res, payload }) => {
    state.user = payload
    if (res) {
      const entries = Object.entries(res)

      entries.forEach(([key, val]) => {
        state.user[key] = val
      })
    }
  },
  setUserPro: (state, active) => {
    state.userPro = active;
  },
  setUserProOld: (state, active) => {
    state.userProOld = active;
  },
  setFreeTrial: (state, active) => {
    state.freeTrial = active;
  },
  addUserDetails: function (state, payload) {
    const entries = Object.entries(payload)

    entries.forEach(([key, val]) => {
      state.user[key] = val
    })
  },
  reLogin: function (state, payload) {
    state.resultPwd = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
