import { register, list, remove, update } from '../database/notes'
import KinModel from '../models/kin_model'
import plasma1 from '../assets/images/plasma/plasma1.png'
import plasma2 from '../assets/images/plasma/plasma2.png'
import plasma3 from '../assets/images/plasma/plasma3.png'
import plasma4 from '../assets/images/plasma/plasma4.png'
import plasma5 from '../assets/images/plasma/plasma5.png'
import plasma6 from '../assets/images/plasma/plasma6.png'
import plasma7 from '../assets/images/plasma/plasma7.png'
import WaveModel from '../models/wave_model'

const plasma = function (day) {
  if ([1, 8, 15, 22].includes(day)) {
    return { name: 'Dali', img: plasma1 }
  } else if ([2, 9, 16, 23].includes(day)) {
    return { name: 'Seli', img: plasma2 }
  } else if ([3, 10, 17, 24].includes(day)) {
    return { name: 'Gama', img: plasma3 }
  } else if ([4, 11, 18, 25].includes(day)) {
    return { name: 'Kali', img: plasma4 }
  } else if ([5, 12, 19, 26].includes(day)) {
    return { name: 'Alfa', img: plasma5 }
  } else if ([6, 13, 20, 27].includes(day)) {
    return { name: 'Limi', img: plasma6 }
  }
  return { name: 'Sílio', img: plasma7 }
}

const kinM = new KinModel()
const kinList = kinM.list()
const waveM = new WaveModel()

export default {
  namespaced: true,
  state: {
    list: {},
    selectedNote: {}
  },
  getters: {},
  actions: {
    update (context, payload) {
      const uid = context.rootState.auth.user.uid
      return update(uid, payload, payload.id).then((res) => {
        context.commit('list', uid)
        return res
      })
    },
    save (context, payload) {
      const uid = context.rootState.auth.user.uid
      return register(uid, payload).then((res) => {
        context.commit('list', uid)
        return res
      })
    },
    list (context) {
      const uid = context.rootState.auth.user.uid
      return context.commit('list', uid)
    },
    remove (context, id) {
      const uid = context.rootState.auth.user.uid
      return remove(uid, id).then((res) => {
        context.commit('list', uid)
      })
    }
  },
  mutations: {
    list (state, uid) {
      list(uid).then((res) => {
        var list = []

        res.docs.forEach(e => {
          var dateS = e.get('date')
          var date = dateS.split('-')
          var calendar = kinM.calcCalender(date[0], date[1], date[2])
          calendar.year = kinList[calendar.year - 1]

          var kin
          var wave
          if (e.get('kin') !== null) {
            kin = kinList[e.get('kin') - 1]
            wave = waveM.getWave(kinList, kin)
          }

          var kinDatas = {
            id: e.id,
            desc: e.get('desc'),
            date: e.get('date'),
            kin: kin,
            calendar: calendar,
            wave: wave,
            hunabKu: e.get('hunabKu'),
            view: false
          }

          const isYear = function (year) {
            return year.key === calendar.year.key
          }
          const isMoon = function (moon) {
            return moon.key === calendar.moon.moon.key
          }

          var yearKey
          if (!list.find(isYear)) {
            list.push(calendar.year)
            yearKey = list.indexOf(calendar.year)
            list[yearKey].moons = []
            list[yearKey].visible = true
            list[yearKey].kins = []
          } else {
            yearKey = list.indexOf(calendar.year)
          }

          if (calendar.moon === undefined) {
            // Day Out Time
            list[yearKey].kins.push(kinDatas)
          } else {
            var m = calendar.moon.moon
            if (!list[yearKey].moons.find(isMoon)) {
              list[yearKey].moons.push({
                key: m.key,
                tone: m.tone,
                name: m.name,
                kins: [],
                visible: true
              })
            }
            var moonKey = list[yearKey].moons.map((e) => {
              return e.name
            }).indexOf(m.name)
            kinDatas.plasma = plasma(calendar.moon.positionDay)
            list[yearKey].moons[moonKey].kins.push(kinDatas)
          }
        })
        state.list = list
      })
    },
    selectNote (state, note) {
      state.selectedNote = note
    }
  }
}
