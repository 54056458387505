<template>
  <div class="assignPlan">
    <div v-if="simple != null && simple">
      <q-btn outline color="white" class="head" @click="openDetails()">Assine o Plano Cósmico</q-btn>
    </div>
    <div v-else>
      <q-btn outline color="white" class="head" @click="openDetails()">Assine o Plano Cósmico para ver esta leitura</q-btn>
      <p>Cadastre-se para receber 3 dias de teste gratuito</p>
    </div>
    <!-- <q-btn outline color="white">Click para saber mais</q-btn> -->
    
  </div>

  <q-dialog v-model="details">
    <q-layout view="lhh LpR lff" container class="dialog">
      <div class="details">
        <div class="margin-top-15">
          <h3 class="text-center">Plano Cósmico</h3>
          <h5 class="margin-top-15 margin-bottom-15">Obtenha acesso à todos estes recursos anualmente</h5>
          <ul>
            <li>Adicionar Metas, Amigos e Anotações</li>
            <li>Veja os alimentos para cada Tom</li>
            <li>Aminoácido, vitaminas e minerais de cada Selo</li>
            <li>Ativação do Código da vida Bioquímico</li>
          </ul>
          <q-btn outline color="white" class="assignBtn padding-5" @click="assignPlan()" :disable="(loadingRedirect)">{{(loadingRedirect) ? 'Carregando ...' : 'Assinar'}}</q-btn>
        </div>
      </div>
    </q-layout>
  </q-dialog>
</template>
<script>
  export default {
    name: 'AssignPlan',
    props: {
      simple: Boolean
    },
    data() {
      return {
        details: false,
        loadingRedirect: false
      }
    },
    methods: {
      async assignPlan () {
        this.loadingRedirect = true;
        let response = await this.$store.dispatch('auth/assignPlan')
        setTimeout(() => {
          this.loadingRedirect = false;
        }, 4000);
        if (response == 'login') {
          this.loadingRedirect = false;
          this.$q.notify({
            color: 'purple',
            textColor: 'white',
            icon: 'warning',
            message: 'Acesse sua conta para assinar o plano'
          })
        }
      },
      openDetails() {
        this.details = true;
      }
    }
  }
</script>
<style scoped lang="scss">
  .assignPlan {
    .head {
      width: 236px;
      font-size: 20px;
      font-family: "Space Mono", monospace;
      padding-top: 5px;
      text-align: center;
      font-weight: 600;
      color: #fff;
      margin: 5px;
    }
  }
  .dialog {
    h5, h3 {
      text-align: center;
      
    }
    
    h5, h3, ul li {
      font-family: 'Space Mono';
    }
  }
  .assignBtn {
    margin: auto;
    display: block;
    font-size: 20px;
    width: 180px;
    border: 4px solid;
    font-weight: bold !important;
  }
  
</style>