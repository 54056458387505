<template>
  <div class="details">
    <h4 class="text-center">Colina</h4>
    <h5>Funções da Colina</h5>
    <p>
      A colina é uma vitamina do complexo B, mais precisamente B8.
      <br/>
      Colina é um nutriente essencial que cumpre várias funções no organismo, e está diretamente relacionada à função cerebral, pois é precursora da acetilcolina. Essa substância química intervém diretamente na transmissão dos impulsos nervosos, acelerando a produção e a liberação de neurotransmissores, o que contribui para a memória e para o aprendizado.
    </p>
    <h5>Alimentos fonte de COLINA</h5>
    <p>Quinoa, amêndoas, linhaça, alga wakame, gergelim, alho, brócolis e couve flor.</p>
  </div>
</template>
