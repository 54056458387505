<template>
  <div class="details">
    <h4 class="text-center">Ferro</h4>
    <h5>Mineral realiza o transporte de oxigênio e gás carbônico.</h5><br/>
    <p>
      O ferro está presente na formação da hemoglobina do sangue e é responsável pelo armazenamento e distribuição de oxigênio e gás carbônico para todo o corpo ao longo da rede sanguínea. Ele também participa da síntese do DNA, do RNA, do colágeno, dos neurotransmissores e na conversão do betacaroteno em vitamina A. O mineral atua ainda como cofator na atuação de diversas enzimas, auxiliando no funcionamento das células.<br/>
      A quantidade ideal de ferro indicada para os homens é de 10 mg por dia e 18mg para as mulheres. Entre as principais fontes do mineral está o feijão. Em 100 gramas de feijão fornece duas miligramas de ferro.<br/>
      Mesmo não sendo difícil suprir as necessidades diárias de ferro, cerca de 25% da população mundial sofre de carência do mineral. O simples consumo de ferro não é garantia de que ele será absorvido, somente de 10 a 15% do ferro ingerido é retido pelo corpo. Para aumentar os níveis de absorção é recomendado ingerir também vitamina C e proteínas e evitar comer fontes de cálcio, cobre e zinco na mesma refeição dos alimentos ricos em ferro. Além disso, perdas excessivas de sangue também podem causar deficiência de ferro.
    </p>
    <h5>Alimentos ricos em Ferro</h5>
    <p>Feijão preto, espinafre, brócolis, couve, lentilha, ervilha, grão de bico, semente de abóbora, semente de girassol, algas marinhas.</p>
    <h5>EFEITOS DA DEFICIÊNCIA E EXCESSO DE FERRO</h5>
    <p>
      A deficiência de ferro pode causar palidez da pele e das mucosas, fraqueza, fadiga, tontura, falta de apetite, dificuldade no aprendizado, apatia, queda da resistência imunológica e anemia ferropênica. Em excesso o ferro aumenta o risco de doenças cardiovasculares, prejudica o crescimento infantil, agrava o quadro das infecções e produz alterações fisiológicas no fígado e no coração.
    </p>
  </div>
</template>
