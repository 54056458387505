<template>
  <div class="details">
    <h4 class="text-center">VITAMINA B15 (Ácido Pangâmico)</h4>
    <h5>Funções da B15</h5>
    <p>
      Prolonga o tempo médio de vida das células. A vitamina B15 é o principal doador de metil, que contribui para a formação de determinados aminoácidos. Ela pode atuar na oxidação da glicose e na respiração celular.
    </p>
    <h5>Alimentos com B15</h5>
    <p>Arroz integral, os grãos, os cereais integrais, semente de abóbora, a semente de gergelim e a semente de sésamo.</p>
  </div>
</template>
