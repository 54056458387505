import tone1 from '../assets/images/tones/borded/tone1.png'
import tone2 from '../assets/images/tones/borded/tone2.png'
import tone3 from '../assets/images/tones/borded/tone3.png'
import tone4 from '../assets/images/tones/borded/tone4.png'
import tone5 from '../assets/images/tones/borded/tone5.png'
import tone6 from '../assets/images/tones/borded/tone6.png'
import tone7 from '../assets/images/tones/borded/tone7.png'
import tone8 from '../assets/images/tones/borded/tone8.png'
import tone9 from '../assets/images/tones/borded/tone9.png'
import tone10 from '../assets/images/tones/borded/tone10.png'
import tone11 from '../assets/images/tones/borded/tone11.png'
import tone12 from '../assets/images/tones/borded/tone12.png'
import tone13 from '../assets/images/tones/borded/tone13.png'

import tone1sm from '../assets/images/tones/small/tone1.png'
import tone2sm from '../assets/images/tones/small/tone2.png'
import tone3sm from '../assets/images/tones/small/tone3.png'
import tone4sm from '../assets/images/tones/small/tone4.png'
import tone5sm from '../assets/images/tones/small/tone5.png'
import tone6sm from '../assets/images/tones/small/tone6.png'
import tone7sm from '../assets/images/tones/small/tone7.png'
import tone8sm from '../assets/images/tones/small/tone8.png'
import tone9sm from '../assets/images/tones/small/tone9.png'
import tone10sm from '../assets/images/tones/small/tone10.png'
import tone11sm from '../assets/images/tones/small/tone11.png'
import tone12sm from '../assets/images/tones/small/tone12.png'
import tone13sm from '../assets/images/tones/small/tone13.png'

export default [
  {
    key: 1,
    name: 'Magnético',
    power: 'Unifico',
    action: 'Atrai',
    essence: 'Propósito',
    question: 'Qual é meu propósito?',
    description: 'Unifica o propósito, atrai e inicia a execução de alguma meta. (Tom do propósito e da Vibração da Unidade e da Atração. Atraia tudo de que necessita para unificar-se com o seu propósito. Quando identificar o seu propósito e unir-se totalmente a ele, abrirá o caminho para que forças naturais o apoiem. Seja receptivo e receba bem todas as pessoas e coisas que alimentem e dêen oider a seu propósito).',
    image: tone1,
    imageSm: tone1sm,
    foods: {
      type: 'Sementes',
      list: {
        0: 'CHIA',
        1: 'LINHAÇA',
        2: 'ROMÃ',
        3: 'ERVA DOCE',
        4: 'ABÓBORA',
        5: 'DAMASCO',
        6: 'GERGELIM',
        7: 'GIRASSOL',
        8: 'COMINHO'
      }
    }
  },
  {
    key: 2,
    name: 'Lunar',
    power: 'Polarizo',
    action: 'Estabiliza',
    essence: 'Desafio',
    question: 'Qual é meu desafio?',
    description: 'Identifica o desafio, estabiliza, polariza, distingue. (Tom do Desafio e da Vibração da Polaridade e da Estabilidade. O Desafio é algo que o fortalece e que expande o seu potencial e a sua espiritualidade. Não é um problema. Precisamos crescer para vencer os obstáculos. É um dom do universo para que estabilize seu verdadeiro propósito e fortaleça as partes profundas de si mesmo. Aprenda com a polaridade. Agradeça a visão e a consciência que a polaridade traz a sua vida).',
    image: tone2,
    imageSm: tone2sm,
    foods: {
      type: 'Frutas secas e castanhas',
      list: {
        0: 'AMÊNDOAS',
        1: 'NOZES',
        2: 'CASTANHA DE CAJU',
        3: 'CASTANHAS',
        4: 'AVELÃ',
        5: 'MACADÂMIA',
        6: 'AMENDOIM',
        7: 'PINHÕES',
        8: 'PISTACHES',
        9: 'DAMASCO',
        10: 'UVA PASSA',
        11: 'TAMARA',
        12: 'AMEIXA',
        13: 'GOJI'
      }
    }
  },
  {
    key: 3,
    name: 'Elétrico',
    power: 'Ativo',
    action: 'Vincula',
    essence: 'Serviço',
    question: 'Qual é minha melhor maneira de servir?',
    description: 'Ativa e identifica o serviço, une as ações, cumpre a meta. (Tom do Serviço e da Vibração do Ritmo, Ativação e Ligação. Serviço é a expressão da nossa gratidão por viver e aprender neste planeta. Significa ajudar e apoiar os outros da forma que só nós podemos fazer. Amor, louvor e gratidão são as mais elevadas formas de serviço e despertam a energia criativa em nós. Ativa o ritmo natural de seu serviço diário. Tenha consciência de quando está servindo à luz e ao Coração Único).',
    image: tone3,
    imageSm: tone3sm,
    foods: {
      type: 'Adoçante natural',
      list: {
        0: 'MEL DE ABELHA',
        1: 'MEL DE CANA',
        2: 'ESTÉVIA',
        3: 'XAROPE DE AGAVE',
        4: 'MELAÇO',
        5: 'AÇÚCAR DE COCO',
        6: 'XILINTOL',
        7: 'ERITRITOL'
      }
    }
  },
  {
    key: 4,
    name: 'Autoexistente',
    power: 'Defino',
    action: 'Medir',
    essence: 'Forma',
    question: 'Qual é minha forma de agir?',
    description: 'Define a forma de atuação, toma as medidas, decide. (Tom da Forma e da Vibração da Definição, da Medida e da Ordem. A forma ideal emerge quando entregamos nosso propósito e desejos ao Plano Maior e nos tornamos receptivos e confiantes em relação à forma que é canalizada através de nós. Use o poder da definição para ver, compreender e relacionar-se com a vida mais autenticamente. A medida lhe permite ver a dimensão das coisas e lhe dá poder de discernimento, para que manifeste seu alinhamento correto).',
    image: tone4,
    imageSm: tone4sm,
    foods: {
      type: 'Tuberculos/Raízes',
      list: {
        0: 'ABÓBORA',
        1: 'BATATA DOCE',
        2: 'BATATA INGLESA',
        3: 'BETERRABA',
        4: 'BATATA YACON',
        5: 'GRÃO DE BICO',
        6: 'CENOURA',
        7: 'CARÁ',
        8: 'GENGIBRE',
        9: 'INHAME',
        10: 'MANDIOCA'
      }
    }
  },
  {
    key: 5,
    name: 'Harmonico',
    power: 'Potencializo',
    action: 'Comanda',
    essence: 'Radiação',
    question: 'Como domino minha vida?',
    description: 'Potencializa a radiação, comanda e lidera. Reúne recursos, brilha. Ordena sem impor. (Tom da Radiação e da Vibração do Centro, que recebe Poder e toma o Comando. Radiação é a qualidade de irradiar a partir do centro, expandindo a própria essência e energia para que possa ser percebida de longe. Dê poder a sua radiação sendo fiel a seu centro. Ela cria um efeito de onda, causando impacto em todos com quem você tiver contato e influenciando o mundo. Estando intimamente ligado a si mesmo irá tornar-se uma força invencivel, capaz de tomar o comando e de criar muitos recursos).',
    image: tone5,
    imageSm: tone5sm,
    foods: {
      type: 'Gorduras insaturadas/Azeites',
      list: {
        0: 'AZEITE DE OLIVA',
        1: 'AZEITE DE COCO',
        2: 'MANTEIGA DE CACAU',
        3: 'ÓLEO DE LINHAÇA',
        4: 'MANTEIGA DE CASTANHAS',
        5: 'PASTA DE AMENDOIM'
      }
    }
  },
  {
    key: 6,
    name: 'Ritmico',
    power: 'Organizo',
    action: 'Equilibra',
    essence: 'Igualdade',
    question: 'O que preciso para me equilibrar e organizar?',
    description: 'Organiza a igualdade, equilibra, modera as dificuldades, administra o desafio. (Tom da igualdade e Vibração do Equilíbrio orgânico. Igualdade significa equilíbrio e proporções iguais. É preciso consciência e compromisso para criar igualdade e equilíbrio. Equilíbrio orgânico significa que a vida está sempre crescendo, evoluindo e transformando. Portanto, é preciso estar sempre equilibrando as coisas. Então tudo flui com eficiência, sem esforço, pacifica e harmoniosamente. Trabalhe com sua mente para nutri-la com energias que a equilibrem, levando-a a um estado de cooperação com o espírito).',
    image: tone6,
    imageSm: tone6sm,
    foods: {
      type: 'Frutas ácidas ou semi-ácidas',
      list: {
        0: {
          title: 'As frutas ácidas são:',
          list: {
            0: 'MORANGO',
            1: 'GOIABA',
            2: 'LIMÃO',
            3: 'AMORAS',
            4: 'FRAMBOESA',
            5: 'MIRTILO',
            6: 'ABACAXI',
            7: 'LARANJA',
            8: 'AMORA',
            9: 'TANGERINA',
            10: 'CUPUAÇU'
          }
        },
        1: {
          title: 'As frutas semi-ácidas são:',
          list: {
            0: 'AMEIXA',
            1: 'KIWI',
            2: 'NÊSPERA',
            3: 'UVAS VERDES',
            4: 'CAQUI',
            5: 'MAÇÃ VERDE',
            6: 'GOIABA',
            7: 'MARACUJÁ',
            8: 'CARAMBOLA',
            9: 'PERA'
          }
        }
      }
    }
  },
  {
    key: 7,
    name: 'Ressonante',
    power: 'Canalizo',
    action: 'Inspira',
    essence: 'Harmonização',
    question: 'Como posso harmonizar meu serviço com os demais?',
    description: 'Canaliza a harmonização. Motiva, busca eficiência, inspira, cria, sintoniza serviço e ação. (Tom da Harmonização, da Vibração, da Inspiração, da Canalização e do Poder Místico. Harmonização significa estar alinhado com as vibrações desejadas. Harmonize-se com seu Eu superior para ajustar-se e entrar em harmonia. Quanto mais sintonizar-se com sua natureza superior, mais fácil se tornará canalizar informações, energias e formas. Preste atenção à ressonância de pessoas, lugares, situações, pensamentos, emoções, conversas e veja com o que se sintoniza, para perceber o que alimenta seu espírito. Deixe todo o resto de lado).',
    image: tone7,
    imageSm: tone7sm,
    foods: {
      type: 'Vegetais com sementes',
      list: {
        0: 'TOMATES',
        1: 'PIMENTAS',
        2: 'PEPINOS',
        3: 'BERINGELAS',
        4: 'ERVILHAS',
        5: 'MILHO',
        6: 'PIMENTÕES'
      }
    }
  },
  {
    key: 8,
    name: 'Galáctico',
    power: 'Harmonizo',
    action: 'Modela',
    essence: 'Integridade',
    question: 'Como vivo o que acredito?',
    description: 'Harmoniza a integridade, modela a forma. A ação toma forma. (Tom da Integridade, da Vibração, da Harmonização e do Modelo. Integridade é decorrente do conhecimento de si mesmo e de um senso de reverência por toda a vida. Significa fazer o melhor, mas também aceitar suas imperfeições humanas, harmonizando os dois aspectos. Desenvolva uma relação de integridade com seu espírito - comprometa-se a viver sua verdade e será um modelo para os outros).',
    image: tone8,
    imageSm: tone8sm,
    foods: {
      type: 'Frutas doce',
      list: {
        0: 'ROMÃ',
        1: 'KIWI',
        2: 'LARANJA',
        3: 'MAÇÃ',
        4: 'PAPAIA',
        5: 'UVAS',
        6: 'BANANA',
        7: 'ABACATE',
        8: 'CEREJAS',
        9: 'GOJI',
        10: 'FIGO',
        11: 'LICHIA',
        12: 'CAQUI',
        13: 'MANGA'
      }
    }
  },
  {
    key: 9,
    name: 'Solar',
    power: 'Pulso',
    action: 'Realiza',
    essence: 'Intenção',
    question: 'Como atingir meu propósito?',
    description: 'Formaliza a intenção. A finalidade da ação é posta em movimento. (Tom da Intenção e Vibração da Realização e Mobilição. A intenção é o combustível que motiva e cria todas as manifestações. Desenvolva a força de suas intenções, pois o único bloqueio à manifestação é não ter uma intenção clara, não ter desejos claros. Seja claro a respeito de onde vem e para onde vai, depois aja de modo a chegar lá, com suas intenções como guias. Determine coisas específicas que pode fazer para realizar as intenções de seu Eu Místico).',
    image: tone9,
    imageSm: tone9sm,
    foods: {
      type: 'Germinado',
      list: {
        0: 'ALFAFA',
        1: 'AGRIÃO',
        2: 'Grão de bico',
        3: 'ERVILHAS VERDES',
        4: 'Lentilhas',
        5: 'SOJA VERDE',
        6: 'TRIGO',
        7: 'BROCOLIS GERMINADO',
        8: 'MOSTARDA GERMINADA',
        9: 'CEBOLA GERMINADA',
        10: 'FEIJÃO GERMINADO',
        11: 'GIRASSOL GERMINADO',
        12: 'REPOLHO VERMELHO GERMINADO',
        13: 'ERVILHAS GERMINADAS',
        14: 'ALFACE GERMINADA',
        15: 'FEIJÃO AZUKI'
      }
    }
  },
  {
    key: 10,
    name: 'Planetário',
    power: 'Aperfeiçoo',
    action: 'Produz',
    essence: 'Manifestação',
    question: 'Como aperfeiçoo o que faço?',
    description: 'Aperfeiçoa a manifestação do desafio. Enfrenta os caminhos e vence os obstáculos. (Tom da Manifestação e Vibração da Produção e Aperfeiçoamento. Manifestações ocorrem a todo momento. O importante é ser um manifestador consciente. Com consciência você pode acessar a liberdade e o poder para expressar suas capacidades plenas. Manifeste o que plenifica, o que alimenta sua alma e lhe dá uma sensação de sucesso, satisfação e auto-amor. Abra-se para receber o apoio do Universo a fim de criar abundância e aperfeiçoas suas manifestações).',
    image: tone10,
    imageSm: tone10sm,
    foods: {
      type: 'Folhas/Flores',
      list: {
        0: 'ESPINAFRE',
        1: 'ACELGA',
        2: 'BRÓCOLIS',
        3: 'SALSÃO',
        4: 'AIPO',
        5: 'Repolho / Couve ',
        6: 'DENTE DE LEÃO',
        7: 'ESCAROLA',
        8: 'FOLHAS DE RABANETES',
        9: 'VERDES DE BETERRABA',
        10: 'FOLHAS DE CENOURA',
        11: 'ALFACE',
        12: 'SALSINHA',
        13: 'ALHO-PORÓ',
        14: 'AGRIÃO',
        15: 'CHICÓRIA',
        16: 'RÚCULA'
      }
    }
  },
  {
    key: 11,
    name: 'Espectral',
    power: 'Dissolvo',
    action: 'Libera',
    essence: 'Libertação',
    question: 'Como posso me liberar e deixar ir?',
    description: 'Dissolve. Ocasiona a liberação do serviço, da ação. Divulga. Realiza. Não requer ação. (Tom da liberação e Vibração do Abrir Mão, Deixar Ir e Dissolver. Liberte-se de todas as fronteiras, crenças, estruturas e limitações. Seja verdadeiramente livre. Traga a energia da liberação a todas as áreas de sua vida que precisam ser preenchidas com liberdade. Acredite que tudo é possivel, que você existe em um Universo ilimitado. Dissolva todas as formas-pensamento de derrota e hábitos que lhe tirem o poder. Deixe ir ou abra mão de qualquer coisa que o impeça de fazer brilhar sua Luz).',
    image: tone11,
    imageSm: tone11sm,
    foods: {
      type: 'Vegetais',
      list: {
        0: 'Lentilhas',
        1: 'ERVILHAS',
        2: 'FEIJÕES',
        3: 'AMENDOIM',
        4: 'GRÃO DE BICO'
      }
    }
  },
  {
    key: 12,
    name: 'Cristal',
    power: 'Dedico',
    action: 'Universaliza',
    essence: 'Cooperação',
    question: 'Como posso dedicar meu ser a tudo o que existe?',
    description: 'Dedica a cooperação da forma. Sintetiza, universaliza. Evolui e prepara a ação futura. (Tom da Cooperação e da Vibração da Estabilidade Complexa e Dedicação. A cooperação emerge quando você expande a percepção, abre o coração e está disposto a criar o resultado Maior que beneficia a todos. A cooperação é impossivel quando você é rigido ou deseja controlar o resultado. Isso também acontece quando cooperando com o Universo. Coopere com todas as situações da vida, sendo aberto e fluindo. Torne-se um Eu mais expandido. Integre as experiências da vida e os novos horizontes com fluidez).',
    image: tone12,
    imageSm: tone12sm,
    foods: {
      type: null,
      list: {
        0: 'TRIGO INTEGRAL',
        1: 'AVEIA',
        2: 'ARROZ INTEGRAL',
        3: 'CEVADA',
        4: 'CENTEIO INTEGRAL',
        5: 'CUSCUZ',
        6: 'MILHO',
        7: 'QUINOA'
      }
    }
  },
  {
    key: 13,
    name: 'Cósmico',
    power: 'Persevero',
    action: 'Transcende',
    essence: 'Presença',
    question: 'Como posso aumentar minha alegria e meu amor?',
    description: 'Perdura a existência. Transcende. Presença. Estabelece um novo ciclo. (Tom da Presença e Vibração da Perseverança. Transcendência e Voô Magico. Presença é o fio que conecta tudo na vida. A presença da vida é o que todos compartilhamos, a Força Vital Universal que une todas as coisas. A presença do espírito. A presença do amor. A presença do seu Eu Cósmico. Esteja presente em todos os momentos. Rejubile-se na possibilidade de transcender todas as limitações e perseverar através das ondas da vida - estar totalmente presente durante toda a sua duração).',
    image: tone13,
    imageSm: tone13sm,
    foods: {
      type: 'RESPIRAÇÃO SOLAR MAIA',
      list: null,
      description: '(A Emanação Espiritual é emitida pelo Centro da Galáxia).\n\nClique no Holon Humano.\n\nVocê deve trazê-lo ( glifo) diariamente para sua vida, mantendo a consciência desperta de sua Conexão com sua Identidade Espiritual Solar.\n\nRealizar práticas e rituais que permitam que você se comunique com seu eu superior.\n\nAfirmação do dia:\nEleve sua consciência aos planos de luz onde você descobrirá que seu Ser é livre, pleno, cheio de amor, para sempre saudável e eternamente feliz.\n"Todo o meu SER manifesta a glória de Deus.\nO Cristo que habita em mim me liberta.\nAceito que todos os códigos de saúde, vida e beleza estejam estabelecidos em mim, agora e para sempre.\nSubo aos planos da felicidade eterna onde me percebo envolto pelo grande AMOR de Deus."'
    }
  }
]
