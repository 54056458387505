<template>
  <div class="details">
    <h4 class="text-center">CONHEÇA A VITAMINA B6 (Piridoxina)</h4>
    <p>
      As principais fontes de vitamina B6 são: Sementes de girassol, abacate, aveia, espinafre, feijão, batata, banana, tomate, couve, repolho roxo, brócolis, quinoa, couve-flor, agrião, vagem.
      <br/><br/>
      A vitamina B6 pode ser encontrada em numerosos alimentos e a necessidade diária para o organismo é relativamente baixa. Esta vitamina participa de mais funções orgânicas do que qualquer outro nutriente isolado. Muitas reações do metabolismo são dependentes da piridoxina. É importante tanto para a saúde física quanto mental.
      <br/><br/>
      A deficiência da vitamina B6 no organismo pode causar anemia, além de convulsões em crianças pequenas, dermatite, lesões nervosas, confusão mental em adultos, dormência e formigamento nas mãos e pés.
    </p>
  </div>
</template>
