<template>
  <div>
    <div class="kin harmonics">
      <div class="head">Harmônicas do Tzolkin</div>
      <div class="body" v-if="isPro">
        <!-- <div class="flex-center"> -->
          <div class="harmonic">
            <p>{{ harmonic.name }}</p>
            <p>{{ harmonic.affirmation }}</p>
            <br/>
            <p v-if="harmonic.iching == 0">Coluna Mística - Centro do Tzolkin</p>
          </div>
          <div v-if="harmonic.iching > 0">
            <div class="btns" v-if="!isStopCodon">
              <p class="margin-top-15">Letra do Código Genético: {{ harmonic.genCode }}</p>
              <q-btn outline color="white" @click="openAminoacid(harmonic.aminoacid)">Aminoácido: {{ harmonic.aminoacid }}</q-btn>
              <br/>
              <q-btn class="transparent-white mt-1" outline color="white" @click="openHebrewLetter(seal)">
                <span>Letra em Hebraico: <img :src="seal.hebrewImg" width="30"/> {{ seal.hebrew }} </span>
              </q-btn>
              <br/>
              <q-btn class="margin-top-15" outline color="white" @click="openBioCode(harmonic.aminoacid)">O Código da Vida Bioquímico</q-btn>
            </div>
            <div class="margin-top-15 stop-codon" v-else>
              <h5>{{ harmonic.aminoacid }} - Stop Codon</h5>
              <p>Sopro divino a fim de estabelecer intervalos entre os períodos de pausa da Luz divina. </p>
              <q-btn class="transparent-white mb-2" outline color="white" @click="openHebrewLetter(harmonic)">
                <span>Letra em Hebraico: <img :src="harmonic.hebrewImg" width="30"/> {{ harmonic.hebrew }} </span>
              </q-btn>
            </div>
            <q-btn class="transparent-white" outline color="white" @click="openIching(iching[harmonic.iching-1])">
              <div class="iching flex-center">
                <div class="img">
                  <h5>Hexagrama/Códon - {{ harmonic.iching }}</h5>
                  <div class="row">
                    <div class="col-6" v-if="iching.length > 0">
                      <img width="100" height="140" :src="iching[harmonic.iching-1]['img']"/>
                    </div>
                    <div class="col-6">
                      <div class="column">
                        <div class="col-md-6 text-left">
                          <h5 class="margin-bottom-8">{{ harmonic.trigrams[0] }}</h5>
                          <h5>{{ harmonic.trigrams[1] }}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5>{{ harmonic.codon }}</h5>
                  <h6>{{ harmonic.harmonic }}</h6>
                </div>
              </div>
            </q-btn>
            <div class="runa">
              <img width="77" height="77" :src="runa_imgs[harmonic.iching-1]"/>
            </div>
          </div>
        <!-- </div> -->
      </div>
      <div class="body" v-if="!isPro">
        <assign-plan></assign-plan>
      </div>
    </div>
  </div>

  <q-dialog v-model="ichingDialog">
    <q-layout view="lhh LpR lff" container class="dialog">
      <div class="details text-center">
        <img :src="selectedIching.img" height='100'/>
        <h4 class="text-center">{{selectedIching.name}}</h4>
        <p class="text-left">{{selectedIching.desc}}</p>
      </div>
    </q-layout>
  </q-dialog>

  <q-dialog v-model="hebrewLetter">
    <q-layout view="lhh LpR lff" container class="dialog">
      <div class="details text-center">
        <h4 class="text-center">{{selectedHebrewLetter.name}}</h4>
        <img :src="selectedHebrewLetter.img" height='300'/>
        <p>{{selectedHebrewLetter.desc}}</p>
      </div>
    </q-layout>
  </q-dialog>
</template>
<script>
import SealModel from '../../models/seal_model'
import ichings from '../../datas/iching'
import runas from '../../datas/runas'
import AssignPlan from '../AssignPlan.vue'

export default {
  name: 'HarmonicComponent',
  props: {
    harmonic: Object
  },
  data () {
    return {
      iching: ichings,
      runa_imgs: runas,
      selectedIching: {
        name: '',
        desc: '',
        img: ''
      },
      selectedHebrewLetter: {
        name: '',
        img: ''
      },
      hebrewLetter: false,
      ichingDialog: false
    }
  },
  computed: {
    isStopCodon () {
      return (['UGA', 'UAG', 'UAA'].indexOf(this.harmonic.aminoacid) > -1)
    },
    isPro () {
      return this.$store.state.auth.userPro
    },
    seal() {
      var sealM = new SealModel()
      var seal = sealM.getByAminoacid(this.harmonic.aminoacid)
      return seal[0]
    }
  },
  components: {
    AssignPlan
  },
  methods: {
    openAminoacid (aminoacid) {
      this.$store.commit('dialogs/setAminoacid', aminoacid)
    },
    openBioCode (aminoacid) {
      var sealM = new SealModel()
      var seal = sealM.getByAminoacid(aminoacid)
      if (seal.length > 0) {
        this.$store.dispatch('dialogs/setBioCode', seal[0])
      }
    },
    openHebrewLetter (seal) {
      this.selectedHebrewLetter = {
        name: seal.hebrew,
        desc: seal.hebrewDesc,
        img: seal.hebrewImg
      }
      this.hebrewLetter = true
    },
    openIching (ic) {
      this.selectedIching = ic
      this.ichingDialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .kin {
    .head {
      font-size: 22px;
    }
  }

  .btns {
    margin-bottom: 10px;
  }

  .iching {
    padding: 2px;
    color: black;
    .img {
      width: 263px;
      background-color: hsla(0, 0%, 100%, 0.42);
      border-radius: 13px;
    }
  }

  .runa {
    margin-top: 15px;
    img {
      border-radius: 8px;
    }
  }

  .harmonic {
    background: #0000006f;
    padding: 2px;
    border-radius: 20px;
    p {
      font-family: "Concert One", cursive;
    }
  }

  .transparent-white {
    background-color: #ffffff70 !important;
  }

  .margin-bottom-8 {
    margin-bottom: 8px;
  }

  .details p {
    white-space: pre-line;
    text-align: left;
  }
</style>
