<template>
  <div class="kin" v-if="cPsi !== undefined">
    <div class="head">Psi Chrono</div>
    <div class="body">
      <h5>{{cPsi.name}}</h5>
      <div>
        <img :src="cPsi.tone.image" class="tone" width="40"/>
        <br/>
        <img :src="cPsi.seal.image" class="seal" width="40"/>
      </div>
      <br/>
      <q-btn outline color="white" @click="openAminoacid(cPsi.seal.aminoacid)">Aminoácido: {{ cPsi.seal.aminoacid }}</q-btn>
      <br/><br/>
      <q-btn outline color="white" class="transparent-white" @click="openHebrewLetter(cPsi.seal)">
        <span>Letra em Hebraico: <img :src="cPsi.seal.hebrewImg" width="30"/> {{ cPsi.seal.hebrew }} </span>
      </q-btn>
    </div>
  </div>
  <aminoacid-dialogs-component />
  <q-dialog v-model="hebrewLetter">
    <q-layout view="lhh LpR lff" container class="dialog">
      <div class="details text-center">
        <h4 class="text-center">{{selectedHebrewLetter.name}}</h4>
        <img :src="selectedHebrewLetter.img" height='300'/>
        <p>{{selectedHebrewLetter.desc}}</p>
      </div>
    </q-layout>
  </q-dialog>
</template>
<script>
export default {
  name: 'ChronoPsiComponent',
  props: {
    cPsi: Object
  },
  data() {
    return {
      aminoacidDetails: false,
      selectedHebrewLetter: {
        name: '',
        img: ''
      },
      hebrewLetter: false
    }
  },
  methods: {
    openAminoacid (aminoacid) {
      this.$store.commit('dialogs/setAminoacid', aminoacid)
    },
    openHebrewLetter (seal) {
      this.selectedHebrewLetter = {
        name: seal.hebrew,
        desc: seal.hebrewDesc,
        img: seal.hebrewImg
      }
      this.hebrewLetter = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .transparent-white {
    background-color: #ffffff70 !important;
  }
  .details p {
    white-space: pre-line;
    text-align: left;
  }
</style>
