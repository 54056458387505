<template>
  <div class="details">
    <h4 class="text-center">Asparagina</h4><br/>
    <p>
      1. Mantêm as células do sistema nervoso saudáveis <br/>
      2. Contribui para a formação e a manutenção de ossos, pele, unha e cabelos<br/>
      3. Cria dentro do organismo novas proteínas de acordo com a necessidade de cada momento<br/>
      4. Ativa e melhora o funcionamento das células do cérebro<br/>
      As células cancerígenas NÃO conseguem produzir esse aminoácido, mas, alimentam-se dele, pois a <span class="bold">asparagina</span> é um aminoácido produzido pelas células saudáveis do organismo. No tratamento da leucemia, utiliza-se uma enzima (asparaginase) que destrói a asparagina dos alimentos, impedindo assim que as células do câncer se alimentem. A asparagina é um aminoácido não essencial envolvido no controle metabólico das funções celulares nos tecidos nervosos e cerebrais. Tem uma função destacada na atividade cerebral. Colabora na síntese de glicoproteínas. Junto com a vitamina B6, é um precursor do neurotransmissor GABA (ácido gama aminobutírico), cuja ação sedativa sobre o sistema nervoso é importante. A asparagina está envolvida nos processos metabólicos do sistema nervoso central e desempenha um papel importante na síntese de amônia.
    </p>
    <h5>Lista de alimentos ricos em Asparagina</h5>
    <p>
      Quinoa, lentilha, grão de bico, amendoim, ervilha, feijão, linhaça, chia, gergelim, aspargos, cenoura, cebola, aveia, grão integrais, nozes, batatas.
    </p>
  </div>
</template>
