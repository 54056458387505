import tones from './tones'

var months = [
  { key: 1, finalDay: 31 },
  { key: 2, finalDay: 28 },
  { key: 3, finalDay: 31 },
  { key: 4, finalDay: 30 },
  { key: 5, finalDay: 31 },
  { key: 6, finalDay: 30 },
  { key: 7, finalDay: 31 },
  { key: 8, finalDay: 31 },
  { key: 9, finalDay: 30 },
  { key: 10, finalDay: 31 },
  { key: 11, finalDay: 30 },
  { key: 12, finalDay: 31 }
]

export default [
  { tone: tones[0], dayStart: 26, monthStart: months[6], dayFinal: 22, monthFinal: months[7], key: 1, name: 'Lua Magnética do Morcego', nameSm: 'Lua Magnética' },
  { tone: tones[1], dayStart: 23, monthStart: months[7], dayFinal: 19, monthFinal: months[8], key: 2, name: 'Lua Lunar do Escorpião', nameSm: 'Lua Lunar' },
  { tone: tones[2], dayStart: 20, monthStart: months[8], dayFinal: 17, monthFinal: months[9], key: 3, name: 'Lua Elétrica do Veado', nameSm: 'Lua Elétrica' },
  { tone: tones[3], dayStart: 18, monthStart: months[9], dayFinal: 14, monthFinal: months[10], key: 4, name: 'Lua Autoexistente da Coruja', nameSm: 'Lua Auto-existente' },
  { tone: tones[4], dayStart: 15, monthStart: months[10], dayFinal: 12, monthFinal: months[11], key: 5, name: 'Lua Harmonica do Pavão', nameSm: 'Lua Harmonica' },
  { tone: tones[5], dayStart: 13, monthStart: months[11], dayFinal: 9, monthFinal: months[0], key: 6, name: 'Lua Rítmica do Lagarto', nameSm: 'Lua Rítmica' },
  { tone: tones[6], dayStart: 10, monthStart: months[0], dayFinal: 6, monthFinal: months[1], key: 7, name: 'Lua Ressonante do Macaco', nameSm: 'Lua Ressonante' },
  { tone: tones[7], dayStart: 7, monthStart: months[1], dayFinal: 6, monthFinal: months[2], key: 8, name: 'Lua Galáctica do Falcão', nameSm: 'Lua Galáctica' },
  { tone: tones[8], dayStart: 7, monthStart: months[2], dayFinal: 3, monthFinal: months[3], key: 9, name: 'Lua Solar do Jaguar', nameSm: 'Lua Solar' },
  { tone: tones[9], dayStart: 4, monthStart: months[3], dayFinal: 1, monthFinal: months[4], key: 10, name: 'Lua Planetária do Cão', nameSm: 'Lua Planetária' },
  { tone: tones[10], dayStart: 2, monthStart: months[4], dayFinal: 29, monthFinal: months[4], key: 11, name: 'Lua Espectral da Serpente', nameSm: 'Lua Espectral' },
  { tone: tones[11], dayStart: 30, monthStart: months[4], dayFinal: 26, monthFinal: months[5], key: 12, name: 'Lua Cristal do Coelho', nameSm: 'Lua Cristal' },
  { tone: tones[12], dayStart: 27, monthStart: months[5], dayFinal: 24, monthFinal: months[6], key: 13, name: 'Lua Cósmica da Tartaruga', nameSm: 'Lua Cósmica' }
]
