<template>
  <div class="details">
    <h4 class="text-center">Glutamina</h4><br/>
    <p>
      1. Ela pode ser produzida através de outros aminoácidos, podendo então ser encontrado em todo organismo<br/>
      2. Promove e mantem a hipertrofia (melhora a desempenho dos atletas) <br/>
      3. Previne infecções musculares, principalmente em atletas<br/>
      4. Melhora funcionamento dos intestinos (nutriente de reparação)<br/>
      5. Memória e concentração<br/>
      6. Ajuda no tratamento para diarreia, pois equilibra a produção de muco para uma evacuação saudável<br/>
      7. Melhora o metabolismo<br/>
      8. Limita o desejo de açúcar e do álcool <br/>
      9. Ajuda a combater o câncer <br/>
      10. Melhora sintomas de diabetes <br/>
      11. Reforma sistema imune, anticorpos para vírus e bactérias.<br/>
      12. Diverticulite tem ação sobre as paredes do intestino delgado<br/>
      13. Atua na abundância de sangue<br/><br/>
      Dentre suas muitas funções, a <span class="bold">glutamina</span> atua como geradora de energia, construtora e conservadora muscular e, no campo do sistema imune, ela tem importante papel no combate a infecções e beneficia a saúde ou função intestinal através de uma melhor absorção de nutrientes e seu papel como reguladora da permeabilidade/barreira intestinal.
    </p>
    <h5>Suplementação</h5>
    <p>A suplementação de glutamina quando usada pela manhã, em jejum, exerce um papel muito importante no equilíbrio da flora intestinal, sendo muito indicada para fortalecimento do sistema imune e tratamento de disbiose intestinal. A disbiose intestinal é o desequilíbrio da flora bacteriana intestinal, ou seja, as bactérias negativas se sobressaem em relação ás bactérias positivas, e esse quadro causa vários fatores negativos ao organismo como:</p>
    <ul>
      <li>Falha na digestão dos alimentos, resultando em alimentos mal digeridos;</li>
      <li>Fermentação de alimentos mal digeridos no intestino (formação de gases intestinais);</li>
      <li>Diminuição da absorção de nutrientes, gerando carências nutricionais;</li>
      <li>Mal estar, cansaço, náuseas, mal humor, irritação (isso porque as endorfinas, substâncias que geram bem estar são produzidas nos intestinos e, se ele está em disbiose, a produção dessas substâncias também é comprometida)</li>
      <li>Mau funcionamento intestinal: constipação (intestino preso) ou diarreia (intestino solto).</li>
    </ul>
    <p>A alteração da microbiota intestinal pode ser originada por: alimentação inadequada, uso indiscriminado de antibióticos, anti-inflamatórios, uso abusivo de laxantes, excesso de consumo de alimentos processados, consumo de alimentos crus, excesso de exposição a toxinas ambientais, presença de fungos, quadros patológicos, como doenças crônicas, e o estresse. No intuito de melhorar a microbiota intestinal, a suplementação de glutamina contribui diminuindo a translocação bacteriana, possuindo uma ação reparadora na mucosa, estimulando o crescimento das vilosidades intestinais e aumentando a capacidade absortiva. Estudos indicam que a baixa quantidade de glutamina pode ocasionar atrofia das células intestinais, prejuízo na função imunológica e aumento da suscetibilidade a infecções. A glutamina pode ser suplementada pela manhã em jejum para o fortalecimento do sistema imune e melhora do funcionamento intestinal. A dose recomendada pode variar de 5 a 10 gramas.</p>
    <h5>Lista de alimentos ricos em Glutamina</h5>
    <ul>
      <li>Alimentos ricos em proteínas, frutas, nozes e sementes;</li>
      <li>Vegetais, como alface, salsa, couve, rúcula e aloe vera;</li>
      <li>Tâmaras, laranjas, abacaxi e groselhas negras;</li>
      <li>Arroz, cevada, soja, lentilhas, feijão;</li>
      <li>Amendoim, amêndoas, pinhões, avelãs e sementes de girassol.</li>
    </ul>
  </div>
</template>
