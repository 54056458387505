import moons from './moons'
import KinModel from '../models/kin_model'

var kinM = new KinModel()
var kins = kinM.list()

export default [
  { day: 1, kin: kins[0], moon: moons[0] },
  { day: 2, kin: kins[0], moon: moons[0] },
  { day: 3, kin: kins[0], moon: moons[0] },
  { day: 4, kin: kins[19], moon: moons[0] },
  { day: 5, kin: kins[19], moon: moons[0] },
  { day: 6, kin: kins[19], moon: moons[0] },
  { day: 7, kin: kins[1], moon: moons[0] },
  { day: 8, kin: kins[2], moon: moons[0] },
  { day: 9, kin: kins[3], moon: moons[0] },
  { day: 10, kin: kins[4], moon: moons[0] },
  { day: 11, kin: kins[5], moon: moons[0] },
  { day: 12, kin: kins[6], moon: moons[0] },
  { day: 13, kin: kins[7], moon: moons[0] },
  { day: 14, kin: kins[8], moon: moons[0] },
  { day: 15, kin: kins[9], moon: moons[0] },
  { day: 16, kin: kins[10], moon: moons[0] },
  { day: 17, kin: kins[11], moon: moons[0] },
  { day: 18, kin: kins[12], moon: moons[0] },
  { day: 19, kin: kins[13], moon: moons[0] },
  { day: 20, kin: kins[14], moon: moons[0] },
  { day: 21, kin: kins[15], moon: moons[0] },
  { day: 22, kin: kins[16], moon: moons[0] },
  { day: 23, kin: kins[240], moon: moons[0] },
  { day: 24, kin: kins[240], moon: moons[0] },
  { day: 25, kin: kins[240], moon: moons[0] },
  { day: 26, kin: kins[259], moon: moons[0] },
  { day: 27, kin: kins[259], moon: moons[0] },
  { day: 28, kin: kins[259], moon: moons[0] },
  { day: 1, kin: kins[21], moon: moons[1] },
  { day: 2, kin: kins[21], moon: moons[1] },
  { day: 3, kin: kins[21], moon: moons[1] },
  { day: 4, kin: kins[38], moon: moons[1] },
  { day: 5, kin: kins[38], moon: moons[1] },
  { day: 6, kin: kins[38], moon: moons[1] },
  { day: 7, kin: kins[17], moon: moons[1] },
  { day: 8, kin: kins[18], moon: moons[1] },
  { day: 9, kin: kins[20], moon: moons[1] },
  { day: 10, kin: kins[22], moon: moons[1] },
  { day: 11, kin: kins[23], moon: moons[1] },
  { day: 12, kin: kins[24], moon: moons[1] },
  { day: 13, kin: kins[25], moon: moons[1] },
  { day: 14, kin: kins[26], moon: moons[1] },
  { day: 15, kin: kins[27], moon: moons[1] },
  { day: 16, kin: kins[28], moon: moons[1] },
  { day: 17, kin: kins[29], moon: moons[1] },
  { day: 18, kin: kins[30], moon: moons[1] },
  { day: 19, kin: kins[31], moon: moons[1] },
  { day: 20, kin: kins[32], moon: moons[1] },
  { day: 21, kin: kins[33], moon: moons[1] },
  { day: 22, kin: kins[34], moon: moons[1] },
  { day: 23, kin: kins[221], moon: moons[1] },
  { day: 24, kin: kins[221], moon: moons[1] },
  { day: 25, kin: kins[221], moon: moons[1] },
  { day: 26, kin: kins[238], moon: moons[1] },
  { day: 27, kin: kins[238], moon: moons[1] },
  { day: 28, kin: kins[238], moon: moons[1] },
  { day: 1, kin: kins[42], moon: moons[2] },
  { day: 2, kin: kins[42], moon: moons[2] },
  { day: 3, kin: kins[42], moon: moons[2] },
  { day: 4, kin: kins[57], moon: moons[2] },
  { day: 5, kin: kins[57], moon: moons[2] },
  { day: 6, kin: kins[57], moon: moons[2] },
  { day: 7, kin: kins[35], moon: moons[2] },
  { day: 8, kin: kins[36], moon: moons[2] },
  { day: 9, kin: kins[37], moon: moons[2] },
  { day: 10, kin: kins[39], moon: moons[2] },
  { day: 11, kin: kins[40], moon: moons[2] },
  { day: 12, kin: kins[41], moon: moons[2] },
  { day: 13, kin: kins[43], moon: moons[2] },
  { day: 14, kin: kins[44], moon: moons[2] },
  { day: 15, kin: kins[45], moon: moons[2] },
  { day: 16, kin: kins[46], moon: moons[2] },
  { day: 17, kin: kins[47], moon: moons[2] },
  { day: 18, kin: kins[48], moon: moons[2] },
  { day: 19, kin: kins[51], moon: moons[2] },
  { day: 20, kin: kins[52], moon: moons[2] },
  { day: 21, kin: kins[53], moon: moons[2] },
  { day: 22, kin: kins[54], moon: moons[2] },
  { day: 23, kin: kins[202], moon: moons[2] },
  { day: 24, kin: kins[202], moon: moons[2] },
  { day: 25, kin: kins[202], moon: moons[2] },
  { day: 26, kin: kins[217], moon: moons[2] },
  { day: 27, kin: kins[217], moon: moons[2] },
  { day: 28, kin: kins[217], moon: moons[2] },
  { day: 1, kin: kins[49], moon: moons[3] },
  { day: 2, kin: kins[49], moon: moons[3] },
  { day: 3, kin: kins[49], moon: moons[3] },
  { day: 4, kin: kins[50], moon: moons[3] },
  { day: 5, kin: kins[50], moon: moons[3] },
  { day: 6, kin: kins[50], moon: moons[3] },
  { day: 7, kin: kins[55], moon: moons[3] },
  { day: 8, kin: kins[56], moon: moons[3] },
  { day: 9, kin: kins[58], moon: moons[3] },
  { day: 10, kin: kins[59], moon: moons[3] },
  { day: 11, kin: kins[60], moon: moons[3] },
  { day: 12, kin: kins[61], moon: moons[3] },
  { day: 13, kin: kins[62], moon: moons[3] },
  { day: 14, kin: kins[64], moon: moons[3] },
  { day: 15, kin: kins[65], moon: moons[3] },
  { day: 16, kin: kins[66], moon: moons[3] },
  { day: 17, kin: kins[67], moon: moons[3] },
  { day: 18, kin: kins[69], moon: moons[3] },
  { day: 19, kin: kins[70], moon: moons[3] },
  { day: 20, kin: kins[72], moon: moons[3] },
  { day: 21, kin: kins[73], moon: moons[3] },
  { day: 22, kin: kins[74], moon: moons[3] },
  { day: 23, kin: kins[209], moon: moons[3] },
  { day: 24, kin: kins[209], moon: moons[3] },
  { day: 25, kin: kins[209], moon: moons[3] },
  { day: 26, kin: kins[210], moon: moons[3] },
  { day: 27, kin: kins[210], moon: moons[3] },
  { day: 28, kin: kins[210], moon: moons[3] },
  { day: 1, kin: kins[63], moon: moons[4] },
  { day: 2, kin: kins[63], moon: moons[4] },
  { day: 3, kin: kins[63], moon: moons[4] },
  { day: 4, kin: kins[76], moon: moons[4] },
  { day: 5, kin: kins[76], moon: moons[4] },
  { day: 6, kin: kins[76], moon: moons[4] },
  { day: 7, kin: kins[75], moon: moons[4] },
  { day: 8, kin: kins[77], moon: moons[4] },
  { day: 9, kin: kins[78], moon: moons[4] },
  { day: 10, kin: kins[79], moon: moons[4] },
  { day: 11, kin: kins[80], moon: moons[4] },
  { day: 12, kin: kins[81], moon: moons[4] },
  { day: 13, kin: kins[82], moon: moons[4] },
  { day: 14, kin: kins[83], moon: moons[4] },
  { day: 15, kin: kins[85], moon: moons[4] },
  { day: 16, kin: kins[86], moon: moons[4] },
  { day: 17, kin: kins[88], moon: moons[4] },
  { day: 18, kin: kins[89], moon: moons[4] },
  { day: 19, kin: kins[90], moon: moons[4] },
  { day: 20, kin: kins[91], moon: moons[4] },
  { day: 21, kin: kins[93], moon: moons[4] },
  { day: 22, kin: kins[94], moon: moons[4] },
  { day: 23, kin: kins[183], moon: moons[4] },
  { day: 24, kin: kins[183], moon: moons[4] },
  { day: 25, kin: kins[183], moon: moons[4] },
  { day: 26, kin: kins[196], moon: moons[4] },
  { day: 27, kin: kins[196], moon: moons[4] },
  { day: 28, kin: kins[196], moon: moons[4] },
  { day: 1, kin: kins[68], moon: moons[5] },
  { day: 2, kin: kins[68], moon: moons[5] },
  { day: 3, kin: kins[68], moon: moons[5] },
  { day: 4, kin: kins[71], moon: moons[5] },
  { day: 5, kin: kins[71], moon: moons[5] },
  { day: 6, kin: kins[71], moon: moons[5] },
  { day: 7, kin: kins[96], moon: moons[5] },
  { day: 8, kin: kins[97], moon: moons[5] },
  { day: 9, kin: kins[98], moon: moons[5] },
  { day: 10, kin: kins[99], moon: moons[5] },
  { day: 11, kin: kins[100], moon: moons[5] },
  { day: 12, kin: kins[101], moon: moons[5] },
  { day: 13, kin: kins[102], moon: moons[5] },
  { day: 14, kin: kins[103], moon: moons[5] },
  { day: 15, kin: kins[104], moon: moons[5] },
  { day: 16, kin: kins[115], moon: moons[5] },
  { day: 17, kin: kins[116], moon: moons[5] },
  { day: 18, kin: kins[117], moon: moons[5] },
  { day: 19, kin: kins[118], moon: moons[5] },
  { day: 20, kin: kins[119], moon: moons[5] },
  { day: 21, kin: kins[120], moon: moons[5] },
  { day: 22, kin: kins[121], moon: moons[5] },
  { day: 23, kin: kins[188], moon: moons[5] },
  { day: 24, kin: kins[188], moon: moons[5] },
  { day: 25, kin: kins[188], moon: moons[5] },
  { day: 26, kin: kins[191], moon: moons[5] },
  { day: 27, kin: kins[191], moon: moons[5] },
  { day: 28, kin: kins[191], moon: moons[5] },
  { day: 1, kin: kins[84], moon: moons[6] },
  { day: 2, kin: kins[84], moon: moons[6] },
  { day: 3, kin: kins[84], moon: moons[6] },
  { day: 4, kin: kins[95], moon: moons[6] },
  { day: 5, kin: kins[95], moon: moons[6] },
  { day: 6, kin: kins[95], moon: moons[6] },
  { day: 7, kin: kins[122], moon: moons[6] },
  { day: 8, kin: kins[123], moon: moons[6] },
  { day: 9, kin: kins[124], moon: moons[6] },
  { day: 10, kin: kins[125], moon: moons[6] },
  { day: 11, kin: kins[126], moon: moons[6] },
  { day: 12, kin: kins[127], moon: moons[6] },
  { day: 13, kin: kins[128], moon: moons[6] },
  { day: 14, kin: kins[129], moon: moons[6] },
  { day: 15, kin: kins[130], moon: moons[6] },
  { day: 16, kin: kins[131], moon: moons[6] },
  { day: 17, kin: kins[132], moon: moons[6] },
  { day: 18, kin: kins[133], moon: moons[6] },
  { day: 19, kin: kins[134], moon: moons[6] },
  { day: 20, kin: kins[135], moon: moons[6] },
  { day: 21, kin: kins[136], moon: moons[6] },
  { day: 22, kin: kins[137], moon: moons[6] },
  { day: 23, kin: kins[164], moon: moons[6] },
  { day: 24, kin: kins[164], moon: moons[6] },
  { day: 25, kin: kins[164], moon: moons[6] },
  { day: 26, kin: kins[175], moon: moons[6] },
  { day: 27, kin: kins[175], moon: moons[6] },
  { day: 28, kin: kins[175], moon: moons[6] },
  { day: 1, kin: kins[87], moon: moons[7] },
  { day: 2, kin: kins[87], moon: moons[7] },
  { day: 3, kin: kins[87], moon: moons[7] },
  { day: 4, kin: kins[92], moon: moons[7] },
  { day: 5, kin: kins[92], moon: moons[7] },
  { day: 6, kin: kins[92], moon: moons[7] },
  { day: 7, kin: kins[138], moon: moons[7] },
  { day: 8, kin: kins[139], moon: moons[7] },
  { day: 9, kin: kins[140], moon: moons[7] },
  { day: 10, kin: kins[141], moon: moons[7] },
  { day: 11, kin: kins[142], moon: moons[7] },
  { day: 12, kin: kins[143], moon: moons[7] },
  { day: 13, kin: kins[144], moon: moons[7] },
  { day: 14, kin: kins[155], moon: moons[7] },
  { day: 15, kin: kins[156], moon: moons[7] },
  { day: 16, kin: kins[157], moon: moons[7] },
  { day: 17, kin: kins[158], moon: moons[7] },
  { day: 18, kin: kins[159], moon: moons[7] },
  { day: 19, kin: kins[160], moon: moons[7] },
  { day: 20, kin: kins[161], moon: moons[7] },
  { day: 21, kin: kins[162], moon: moons[7] },
  { day: 22, kin: kins[163], moon: moons[7] },
  { day: 23, kin: kins[167], moon: moons[7] },
  { day: 24, kin: kins[167], moon: moons[7] },
  { day: 25, kin: kins[167], moon: moons[7] },
  { day: 26, kin: kins[172], moon: moons[7] },
  { day: 27, kin: kins[172], moon: moons[7] },
  { day: 28, kin: kins[172], moon: moons[7] },
  { day: 1, kin: kins[105], moon: moons[8] },
  { day: 2, kin: kins[105], moon: moons[8] },
  { day: 3, kin: kins[105], moon: moons[8] },
  { day: 4, kin: kins[114], moon: moons[8] },
  { day: 5, kin: kins[114], moon: moons[8] },
  { day: 6, kin: kins[114], moon: moons[8] },
  { day: 7, kin: kins[165], moon: moons[8] },
  { day: 8, kin: kins[166], moon: moons[8] },
  { day: 9, kin: kins[168], moon: moons[8] },
  { day: 10, kin: kins[169], moon: moons[8] },
  { day: 11, kin: kins[170], moon: moons[8] },
  { day: 12, kin: kins[171], moon: moons[8] },
  { day: 13, kin: kins[173], moon: moons[8] },
  { day: 14, kin: kins[174], moon: moons[8] },
  { day: 15, kin: kins[176], moon: moons[8] },
  { day: 16, kin: kins[177], moon: moons[8] },
  { day: 17, kin: kins[178], moon: moons[8] },
  { day: 18, kin: kins[179], moon: moons[8] },
  { day: 19, kin: kins[180], moon: moons[8] },
  { day: 20, kin: kins[181], moon: moons[8] },
  { day: 21, kin: kins[182], moon: moons[8] },
  { day: 22, kin: kins[184], moon: moons[8] },
  { day: 23, kin: kins[145], moon: moons[8] },
  { day: 24, kin: kins[145], moon: moons[8] },
  { day: 25, kin: kins[145], moon: moons[8] },
  { day: 26, kin: kins[154], moon: moons[8] },
  { day: 27, kin: kins[154], moon: moons[8] },
  { day: 28, kin: kins[154], moon: moons[8] },
  { day: 1, kin: kins[106], moon: moons[9] },
  { day: 2, kin: kins[106], moon: moons[9] },
  { day: 3, kin: kins[106], moon: moons[9] },
  { day: 4, kin: kins[113], moon: moons[9] },
  { day: 5, kin: kins[113], moon: moons[9] },
  { day: 6, kin: kins[113], moon: moons[9] },
  { day: 7, kin: kins[185], moon: moons[9] },
  { day: 8, kin: kins[186], moon: moons[9] },
  { day: 9, kin: kins[187], moon: moons[9] },
  { day: 10, kin: kins[189], moon: moons[9] },
  { day: 11, kin: kins[190], moon: moons[9] },
  { day: 12, kin: kins[192], moon: moons[9] },
  { day: 13, kin: kins[193], moon: moons[9] },
  { day: 14, kin: kins[194], moon: moons[9] },
  { day: 15, kin: kins[195], moon: moons[9] },
  { day: 16, kin: kins[197], moon: moons[9] },
  { day: 17, kin: kins[198], moon: moons[9] },
  { day: 18, kin: kins[199], moon: moons[9] },
  { day: 19, kin: kins[200], moon: moons[9] },
  { day: 20, kin: kins[201], moon: moons[9] },
  { day: 21, kin: kins[203], moon: moons[9] },
  { day: 22, kin: kins[204], moon: moons[9] },
  { day: 23, kin: kins[146], moon: moons[9] },
  { day: 24, kin: kins[146], moon: moons[9] },
  { day: 25, kin: kins[146], moon: moons[9] },
  { day: 26, kin: kins[153], moon: moons[9] },
  { day: 27, kin: kins[153], moon: moons[9] },
  { day: 28, kin: kins[153], moon: moons[9] },
  { day: 1, kin: kins[107], moon: moons[10] },
  { day: 2, kin: kins[107], moon: moons[10] },
  { day: 3, kin: kins[107], moon: moons[10] },
  { day: 4, kin: kins[112], moon: moons[10] },
  { day: 5, kin: kins[112], moon: moons[10] },
  { day: 6, kin: kins[112], moon: moons[10] },
  { day: 7, kin: kins[205], moon: moons[10] },
  { day: 8, kin: kins[206], moon: moons[10] },
  { day: 9, kin: kins[207], moon: moons[10] },
  { day: 10, kin: kins[208], moon: moons[10] },
  { day: 11, kin: kins[211], moon: moons[10] },
  { day: 12, kin: kins[212], moon: moons[10] },
  { day: 13, kin: kins[213], moon: moons[10] },
  { day: 14, kin: kins[214], moon: moons[10] },
  { day: 15, kin: kins[215], moon: moons[10] },
  { day: 16, kin: kins[216], moon: moons[10] },
  { day: 17, kin: kins[218], moon: moons[10] },
  { day: 18, kin: kins[219], moon: moons[10] },
  { day: 19, kin: kins[220], moon: moons[10] },
  { day: 20, kin: kins[222], moon: moons[10] },
  { day: 21, kin: kins[223], moon: moons[10] },
  { day: 22, kin: kins[224], moon: moons[10] },
  { day: 23, kin: kins[147], moon: moons[10] },
  { day: 24, kin: kins[147], moon: moons[10] },
  { day: 25, kin: kins[147], moon: moons[10] },
  { day: 26, kin: kins[152], moon: moons[10] },
  { day: 27, kin: kins[152], moon: moons[10] },
  { day: 28, kin: kins[152], moon: moons[10] },
  { day: 1, kin: kins[108], moon: moons[11] },
  { day: 2, kin: kins[108], moon: moons[11] },
  { day: 3, kin: kins[108], moon: moons[11] },
  { day: 4, kin: kins[111], moon: moons[11] },
  { day: 5, kin: kins[111], moon: moons[11] },
  { day: 6, kin: kins[111], moon: moons[11] },
  { day: 7, kin: kins[225], moon: moons[11] },
  { day: 8, kin: kins[226], moon: moons[11] },
  { day: 9, kin: kins[227], moon: moons[11] },
  { day: 10, kin: kins[228], moon: moons[11] },
  { day: 11, kin: kins[229], moon: moons[11] },
  { day: 12, kin: kins[230], moon: moons[11] },
  { day: 13, kin: kins[231], moon: moons[11] },
  { day: 14, kin: kins[232], moon: moons[11] },
  { day: 15, kin: kins[233], moon: moons[11] },
  { day: 16, kin: kins[234], moon: moons[11] },
  { day: 17, kin: kins[235], moon: moons[11] },
  { day: 18, kin: kins[236], moon: moons[11] },
  { day: 19, kin: kins[237], moon: moons[11] },
  { day: 20, kin: kins[239], moon: moons[11] },
  { day: 21, kin: kins[241], moon: moons[11] },
  { day: 22, kin: kins[242], moon: moons[11] },
  { day: 23, kin: kins[148], moon: moons[11] },
  { day: 24, kin: kins[148], moon: moons[11] },
  { day: 25, kin: kins[148], moon: moons[11] },
  { day: 26, kin: kins[151], moon: moons[11] },
  { day: 27, kin: kins[151], moon: moons[11] },
  { day: 28, kin: kins[151], moon: moons[11] },
  { day: 1, kin: kins[109], moon: moons[12] },
  { day: 2, kin: kins[109], moon: moons[12] },
  { day: 3, kin: kins[109], moon: moons[12] },
  { day: 4, kin: kins[110], moon: moons[12] },
  { day: 5, kin: kins[110], moon: moons[12] },
  { day: 6, kin: kins[110], moon: moons[12] },
  { day: 7, kin: kins[243], moon: moons[12] },
  { day: 8, kin: kins[244], moon: moons[12] },
  { day: 9, kin: kins[245], moon: moons[12] },
  { day: 10, kin: kins[246], moon: moons[12] },
  { day: 11, kin: kins[247], moon: moons[12] },
  { day: 12, kin: kins[248], moon: moons[12] },
  { day: 13, kin: kins[249], moon: moons[12] },
  { day: 14, kin: kins[250], moon: moons[12] },
  { day: 15, kin: kins[251], moon: moons[12] },
  { day: 16, kin: kins[252], moon: moons[12] },
  { day: 17, kin: kins[253], moon: moons[12] },
  { day: 18, kin: kins[254], moon: moons[12] },
  { day: 19, kin: kins[255], moon: moons[12] },
  { day: 20, kin: kins[256], moon: moons[12] },
  { day: 21, kin: kins[257], moon: moons[12] },
  { day: 22, kin: kins[258], moon: moons[12] },
  { day: 23, kin: kins[149], moon: moons[12] },
  { day: 24, kin: kins[149], moon: moons[12] },
  { day: 25, kin: kins[149], moon: moons[12] },
  { day: 26, kin: kins[150], moon: moons[12] },
  { day: 27, kin: kins[150], moon: moons[12] },
  { day: 28, kin: kins[150], moon: moons[12] }
]
