<template>
  <q-dialog v-model="isOpen">
    <q-layout view="lhh LpR lff" container class="dialog">
      <div v-if="selected === 'Alanina'">
        <alanina />
      </div>
      <div v-if="selected === 'Arginina'">
        <arginina />
      </div>
      <div v-if="selected === 'Ácido Aspártico'">
        <acido-aspartico />
      </div>
      <div v-if="selected === 'Cisteína'">
        <cisteina />
      </div>
      <div v-if="selected === 'Ácido Glutâmico'">
        <acido-glutamico />
      </div>
      <div v-if="selected === 'Glutamina'">
        <glutamina />
      </div>
      <div v-if="selected === 'Glicina'">
        <glicina />
      </div>
      <div v-if="selected === 'Prolina'">
        <prolina />
      </div>
      <div v-if="selected === 'Serina'">
        <serina />
      </div>
      <div v-if="selected === 'Tirosína'">
        <tirosina />
      </div>
      <div v-if="selected === 'Histidina'">
        <histidina />
      </div>
      <div v-if="selected === 'Isoleucina'">
        <isoleucina />
      </div>
      <div v-if="selected === 'Leucina'">
        <leucina />
      </div>
      <div v-if="selected === 'Lisina'">
        <lisina />
      </div>
      <div v-if="selected === 'Metionina'">
        <metionina />
      </div>
      <div v-if="selected === 'Fenilalanina'">
        <fenilalanina />
      </div>
      <div v-if="selected === 'Treonina'">
        <treonina />
      </div>
      <div v-if="selected === 'Triptofano'">
        <triptofano />
      </div>
      <div v-if="selected === 'Valina'">
        <valina />
      </div>
      <div v-if="selected === 'Asparagina'">
        <asparagina />
      </div>
    </q-layout>
  </q-dialog>
</template>
<script>
import Alanina from '../../components/aminoacids/Alanina.vue'
import Arginina from '../../components/aminoacids/Arginina.vue'
import Asparagina from '../../components/aminoacids/Asparagina.vue'
import AcidoAspartico from '../../components/aminoacids/AcidoAspartico.vue'
import Cisteina from '../../components/aminoacids/Cisteina.vue'
import AcidoGlutamico from '../../components/aminoacids/AcidoGlutamico.vue'
import Glutamina from '../../components/aminoacids/Glutamina.vue'
import Glicina from '../../components/aminoacids/Glicina.vue'
import Prolina from '../../components/aminoacids/Prolina.vue'
import Serina from '../../components/aminoacids/Serina.vue'
import Tirosina from '../../components/aminoacids/Tirosina.vue'
import Histidina from '../../components/aminoacids/Histidina.vue'
import Isoleucina from '../../components/aminoacids/Isoleucina.vue'
import Leucina from '../../components/aminoacids/Leucina.vue'
import Lisina from '../../components/aminoacids/Lisina.vue'
import Metionina from '../../components/aminoacids/Metionina.vue'
import Fenilalanina from '../../components/aminoacids/Fenilalanina.vue'
import Treonina from '../../components/aminoacids/Treonina.vue'
import Triptofano from '../../components/aminoacids/Triptofano.vue'
import Valina from '../../components/aminoacids/Valina.vue'

export default {
  name: 'AminoacidDialogsComponent',
  computed: {
    isOpen: {
      get () {
        return this.$store.state.dialogs.aminoacids.open
      },
      set (newValue) {
        return this.$store.commit('dialogs/setAminoacid', newValue)
      }
    },
    selected () {
      return this.$store.state.dialogs.aminoacids.selected
    }
  },
  components: {
    Alanina,
    Arginina,
    AcidoAspartico,
    Cisteina,
    AcidoGlutamico,
    Histidina,
    Glutamina,
    Glicina,
    Prolina,
    Serina,
    Tirosina,
    Isoleucina,
    Leucina,
    Lisina,
    Metionina,
    Fenilalanina,
    Treonina,
    Triptofano,
    Valina,
    Asparagina
  },
  methods: {}
}

</script>
