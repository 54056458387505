import {
  listMoonGoal,
  saveMoonGoal
} from '../database/goals'
import moment from 'moment'
import KinModel from '../models/kin_model'
import moons from '../datas/moons'

export default {
  namespaced: true,
  state: {
    year: moment().year(),
    moonSelected: {},
    listWhatsMatters: {},
    goals: {},
    allGoals: {}
  },
  getters: {},
  actions: {
    listMoonGoals (context, payload) {
      const uid = context.rootState.auth.user.uid
      return context.commit('moonGoal', { uid })
    },
    moonGoalSave (context, payload) {
      const uid = context.rootState.auth.user.uid
      return saveMoonGoal(uid,
        payload.datas,
        context.state.year,
        context.state.moonSelected.key
      ).then((res) => {
        context.commit('moonGoal', { uid })
      })
    },
    listAllGoals (context, payload) {
      const uid = context.rootState.auth.user.uid
      return context.commit('listAllGoals', uid)
    }
  },
  mutations: {
    setMoon (state, load) {
      state.moonSelected = load
    },
    moonGoal (state, payload) {
      return listMoonGoal(payload.uid, state.year, state.moonSelected.key).then((res) => {
        if (res.docs !== undefined) {
          if (res.docs[0] !== undefined) {
            state.goals = res.docs[0].data()
          } else {
            state.goals = {}
          }
        }
      })
    },
    listAllGoals (state, uid) {
      const kinM = new KinModel()
      const kinList = kinM.list()
      return listMoonGoal(uid).then(res => {
        var datas = []
        res.docs.forEach(e => {
          if (e.get('finished')) {
            var y = e.get('year')
            var yKin = kinM.calc(y, 7, 26)

            const isYear = function (data) {
              return data.year === y
            }

            const isMoon = function (data) {
              if (data !== undefined) {
                return data.key === e.get('moon')
              }
              return false
            }

            var yKey
            var yDatas = { year: y, yearKin: kinList[yKin - 1] }
            if (!datas.find(isYear)) {
              datas.push(yDatas)
              yKey = datas.indexOf(yDatas)
              datas[yKey].moons = []
            } else {
              yKey = datas.findIndex(function (item, i) {
                return item.year === e.get('year')
              })
            }

            var mDatas = moons[e.get('moon') - 1]
            if (!datas[yKey].moons.find(isMoon)) {
              datas[yKey].moons.push(mDatas)
            }

            var mKey = datas[yKey].moons.indexOf(mDatas)
            if (datas[yKey].moons[mKey] !== undefined) {
              datas[yKey].moons[mKey].goals = e.data()
            }
          }
        })
        state.allGoals = datas
        return datas
      })
    }
  }
}
