<template>
  <div id='app'>
    <video loop muted autoPlay :poster="bg1" className="imgBG">
        <source :src="video" type="video/mp4"/>
    </video>
    <q-layout view="lHh Lpr lFf">
      <layout-navigation></layout-navigation>
      <q-page-container>
        <router-view/>
      </q-page-container>
      <div class="footer">
        <q-btn dark outline color="white" to="/politicas-de-privacidade">Políticas de Privacidade</q-btn>
      </div>
    </q-layout>
  </div>
</template>

<script>
import LayoutNavigation from './components/layout/LayoutNavigation'
import video from './assets/images/bg/cosmicPurple.mp4'
import bg1 from './assets/images/bg/2.png'

export default {
  name: 'LayoutDefault',
  data () {
    return { video, bg1 }
  },
  components: {
    LayoutNavigation
  }
}
</script>

<style lang="scss" scoped>
  .footer {
    padding: 7px;
    border-top: 2px solid #fff;
    text-align: right;
  }
</style>
