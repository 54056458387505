<template>
  <div class="details">
    <h4 class="text-center">Triptofano</h4><br/>
    <p>
      1. Sintetiza a serotonina, conhecida como um dos hormônios da felicidade, harmonia e prazer<br/>
      2. Ajuda na produção de melatonina e niacina atuando na prevenção da depressão<br/>
      3. Ansiedade e Insônia<br/>
      4. Ajuda no emagrecimento e controle do peso<br/>
      5. Melhora a memória <br/>
      6. Aumenta a habilidade de aprendizagem<br/>
      7. Regula o sono<br/><br/>
      Este aminoácido é um relaxante natural, ajuda a aliviar a insônia induzindo um sono normal, reduz a ansiedade e a depressão e estabiliza o humor, ajuda no tratamento de enxaqueca, ajuda o sistema imunológico a funcionar corretamente. O triptofano ajuda no controle de peso, ao reduzir o apetite, aumenta a liberação de hormônios de crescimento e ajuda a controlar a hiperatividade em crianças. É ótimo para melhorar o humor e proporcionar sensação de bem estar porque ajuda na formação de serotonina, uma substância presente no cérebro que facilita a comunicação entre os neurônios, regulando o humor e a sensação de fome.
    </p>
    <h5>Alimentos ricos em triptofano</h5>
    <p>Abacate, ervilha, lentilha, banana, abacaxi, couve-flor, aveia, batata, amêndoa, cacau, grão de bico, feijão azuki, mel, linhaça</p>
  </div>
</template>
