<template>
  <div class="details">
    <h4 class="text-center">Sódio</h4>
    <h5>Vilão da dieta brasileira regula volume sanguíneo, impulsos nervosos e contração muscular.</h5><br/>
    <p>O sódio é um conhecido vilão da pressão arterial, mas ele não pode ser totalmente banido da dieta pois cumpre funções vitais no organismo como a regulação do volume sanguíneo, formação dos impulsos nervosos e da contração muscular.</p>
    <h5>QUANTIDADE RECOMENDADA E FONTES DE SÓDIO</h5>
    <p>
      A dieta brasileira é tradicionalmente carregada de sódio, o que faz da hipertensão uma das doenças mais comuns do país, um em cada quatro brasileiros tem o problema. O consumo diário recomendado pela organização Mundial da Saúde é de no máximo 2 gramas de sódio por dia, o equivalente a 5 gramas de sal. A maior dificuldade no controle do sal está nas propriedades conservantes do mineral, o que levam ele a ser adicionado em quase todos os alimentos e até bebidas. O sódio está presente não apenas em alimentos salgados, principalmente os industrializados, mas também em doces e até na água.
    </p>
    <h5>EFEITOS DA DEFICIÊNCIA E EXCESSO DE SÓDIO</h5>
    <p>
      O excesso de sal causa aumento da hipertensão e inchaço devido a retenção hídrica. A alimentação da grande maioria dos brasileiros dificilmente permite que alguém sofra com carência de sódio, mas vômito e diarreia por um período prolongado, além de algumas outras doenças específicas, podem levar a hiponatremia. Os sintomas da falta de sal no sangue incluem náuseas e vômitos, dor de cabeça, confusão mental, fadiga, inquietação, irritabilidade, fraqueza, perda de energia, espasmos musculares e cólicas.<br/><br/>
      Como a maioria dos alimentos contêm naturalmente sódio em sua composição, a dica é utilizar ervas para realçar o sabor dos pratos e evitar o excesso do mineral. Consulte sempre seu médico.
    </p>
  </div>
</template>
