<template>
  <div class="details">
    <h4 class="text-center">Cálcio</h4>
    <h5>Além de atuar na formação de ossos e dentes o cálcio regula a coagulação e participa de funções neuromusculares.</h5><br/>
    <p>
      Estima-se que 1% a 2% do peso corpóreo de um adulto esteja na forma de cálcio, sendo este o mineral mais presente no corpo humano, 99% dele nos ossos e dentes.<br/>
      A principal função do cálcio é participar da formação e manter a integridade de ossos e dentes. Além disso ele participa das funções neuromusculares, promovendo reações que desencadeiam as contrações dos músculos. No interior das células o cálcio se liga com proteínas e fosfolipídios, promovendo a permeabilidade da membrana, sendo responsável pela secreção de hormônios em algumas células, incluindo as adrenais.<br/><br/>
      O cálcio participa da ativação de fatores que possibilitam a coagulação do sangue, além de prevenir e ajudar no tratamento da hipertensão arterial. Acredita-se inclusive que o cálcio possa ajudar na diminuição da massa gorda.
    </p>
    <h5>Fontes de Cálcio</h5>
    <p>Espinafre, tofú e feijão branco</p>
    <h5>EFEITOS DA DEFICIÊNCIA E EXCESSO DE CÁLCIO</h5>
    <p>
      O excesso de cálcio causa calcificação dos ossos e tecidos moles, comprometimento renal e prejudica a absorção do ferro. A falta de cálcio causa osteoporose, raquitismo, hipertensão e até câncer de cólon. Evite a ingestão excessiva de cálcio sem acompanhamento médico.
    </p>
  </div>
</template>
