import segol from '../assets/images/hebrew-letters/segol.png'
import aleph from '../assets/images/hebrew-letters/aleph.png'
import tav from '../assets/images/hebrew-letters/tav.png'

export default [
  {
    name: 'HARMÔNICA 1 – ENTRADA AUTOEXISTENTE',
    affirmation: 'Informar o Florescimento da Forma',
    iching: 1,
    codon: 'Gênese Criativo',
    harmonic: 'O Tempo gera a Árvore',
    trigrams: [
      'Tempo (Céu)',
      'Tempo (Céu)'
    ],
    aminoacid: 'Lisina',
    genCode: 'AAA'
  },
  {
    name: 'HARMÔNICA 2 – ARMAZÉM GALÁCTICO',
    affirmation: 'Recordar a Elegância da Integridade',
    iching: 43,
    codon: 'Indomável',
    harmonic: 'O princípio de construção dinâmica evolui o espaço',
    trigrams: [
      'Alegria (Lago)',
      'Tempo (Céu)'
    ],
    aminoacid: 'Asparagina',
    genCode: 'AAC'
  },
  {
    name: 'HARMÔNICA 3 – PROCESSO CRISTAL',
    affirmation: 'Formular o Livre Arbítrio da Cooperação',
    iching: 44,
    codon: 'Penetração do Tempo',
    harmonic: 'Princípio de construção dinâmica empoderado pelo Tempo',
    trigrams: [
      'Tempo (Céu)',
      'Alento (Vento)'
    ],
    aminoacid: 'Ácido Glutâmico',
    genCode: 'GAA'
  },
  {
    name: 'HARMÔNICA 4 – SAÍDA ELÉTRICA',
    affirmation: 'Expressar a inteligência do Serviço',
    iching: 14,
    codon: 'Sabedoria do Povo',
    harmonic: 'A conduta define o caminho',
    trigrams: [
      'Visão (Fogo)',
      'Tempo (Céu)'
    ],
    aminoacid: 'Lisina',
    genCode: 'AAG'
  },
  {
    name: 'HARMÔNICA 5 – MATRIZ RESSONANTE',
    affirmation: 'Auto-regular o Fogo universal da Harmonização',
    iching: 28,
    codon: 'Tempo de Brotar',
    harmonic: 'A consciência cósmica transcende o espaço',
    trigrams: [
      'Alegria (Lago)',
      'Alento (Vento)'
    ],
    aminoacid: 'Ácido Aspártico',
    genCode: 'GAC'
  },
  {
    name: 'HARMÔNICA 6 - ENTRADA ESPECTRA',
    affirmation: 'Comunicar o Florescimento da Liberação',
    iching: 34,
    codon: 'Oração',
    harmonic: 'Mente informada pela órdem cósmica',
    trigrams: [
      'Energia (Trovão)',
      'Tempo (Céu)'
    ],
    aminoacid: 'Asparagina',
    genCode: 'AAU'
  },
  {
    name: 'HARMÔNICA 7 – ARMAZÉM LUNAR',
    affirmation: 'Recordar a Elegância do Desafio',
    iching: 50,
    codon: 'Transformação do Tempo',
    harmonic: 'A telepatia transforma',
    trigrams: [
      'Visão (Fogo)',
      'Alento (Vento)'
    ],
    aminoacid: 'Ácido Glutâmico',
    genCode: 'GAG'
  },
  {
    name: 'HARMÔNICA 8 – PROCESSO RÍTMICO',
    affirmation: 'Formular o Livre Arbítrio da Igualdade',
    iching: 9,
    codon: 'Disciplina',
    harmonic: 'Gênese da conduta',
    trigrams: [
      'Alento (Vento)',
      'Tempo (Céu)'
    ],
    aminoacid: 'Treonina',
    genCode: 'ACA'
  },
  {
    name: 'HARMÔNICA 9 - SAÍDA PLANETÁRIA',
    affirmation: 'Expressar a Inteligência da Manifestação',
    iching: 32,
    codon: 'Persistência da Mente',
    harmonic: 'A órdem binária define o movimento do espaço',
    trigrams: [
      'Energia (Trovão)',
      'Alento (Vento)'
    ],
    aminoacid: 'Ácido Aspártico',
    genCode: 'GAU'
  },
  {
    name: 'HARMÔNICA 10 - MATRIZ MAGNÉTICA',
    affirmation: 'Auto-regular o Fogo Universal do Propósito',
    iching: 5,
    codon: 'Povo Unido',
    harmonic: 'A árvore evoluciona o espaço',
    trigrams: [
      'Coração (Água)',
      'Tempo (Céu)'
    ],
    aminoacid: 'Treonina',
    genCode: 'ACC'
  },
  {
    name: 'HARMÔNICA 11 – ENTRADA HARMÔNICA',
    affirmation: 'Comunicar o Florescimento da Radiação',
    iching: 57,
    codon: 'Mente de Alento',
    harmonic: 'Penetração da oitava galáctica',
    trigrams: [
      'Alento (Vento)',
      'Alento (Vento)'
    ],
    aminoacid: 'Alanina',
    genCode: 'GCA'
  },
  {
    name: 'HARMÔNICA 12 – ARMAZÉM SOLAR',
    affirmation: 'Recordar a Elegância da Intenção',
    iching: 26,
    codon: 'Templo do Tempo',
    harmonic: 'A Consciência Cósmica Informada',
    trigrams: [
      'Templo (Montanha)',
      'Tempo (Céu)'
    ],
    aminoacid: 'Treonina',
    genCode: 'ACC'
  },
  {
    name: 'HARMÔNICA 13 - PROCESSO CÓSMICO',
    affirmation: 'Formular o Livre Arbítrio da Presença',
    iching: 48,
    codon: 'Mente de Alento',
    harmonic: 'Penetração da oitava galáctica',
    trigrams: [
      'Coração (Água)',
      'Alento (Vento)'
    ],
    aminoacid: 'Alanina',
    genCode: 'GCC'
  },
  {
    name: 'HARMÔNICA 14 – SAÍDA AUTOEXISTENTE',
    affirmation: 'Expressar a Inteligência da Forma',
    iching: 11,
    codon: 'Dinamização',
    harmonic: 'O caminho da conduta molda o espaço',
    trigrams: [
      'Espaço (Terra)',
      'Tempo (Céu)'
    ],
    aminoacid: 'Treonina',
    genCode: 'ACU'
  },
  {
    name: 'HARMÔNICA 15 – MATRIZ GALÁCTICA',
    affirmation: 'Auto-regular o Fogo Universal da Integridade',
    iching: 18,
    codon: 'Domar a Mente',
    harmonic: 'O caminho de exercer o poder informa a mente',
    trigrams: [
      'Templo (Montanha)',
      'Alento (Vento)'
    ],
    aminoacid: 'Alanina',
    genCode: 'GCG'

  },
  {
    name: 'HARMÔNICA 16 – ENTRADA CRISTAL',
    affirmation: 'Comunicar o Florescimento da Cooperação',
    iching: 10,
    codon: 'Prática',
    harmonic: 'A conduta marca o caminho',
    trigrams: [
      'Tempo (Céu)',
      'Alegria (Lago)'
    ],
    aminoacid: 'Arginina',
    genCode: 'AGA'
  },
  {
    name: 'HARMÔNICA 17 – ARMAZÉM ELÉTRICO',
    affirmation: 'Recordar a Elegância do Serviço',
    iching: 46,
    codon: 'Vazio Radiante',
    harmonic: 'A construção dinâmica torna-se espaço criativo',
    trigrams: [
      'Espaço (Terra)',
      'Alento (Vento)'
    ],
    aminoacid: 'Alanina',
    genCode: 'GCU'
  },
  {
    name: 'HARMÔNICA 18 – PROCESSO RESSONANTE',
    affirmation: 'Formular o Livre Arbítrio da Harmonização',
    iching: 58,
    codon: 'Radiância da Alegria',
    harmonic: 'Canção da Oitava Galáctica',
    trigrams: [
      'Alegria (Lago)',
      'Alegria (Lago)'
    ],
    aminoacid: 'Serina',
    genCode: 'AGC'
  },
  {
    name: 'HARMÔNICA 19 – SAÍDA ESPECTRAL',
    affirmation: 'Expressar a Inteligência da Liberação',
    iching: 6,
    codon: 'Povo Separado',
    harmonic: 'A Árvore Define a Vida',
    trigrams: [
      'Tempo (Céu)',
      'Coração (Água)'
    ],
    aminoacid: 'Glicina',
    genCode: 'GGA'
  },
  {
    name: 'HARMÔNICA 20 – MATRIZ LUNAR',
    affirmation: 'Auto-regular o Fogo Universal do Desafio',
    iching: 38,
    codon: 'Discriminação',
    harmonic: 'A ordem cósmica contem a radiância do espaço',
    trigrams: [
      'Visão (Fogo)',
      'Alegria (Lago)'
    ],
    aminoacid: 'Arginina',
    genCode: 'AGG'
  },
  {
    name: 'HARMÔNICA 21 – ENTRADA RÍTMICA',
    affirmation: 'Comunicar o Florescimento da Igualdade',
    iching: 47,
    codon: 'Chamada para a Fonte',
    harmonic: 'O dinamismo resolvido como arquitetura',
    trigrams: [
      'Alegria (Lago)',
      'Coração (Água)'
    ],
    aminoacid: 'Glicina',
    genCode: 'GGC'
  },
  {
    name: 'HARMÔNICA 22 – ARMAZÉM PLANETÁRIO',
    affirmation: 'Recordar a Elegância da Manifestação',
    iching: 54,
    codon: 'Transcendencia',
    harmonic: 'O templo incorpora a telepatia',
    trigrams: [
      'Energia (Trovão)',
      'Alegria (Lago)'
    ],
    aminoacid: 'Serina',
    genCode: 'AGU'
  },
  {
    name: 'HARMÔNICA 23 – PROCESSO MAGNÉTICO',
    affirmation: 'Formular o Livre Arbítrio do Propósito',
    iching: 64,
    codon: 'Preparado',
    harmonic: 'A oitava galáctica unifica as dimenções',
    trigrams: [
      'Visão (Fogo)',
      'Coração (Água)'
    ],
    aminoacid: 'Glicina',
    genCode: 'GGG'
  },
  {
    name: 'HARMÔNICA 24 – SAÍDA HARMÔNICA',
    affirmation: 'Expressar a Inteligência da Radiância',
    iching: 61,
    codon: 'Espaço Interior',
    harmonic: 'A oitava galáctica resulta como mente',
    trigrams: [
      'Alento (Vento)',
      'Alegria (Lago)'
    ],
    aminoacid: 'Isoleucina',
    genCode: 'AUA'
  },
  {
    name: 'HARMÔNICA 25 – MATRIZ SOLAR',
    affirmation: 'Auto-regular o Fogo Universal da Intenção',
    iching: 40,
    codon: 'Liberação do Coração',
    harmonic: 'A ordem cósmica regressa ao coração da terra',
    trigrams: [
      'Energia (Trovão)',
      'Coração (Água)'
    ],
    aminoacid: 'Glicina',
    genCode: 'GGU'
  },
  {
    name: 'HARMÔNICA 26 – ENTRADA CÓSMICA',
    affirmation: 'Comunicar o Florescimento da Presença',
    iching: 60,
    codon: 'Medida',
    harmonic: 'A oitava galáctica limita o espaço',
    trigrams: [
      'Coração (Água)',
      'Alegria (Lago)'
    ],
    aminoacid: 'Isoleucina',
    genCode: 'AUC'
  },
  {
    name: 'HARMÔNICA 27 – ARMAZÉM AUTOEXISTENTE',
    affirmation: 'Recordar a Elegância da Forma',
    iching: 59,
    codon: 'Dissolução',
    harmonic: 'A oitava galáctica canta a mente do espaço',
    trigrams: [
      'Alento (Vento)',
      'Coração (Água)'
    ],
    aminoacid: 'Valina',
    genCode: 'GUA'
  },
  {
    name: 'HARMÔNICA 28- PROCESSO GALÁCTICO',
    affirmation: 'Formular o Livre Arbítrio da Integridade',
    iching: 41,
    codon: 'Templo da Alegria',
    harmonic: 'Principio de construção dinâmica',
    trigrams: [
      'Templo (Montanha)',
      'Alegria (Lago)'
    ],
    aminoacid: 'Metionina',
    genCode: 'AUG'
  },
  {
    name: 'HARMÔNICA 29 – SAÍDA CRISTAL',
    affirmation: 'Expressar a Inteligência da Cooperação',
    iching: 29,
    codon: 'Coração/Lua Cristal',
    harmonic: 'O Espaço flui como Consciência Cósmica',
    trigrams: [
      'Coração (Água)',
      'Coração (Água)'
    ],
    aminoacid: 'Valina',
    genCode: 'GUC'
  },
  {
    name: 'HARMÔNICA 30 – MATRIZ ELÉTRICA',
    affirmation: 'Auto-regular o Fogo Universal do Serviço',
    iching: 19,
    codon: 'Aspiração do Mago',
    harmonic: 'O caminho de exercer o poder molda o espaço',
    trigrams: [
      'Espaço (Terra)',
      'Alegria (Lago)'
    ],
    aminoacid: 'Isoleucina',
    genCode: 'AUU'
  },
  {
    name: 'HARMÔNICA 31 – ENTRADA RESSONANTE',
    affirmation: 'Comunicar o Florescimento da Harmonização',
    iching: 4,
    codon: 'Escuta de Novo',
    harmonic: 'A Árvore Modela o Espaço',
    trigrams: [
      'Templo (Montanha)',
      'Coração (Agua)'
    ],
    aminoacid: 'Valina',
    genCode: 'GUG'
  },
  {
    name: 'HARMÔNICA 32 – ARMAZÉM ESPECTRAL',
    affirmation: 'Recordar a Elegância da Liberação',
    iching: 13,
    codon: 'Povo Organizado',
    harmonic: 'A verdade evoluciona o caminho da conduta',
    trigrams: [
      'Templo (Céu)',
      'Visão (Fogo)'
    ],
    aminoacid: 'Glutamina',
    genCode: 'CAA'
  },
  {
    name: 'HARMÔNICA 33 – PROCESSO LUNAR',
    affirmation: 'Formular o Livre Arbítrio do Desafio',
    iching: 0
  },
  {
    name: 'HARMÔNICA 34 – SAÍDA RÍTMICA',
    affirmation: 'Expressar a Inteligência da Igualdade',
    iching: 7,
    codon: 'Poder do Povo',
    harmonic: 'A Árvore do tempo se transforma em Terra',
    trigrams: [
      'Espaço (Terra)',
      'Coração (Água)'
    ],
    aminoacid: 'Valina',
    genCode: 'GUU'
  },
  {
    name: 'HARMÔNICA 35 – MATRIZ PLANETÁRIA',
    affirmation: 'Auto-regular o Fogo Universal da Manifestação',
    iching: 49,
    codon: 'Revolução do Tempo',
    harmonic: 'Revolução da Telepatia',
    trigrams: [
      'Alegria (Lago)',
      'Visão (Fogo)'
    ],
    aminoacid: 'Histidina',
    genCode: 'CAC'
  },
  {
    name: 'HARMÔNICA 36 – ENTRADA MAGNÉTICA',
    affirmation: 'Comunicar o Florescimento do Propósito',
    iching: 33,
    codon: 'Devoção',
    harmonic: 'O tempo medita a ordem cósmica',
    trigrams: [
      'Tempo (Céu)',
      'Templo (Montanha)'
    ],
    aminoacid: 'UAA',
    genCode: 'UAA',
    hebrew: 'TAV',
    hebrewImg: tav,
    hebrewDesc: 'Perfeição\n\nDesenho: 1. Dalet e Nun; 2. Três linhas e um yud.\n\nGuematria: Quatrocentos.\n\nSignificado: 1. Verdade; 2. Sinal; 3. Vida ou morte.\n\nPalavra Espírito: HEY HEY YOD'
  },
  {
    name: 'HARMÔNICA 37 – ARMAZÉM HARMÔNICO',
    affirmation: 'Recordar a Elegância da Radiação',
    iching: 30,
    codon: 'Visão/Sol Cristal',
    harmonic: 'A radiância do  espaçõ defina a consciência cósmica',
    trigrams: [
      'Visão (Fogo)',
      'Visão (Fogo)'
    ],
    aminoacid: 'Glutamina',
    genCode: 'CAG'
  },
  {
    name: 'HARMÔNICA 38 – PROCESSO SOLAR',
    affirmation: 'Formular o Livre Arbítrio da Intenção',
    iching: 31,
    codon: 'Atração da Mente',
    harmonic: 'A consciência cósmica estabelece a ordem binária',
    trigrams: [
      'Alegria (Lago)',
      'Templo (Montanha)'
    ],
    aminoacid: 'Tirosina',
    genCode: 'UAC'
  },
  {
    name: 'HARMÔNICA 39 – SAÍDA CÓSMICA',
    affirmation: 'Expressar a Inteligência da Presença',
    iching: 55,
    codon: 'Despertar da Sabedoria',
    harmonic: 'A telepatia se converte na viagem no tempo',
    trigrams: [
      'Energia (Trovão)',
      'Visão (Fogo)'
    ],
    aminoacid: 'Histidina',
    genCode: 'CAU'
  },
  {
    name: 'HARMÔNICA 40 – MATRIZ AUTOEXISTENTE',
    affirmation: 'Auto-regular o Fogo Universal da Forma',
    iching: 56,
    codon: 'O Viajante',
    harmonic: 'A Viagem no tempo unifica a mente',
    trigrams: [
      'Visão (Fogo)',
      'Templo (Montanha)'
    ],
    aminoacid: 'UAG',
    genCode: 'UAG',
    hebrew: 'ALEPH',
    hebrewImg: aleph,
    hebrewDesc: 'A diferença Entre Exílio e Redenção\n\nDesenho: Um ponto no topo e na base; uma linha no meio\n\nGuematria: Um- 26\n\nPalavra Espírito: HEY HEY YOD'
  },
  {
    name: 'HARMÔNICA 41 – ENTRADA GALÁCTICA',
    affirmation: 'Comunicar o Florescimento da Integridade',
    iching: 37,
    codon: 'Poder de Casa',
    harmonic: 'A ordem cósmica informa o tempo',
    trigrams: [
      'Alento (Vento)',
      'Visão (Fogo)'
    ],
    aminoacid: 'Prolina',
    genCode: 'CCA'
  },
  {
    name: 'HARMÔNICA 42 – ARMAZÉM CRISTAL',
    affirmation: 'Recordar a Elegância da Cooperação',
    iching: 62,
    codon: 'Tempo Interior',
    harmonic: 'A oitava galáctica define a evolução',
    trigrams: [
      'Energia (Trovão)',
      'Templo (Montanha)'
    ],
    aminoacid: 'Tirosina',
    genCode: 'UAU'
  },
  {
    name: 'HARMÔNICA 43 – PROCESSO ELÉTRICO',
    affirmation: 'Formular o Livre Arbítrio do Serviço',
    iching: 63,
    codon: 'Realizado',
    harmonic: 'A oitava galáctica universaliza o espaço',
    trigrams: [
      'Coração (Água)',
      'Visão (Fogo)'
    ],
    aminoacid: 'Prolina',
    genCode: 'CCC'
  },
  {
    name: 'HARMÔNICA 44 – SAÍDA RESSONANTE',
    affirmation: 'Expressar a Inteligência da Harmonização',
    iching: 53,
    codon: 'Evolução',
    harmonic: 'A telepatia evoluciona o tempo',
    trigrams: [
      'Alento (Vento)',
      'Templo (Montanha)'
    ],
    aminoacid: 'Serina',
    genCode: 'UCA'
  },
  {
    name: 'HARMÔNICA 45 – MATRIZ ESPECTRAL',
    affirmation: 'Auto-regular o Fogo Universal da Liberação',
    iching: 22,
    codon: 'Templo da Visão',
    harmonic: 'O Caminho de exercer o poder define a radiância do espaço',
    trigrams: [
      'Templo (Montanha)',
      'Visão (Fogo)'
    ],
    aminoacid: 'Prolina',
    genCode: 'CCG'
  },
  {
    name: 'HARMÔNICA 46 – ENTRADA LUNAR',
    affirmation: 'Comunicar o Florescimento do Desafio',
    iching: 39,
    codon: 'Disciplina do Coração',
    harmonic: 'A ordem cósmica retorna ao coração do céu',
    trigrams: [
      'Coração (Água)',
      'Templo (Montanha)'
    ],
    aminoacid: 'Serina',
    genCode: 'UCC'
  },
  {
    name: 'HARMÔNICA 47 – ARMAZÉM RÍTMICO',
    affirmation: 'Recordar a Elegância da Igualdade',
    iching: 36,
    codon: 'Radiância Interior',
    harmonic: 'A ordem cósmica torna-se auto-iluminada',
    trigrams: [
      'Espaço (Terra)',
      'Visão (Fogo)'
    ],
    aminoacid: 'Prolina',
    genCode: 'CCU'
  },
  {
    name: 'HARMÔNICA 48 – PROCESSO PLANETÁRIO',
    affirmation: 'Formular o Livre Arbítrio da Manifestação',
    iching: 52,
    codon: 'Meditação/Templo',
    harmonic: 'A telepatia estabelece o templo',
    trigrams: [
      'Templo (Montanha)',
      'Templo (Montanha)'
    ],
    aminoacid: 'Serina',
    genCode: 'UCG'
  },
  {
    name: 'HARMÔNICA 49 – SAÍDA MAGNÉTICA',
    affirmation: 'Expressar a Inteligência do Propósito',
    iching: 25,
    codon: 'Sincronicidade',
    harmonic: 'O Tempo Concentra a Consciência Cósmica',
    trigrams: [
      'Tempo (Céu)',
      'Energia (Trovão)'
    ],
    aminoacid: 'Arginina',
    genCode: 'CGA'
  },
  {
    name: 'HARMÔNICA 50 – MATRIZ HARMÔNICA',
    affirmation: 'Auto-regular o Fogo Universal da Radiação',
    iching: 15,
    codon: 'Caminho do Povo',
    harmonic: 'Todos os pontos unificam na conduta',
    trigrams: [
      'Espaço (Terra)',
      'Templo (Montanha)'
    ],
    aminoacid: 'Serina',
    genCode: 'UCU'
  },
  {
    name: 'HARMÔNICA 51 - ENTRADA SOLAR',
    affirmation: 'Comunicar o Florescimento da Intenção',
    iching: 17,
    codon: 'Despertar da Alegria',
    harmonic: 'O tempo evoluciona o caminho de exercer o poder',
    trigrams: [
      'Alegria (Lago)',
      'Energia (Trovão)'
    ],
    aminoacid: 'Arginina',
    genCode: 'CGC'
  },
  {
    name: 'HARMÔNICA 52 – ARMAZÉM CÓSMICO',
    affirmation: 'Recordar a Elegância da Presença',
    iching: 12,
    codon: 'Estabilização',
    harmonic: 'O caminho da conduta informado pela Verdade',
    trigrams: [
      'Tempo (Céu)',
      'Espaço (Terra)'
    ],
    aminoacid: 'UGA',
    genCode: 'UGA',
    hebrew: 'SEGOL',
    hebrewImg: segol,
    hebrewDesc: 'Os Patriarcas\n\nDesenho e Sefirá\n\nO segol representa a Sefirá de Chessed- bondade- e é mostrado como um triângulo de três pontos: direita, esquerda e centro.\n\nGuematria: Trinta\n\nSignificado: O povo Judeu é chamado de “am segulá”, um povo escolhido. Assim como o segol é formado por três pontos, o povo de Israel é composto de Cohanim, Levitas e Israelitas.\n\nO Segol também representa os Patriarcas: Avraham, Yitschac e Yaacov. Alternativamente, o segol significa três pais fundadores da Chassidut.\n\nO primeiro, o Baal Shem Tov, é espelhado no ponto à diretora. O ponto à direita introduz os três amores: amor a D’us, amor à Torà e amor ao próximo judeu.\n\nO ponto a esquerda simboliza Maguid e Mezritch, sucessor do Baal Shem Tov, que ensinava somente aqueles que eram notáveis eruditos de Torá e homens de extrema piedade.\n\nO ponto do meio, representando Rabi Shneur Zkaman de Laidi, o Alter Rebe, incorpora ensinamentos tanto do Baal Shem Tov quanto do Maguid.\n\nO Alter Rebe perpetuou e aumentou seus esforços tornando os ensinamentos esotéricos da Chassidut acessíveis a todas as pessoas.\n\nO segol também tem uma característica singular, que reflete igualmente no “am segulá". Não importa o quanto viramos o segol, ele se manterá sempre um segol. Assim é o povo de Israel, am segulá, apesar de todas as adversidades, mudanças e dificuldades, continua firme s forte.\n\nPalavra Espírito: HEY HEY YOD'
  },
  {
    name: 'HARMÔNICA 53 – PROCESSO AUTOEXISTENTE',
    affirmation: 'Formular o Livre Arbítrio da Forma',
    iching: 21,
    codon: 'Despertar da Visão',
    harmonic: 'O caminho do exercício do poder se apega à verdade',
    trigrams: [
      'Visão (Fogo)',
      'Energia (Trovão)'
    ],
    aminoacid: 'Arginina',
    genCode: 'CGG'
  },
  {
    name: 'HARMÔNICA 54 - SAÍDA GALÁCTICA',
    affirmation: 'Expressar a Inteligência da Integridade',
    iching: 45,
    codon: 'Oceano de Presença',
    harmonic: 'O principio de construção dinamica liberado no tempo',
    trigrams: [
      'Alegria (Lago)',
      'Espaço (Terra)'
    ],
    aminoacid: 'Cisteína',
    genCode: 'UGC'
  },
  {
    name: 'HARMÔNICA 55 - MATRIZ CRISTAL',
    affirmation: 'Auto-regular o Fogo Universal da Cooperação',
    iching: 51,
    codon: 'Trovão/Despertar do Ser',
    harmonic: 'A telepatia desperta a energia do espaço',
    trigrams: [
      'Energia (Trovão)',
      'Energia (Trovão)'
    ],
    aminoacid: 'Arginina',
    genCode: 'CGU'
  },
  {
    name: 'HARMÔNICA 56 – ENTRADA ELÉTRICA',
    affirmation: 'Comunicar o Florescimento do Serviço',
    iching: 35,
    codon: 'Expanção da mente',
    harmonic: 'A orderm cósmica ilumina o espaço',
    trigrams: [
      'Visão (Fogo)',
      'Espaço (Terra)'
    ],
    aminoacid: 'Triptofano',
    genCode: 'UGG'
  },
  {
    name: 'HARMÔNICA 57 – ARMAZÉM RESSONANTE',
    affirmation: 'Recordar a Elegância da Harmonização',
    iching: 42,
    codon: 'Caminho Radiossônico',
    harmonic: 'O principio de construção dinâmica evoluciona a mente',
    trigrams: [
      'Alento (Vento)',
      'Energia (Trovão)'
    ],
    aminoacid: 'Leucina',
    genCode: 'CUA'
  },
  {
    name: 'HARMÔNICA 58 – PROCESSO ESPECTRAL',
    affirmation: 'Formular o Livre-Arbítrio da Liberação',
    iching: 16,
    codon: 'Triunfo do Povo',
    harmonic: 'A conduta unifica o caminho',
    trigrams: [
      'Energia (Trovão)',
      'Espaço (Terra)'
    ],
    aminoacid: 'Cisteína',
    genCode: 'UGU'
  },
  {
    name: 'HARMÔNICA 59 – SAÍDA LUNAR',
    affirmation: 'Expressar a Inteligência do Desafio',
    iching: 3,
    codon: 'Novo Começo',
    harmonic: 'A árvore contem a forma do espaço',
    trigrams: [
      'Coração (Água)',
      'Energia (Céu)'
    ],
    aminoacid: 'Leucina',
    genCode: 'CUC'
  },
  {
    name: 'HARMÔNICA 60 – MATRIZ RÍTMICA',
    affirmation: 'Auto-regular o Fogo Universal da Igualdade',
    iching: 20,
    codon: 'Contemplação do Mago',
    harmonic: 'O Caminho do exercício do poder é domado',
    trigrams: [
      'Alento (Vento)',
      'Espaço (Terra)'
    ],
    aminoacid: 'Leucina',
    genCode: 'UUA'
  },
  {
    name: 'HARMÔNICA 61 – ENTRADA PLANETÁRIA',
    affirmation: 'Comunicar o Florescimento da Manifestação',
    iching: 27,
    codon: 'Templo do Ser',
    harmonic: 'A Consciência Cósmica Empodera o Espaço',
    trigrams: [
      'Templo (Montanha)',
      'Energia (Trovão)'
    ],
    aminoacid: 'Leucina',
    genCode: 'CUG'
  },
  {
    name: 'HARMÔNICA 62 – ARMAZÉM MAGNÉTICO',
    affirmation: 'Recordar a Elegância do Propósito',
    iching: 8,
    codon: 'Unidade do Povo',
    harmonic: 'A árvore do tempo se volta para o céu',
    trigrams: [
      'Coração (Água)',
      'Espaço (Terra)'
    ],
    aminoacid: 'Fenilalanina',
    genCode: 'UUC'
  },
  {
    name: 'HARMÔNICA 63 – PROCESSO HARMÔNICO',
    affirmation: 'Formular o Livre Arbítrio da Radiação',
    iching: 24,
    codon: 'Retorno Radiante',
    harmonic: 'A maneira de exercer o poder ascende ao céu',
    trigrams: [
      'Espaço (Terra)',
      'Energia (Trovão)'
    ],
    aminoacid: 'Leucina',
    genCode: 'CUU'
  },
  {
    name: 'HARMÔNICA 64 - SAÍDA SOLAR',
    affirmation: 'Expressar a Inteligência da Intenção',
    iching: 23,
    codon: 'Liberação da Mente',
    harmonic: 'O caminho de exercer o poder desceu à terra',
    trigrams: [
      'Templo (Montanha)',
      'Espaço (Terra)'
    ],
    aminoacid: 'Leucina',
    genCode: 'UUG'
  },
  {
    name: 'HARMÔNICA 65 – MATRIZ CÓSMICA',
    affirmation: 'Auto-regular o Fogo Universal da Presença',
    iching: 2,
    codon: 'Matriz Primordial',
    harmonic: 'Árvore Informada',
    trigrams: [
      'Espaço (Terra)',
      'Espaço (Terra)'
    ],
    aminoacid: 'Fenilalanina',
    genCode: 'UUU'
  }
]
