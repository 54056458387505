import { db } from '../firebase'

const register = function (uid, datas) {
  return db.collection('users')
    .doc(uid)
    .collection('friends')
    .add(datas)
    .then((res) => {
      return true
    })
    .catch((err) => {
      console.log(err)
      return false
    })
}

const update = function (uid, datas, id) {
  return db.collection('users')
    .doc(uid)
    .collection('friends')
    .doc(id)
    .update(datas)
    .then((res) => {
      return true
    })
    .catch((err) => {
      console.log(err)
      return false
    })
}

const list = function (uid) {
  return db.collection('users')
    .doc(uid)
    .collection('friends')
    .get()
    .then((res) => {
      return res
    })
    .catch((err) => {
      console.log(err)
      return false
    })
}

const remove = function (uid, id) {
  return db.collection('users')
    .doc(uid)
    .collection('friends')
    .doc(id)
    .delete()
}

export { register, list, remove, update }
