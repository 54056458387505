<template>
  <div class="details">
    <h4 class="text-center">Glicina</h4><br/>
    <p>
      1. Melhora o cansaço físico e mental <br/>
      2. Melhora a contração muscular<br/>
      3. Utilizada na transmissão de impulsos nervosos <br/><br/>
      A <span class="bold">glicina</span> serve principalmente para regulamentação da glicose no sangue o que, em alguns casos, pode trazer uma melhora significativa aos níveis de energia e ao combate à fadiga. Fornece também, ao corpo humano, a glicose necessária para realização de funções metabólicas. Dentre outras funções exercidas pela glicina compreendem a redução da ansiedade e da depressão, a melhora na concentração e na participação na formação do DNA, do RNA e da síntese de colágeno e da creatina. Com isto, influencia no crescimento muscular, no funcionamento cerebral, no sono e na digestão.
    </p>
    <h5>Alimentos ricos em Glicina</h5>
    <ul>
      <li>Ervilha, cevada, feijão, lentilha, trigo;</li>
      <li>Oleaginosas;</li>
      <li>Aveia, milho;</li>
      <li>Cogumelos, abóbora.</li>
      <li>Algas marinhas, amaranto, espinafre, alface, aspargos, brócolis.</li>
    </ul>
  </div>
</template>
