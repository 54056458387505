<template>
  <div class="details">
    <h4 class="text-center">Manganês</h4>
    <h5>Mineral ajuda a controlar diabetes e hipotireoidismo.</h5><br/>
    <p>
      Manganês é um mineral antioxidante que ajuda a preservar a saúde das células, inibindo os radicais livres que causam a degeneração do colágeno e o envelhecimento precoce do tecido. Considerado um oligoelemento essencial para a ativação de várias enzimas digestivas, o manganês participa da síntese de carboidratos, aminoácidos e colesterol, sendo o principal antioxidante da mitocôndria, parte vital da célula, responsável pela produção de energia.<br/>
      Entre outras funções do manganês no organismo está o controle da síntese e secreção de insulina, hormônio produzido pelo pâncreas responsável por metabolizar o açúcar. O mineral também participa ativamente da produção de outro hormônio, a tiroxina, que é secretado pela tireoide e interfere no apetite, regulação do humor e quase todas as reações celulares do corpo.<br/>
    </p>
    <h5>QUANTIDADE RECOMENDADA, FONTE E EFEITOS DA DEFICIÊNCIA E EXCESSO DE MANGANÊS</h5>
    <p>
      A quantidade recomendada de manganês é de 2mg de manganês por dia. Em 100g de abacaxi encontramos 0,77mg do mineral e em 100g de espinafre 0,84mg.<br/>
      Níveis reduzidos de manganês podem causar descontrole do açúcar no sangue e diabetes do tipo 2, o mais comum. Outros problemas decorrentes da falta de manganês são perda de peso, dermatite transiente, náusea, vômito, redução da capacidade reprodutiva e dificuldade no metabolismo de carboidratos.<br/>
      O excesso de manganês pode desestabilizar o sistema nervoso central causando confusão mental, problemas neurológicos, espasmos musculares e até facilitar o desenvolvimento de Parkinson.
    </p>
  </div>
</template>
